import React from 'react';
import { List, ListItem, Typography, Divider, Grid, ListItemText, useMediaQuery, useTheme, Button } from '@mui/material';
import dayjs from 'dayjs';
import { PlannedProcurement } from './datatypes';

interface PlannedProcurementListProps {
  plannedProcurements: PlannedProcurement[];
  searchTerm: string;
  favoriteIds: Set<number>;
  onAddFavorite: (plannedProcurementId: number) => void;
  onRemoveFavorite: (plannedProcurementId: number) => void;
}

export const PlannedProcurementList: React.FC<PlannedProcurementListProps> = ({
  plannedProcurements,
  searchTerm,
  favoriteIds, 
  onAddFavorite, 
  onRemoveFavorite
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Utility function to escape regex special characters except for '*'
  const escapeRegExp = (text: string) => {
    return text.replace(/[-[\]/{}()+?.\\^$|]/g, '\\$&');
  };

  // Function to parse the searchTerm and extract tokens
  const parseSearchTerm = (searchTerm: string) => {
    const tokens: string[] = [];
    const regex = /(?:"([^"]+)")|(\S+)/g;
    let match;

    while ((match = regex.exec(searchTerm)) !== null) {
      if (match[1]) {
        // Matched a quoted phrase
        tokens.push(match[1]);
      } else if (match[2]) {
        // Matched a word (possibly with operators)
        let token = match[2];

        // Remove leading '+' operator
        token = token.replace(/^\+/, '');

        // Split by '|' operator
        const subTokens = token.split('|');

        subTokens.forEach((subToken) => {
          if (subToken) {
            // Handle wildcard '*'
            let pattern = escapeRegExp(subToken);
            pattern = pattern.replace(/\\\*/g, '.*'); // Replace escaped '*' with '.*' for regex

            tokens.push(pattern);
          }
        });
      }
    }

    return tokens;
  };

  // Function to highlight the search terms in the text
  const highlightText = (text: string, searchTerm: string) => {
    if (!searchTerm) return text;

    const tokens = parseSearchTerm(searchTerm);
    if (tokens.length === 0) return text;

    // Build a regex pattern to match any of the tokens
    const pattern = tokens.map((token) => `(${token})`).join('|');
    const regexPattern = new RegExp(pattern, 'gi');

    const parts = text.split(regexPattern);

    return (
      <>
        {parts.map((part, index) =>
          regexPattern.test(part) ? (
            <span key={index} style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}>
              {part}
            </span>
          ) : (
            <span key={index}>{part}</span>
          )
        )}
      </>
    );
  };

  if (plannedProcurements.length === 0) {
    return <Typography>Ingen planlagte innkjøp funnet.</Typography>;
  }

  return (
    <List>
      {/* Header */}
      <ListItem>
        <ListItemText>
          <Grid container spacing={2}>
            <Grid item xs={isMobile ? 3 : 2}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  wordBreak: 'break-word',
                  hyphens: 'auto',
                  fontSize: isMobile ? '0.8rem' : '1rem',
                }}
              >
                Tittel
              </Typography>
            </Grid>
            <Grid item xs={isMobile ? 6 : 7}>
              <Typography sx={{ fontWeight: 'bold', fontSize: isMobile ? '0.8rem' : '1rem' }}>
                Beskrivelse
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography sx={{ fontWeight: 'bold', fontSize: isMobile ? '0.8rem' : '1rem' }}>
                Fullførelsesår
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography sx={{ fontWeight: 'bold' }}>Lagre</Typography>
            </Grid>
          </Grid>
        </ListItemText>
      </ListItem>

      {/* Horizontal Divider */}
      <Divider />

      {/* Data Rows */}
      {plannedProcurements.map((plannedProcurement, index) => (
        <div key={index}>
          {/* Data Row */}
          <ListItem>
            <ListItemText>
              <Grid container spacing={2}>
                <Grid item xs={isMobile ? 3 : 2}>
                  <Typography
                    sx={{
                      wordBreak: 'break-word',
                      hyphens: 'auto',
                      fontSize: isMobile ? '0.8rem' : '1rem',
                    }}
                  >
                    {highlightText(plannedProcurement.title, searchTerm)}
                  </Typography>
                </Grid>
                <Grid item xs={isMobile ? 6 : 7}>
                  <Typography sx={{ fontSize: isMobile ? '0.8rem' : '1rem' }}>
                    {highlightText(plannedProcurement.description, searchTerm)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography sx={{ fontSize: isMobile ? '0.8rem' : '1rem' }}>
                    {plannedProcurement.finishingYear
                      ? dayjs(plannedProcurement.finishingYear).format('YYYY')
                      : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  {favoriteIds.has(plannedProcurement.id) ? (
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => onRemoveFavorite(plannedProcurement.id)}
                      sx={{ fontSize: '0.875rem' }} // Adjust font size here
                    >
                      Fjern fra Min Oversikt
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => onAddFavorite(plannedProcurement.id)}
                      sx={{ fontSize: '0.875rem' }} // Adjust font size here
                    >
                      Lagre i Min Oversikt
                    </Button>
                  )}
                </Grid>
              </Grid>
            </ListItemText>
          </ListItem>

          {/* Horizontal Divider */}
          <Divider />
        </div>
      ))}
    </List>
  );
};
