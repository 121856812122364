import { Button } from "@mui/material";
import { FunctionComponent } from "react";

type Props = {
  link?: string;
  text: string;
  onClick?: () => void;
  dynamicSize?: string;
  variant?: string;
};

export const UnderlinedButton: FunctionComponent<Props> = ({ variant, link, text, onClick, dynamicSize }) => {
  return (
    <Button
      size="large"
      href={link}
      onClick={onClick}
      sx={{
        textTransform: "none",
        backgroundColor: "white",
        letterSpacing: "0rem",
        fontWeight: 400,
        borderBottom: "1px solid black",
        borderRadius: "0px",
        marginTop: "1rem",
        fontSize: dynamicSize ? `${dynamicSize}vw` : "1rem",
        width: dynamicSize && `${dynamicSize}0vw`,
      }}
    >
      {text}
    </Button>
  );
};
