import { FunctionComponent } from "react";
import { Grid, Paper, Typography, Box, Card, useTheme } from "@mui/material";
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator";
import { useUserContext } from "../../../utils/contexts/UserContext";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { SubscriptionCard } from "./SubscriptionCard";
import { MyCompanyCard } from "./MyCompanyCard";

const ProfilePure: FunctionComponent = () => {
  const theme = useTheme();
  const { user: auth0User } = useAuth0();
  const { finndoffUser, isLoading } = useUserContext();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Paper elevation={0} sx={{ width: "100%" }}>
      <Box
        sx={{
          [theme.breakpoints.up("md")]: {
            marginLeft: "auto",
            marginRight: "auto",
            width: "80%",
          },
        }}
      >
        <Box padding={4}>
          <Card
            sx={{
              backgroundColor: "transparent",
              marginTop: "3%",
            }}
          >
            <Grid
              item
              md={4}
              sm={12}
              xs={12}
              sx={{
                margin: theme.spacing(1),
                float: "left",
                marginLeft: "32px",
                [theme.breakpoints.up("md")]: {
                  paddingTop: "32px",
                  paddingBottom: "32px",
                },
              }}
            >
              <Box
                component="img"
                src={auth0User?.picture}
                alt="User"
                sx={{
                  borderRadius: "50%",
                  backgroundPosition: "top center",
                  marginTop: "20px",
                  borderStyle: "solid",
                  borderColor: theme.palette.grey[400],
                }}
              ></Box>
              <Typography variant="h5" gutterBottom>
                Kontaktperson
              </Typography>
              <div>{auth0User?.email}</div>
            </Grid>

            <Grid
              item
              md={4}
              sm={12}
              xs={12}
              sx={{
                margin: theme.spacing(1),
                float: "left",
                marginLeft: "32px",
                [theme.breakpoints.up("md")]: {
                  paddingTop: "32px",
                  paddingBottom: "32px",
                },
              }}
            >
              {/* <Fade in={true} timeout={Math.min(200 * 1, 600)}> */}
              <MyCompanyCard finndoffUser={finndoffUser} />
            </Grid>
          </Card>
        </Box>
      </Box>

      <Box
        sx={{
          [theme.breakpoints.up("md")]: {
            marginLeft: "auto",
            marginRight: "auto",
            width: "80%",
          },
        }}
      >
        <Box padding={4}>
          <Grid container spacing={6}>
            {/*<Fade in={true} timeout={Math.min(200 * 1, 600)}>*/}
            <Grid item md={12} sm={12} xs={12}>
              <SubscriptionCard />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* <FeedbackModal /> */}

      {/* <Box>
        <Button className={classes.feedbackbutton} variant="contained" color="primary" onClick={openFeedbackModal}>
          <ChatBubbleOutlineIcon />
        </Button>
      </Box> */}
    </Paper>
  );
};

export const Profile = withAuthenticationRequired(ProfilePure, {
  onRedirecting: () => <LoadingIndicator />,
});
