import { FunctionComponent } from "react";
import { Box, Grid, Button, Link } from "@mui/material";
import { paths } from "../../app/paths";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useAuth0 } from "@auth0/auth0-react";
import { useVilkaarModal } from "../../components/Modal/VilkaarModal";
import styled from "@emotion/styled";

interface BottomToolBarProps {
  activePage: string;
}

const HeaderBox = styled(Box)`
  color: white;
  font-family: Roboto;
  font-size: 1.5rem;
  text-decoration: none;
  text-transform: none;
  margin-bottom: 0.75rem;
`;

const StyledLink: FunctionComponent<{
  active: boolean;
  children: React.ReactNode;
  href: string;
  onClick: () => void;
}> = ({ active, children, href, onClick }) => {
  return (
    <Link
      href={`/portal/${href}`}
      onClick={onClick}
      sx={{
        color: (theme) => (active ? theme.palette.secondary.main : "white"),
        fontFamily: "Roboto",
        fontSize: "16px",
        textDecoration: "none",
        textTransform: "none",
      }}
    >
      {children}
    </Link>
  );
};

export const BottomToolBar: FunctionComponent<BottomToolBarProps> = ({ activePage }) => {
  const { isAuthenticated } = useAuth0();
  const { openVilkaarModal, VilkaarModal } = useVilkaarModal();
  const currentYear = new Date().getFullYear();
  return (
    <>
      <Box bgcolor="primary.main" paddingTop={4} paddingLeft={8}>
        <Grid container direction="column" spacing={2}>
          {/* Main content */}
          <Grid item>
            <Grid container direction="row">
              {/* Annet */}
              <Grid item lg={3} md={3} sm={3} xs={6}>
                <Grid container direction="column">
                  <Grid item>
                    <HeaderBox>Tjenester</HeaderBox>
                  </Grid>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <StyledLink
                        active={paths.start === activePage}
                        href={paths.start}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Hjem
                      </StyledLink>
                    </Grid>
                    <Grid item>
                      <StyledLink
                        active={paths.news === activePage}
                        href={paths.news}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Nyheter
                      </StyledLink>
                    </Grid>
                    <Grid item>
                      <StyledLink
                        active={paths.faq === activePage}
                        href={paths.faq}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        FAQ
                      </StyledLink>
                    </Grid>
                    <Grid item>
                      <StyledLink
                        active={paths.prices === activePage}
                        href={paths.prices}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Priser
                      </StyledLink>
                    </Grid>
                    <Grid item>
                      <StyledLink
                        active={paths.search === activePage}
                        href={paths.search}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Søk
                      </StyledLink>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* Selskapet */}
              <Grid item lg={3} md={3} sm={3} xs={6}>
                <Grid container direction="column">
                  <Grid item>
                    <HeaderBox>Selskapet</HeaderBox>
                  </Grid>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <StyledLink
                        active={paths.about === activePage}
                        href={paths.about}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Om oss
                      </StyledLink>
                    </Grid>
                    <Grid item>
                      <StyledLink
                        active={paths.contact === activePage}
                        href={paths.contact}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Kontakt
                      </StyledLink>
                    </Grid>
                    <Grid item>
                      <StyledLink
                        active={paths.tnc === activePage}
                        href={paths.tnc}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Vilkår og betingelser
                      </StyledLink>
                    </Grid>
                    <Grid item>
                      <StyledLink
                        active={paths.privacypolicy === activePage}
                        href={paths.privacypolicy}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Personvernerklæring
                      </StyledLink>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Mine sider */}
              {isAuthenticated && (
                <Grid item lg={3} md={3} sm={3} xs={6}>
                  <Grid
                    container
                    direction="column"
                    sx={{ marginTop: { xs: 3, sm: 0 }, marginBottom: { xs: 3, sm: 0 } }}
                  >
                    <Grid item>
                      <HeaderBox>Mine sider</HeaderBox>
                    </Grid>
                    <Grid container spacing={1} direction="column">
                      <Grid item>
                        <StyledLink
                          active={paths.searches === activePage}
                          href={paths.searches}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          Mine søk
                        </StyledLink>
                      </Grid>
                      <Grid item>
                        <StyledLink
                          active={paths.profile === activePage}
                          href={paths.profile}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          Min profil
                        </StyledLink>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {/* Sosiale medier */}
              <Grid item lg={isAuthenticated ? 2 : 5} md={isAuthenticated ? 2 : 5} sm={isAuthenticated ? 2 : 5} xs={6}>
                <Grid
                  container
                  direction="row"
                  sx={{
                    justifyContent: { xs: "flex-start", sm: "flex-end" },
                    marginTop: { xs: 3, sm: 0 },
                  }}
                  alignItems="center"
                  columnSpacing={2}
                >
                  <Grid item>
                    <a href="https://www.facebook.com/profile.php?id=100077230503459" target="_blank" rel="noreferrer">
                      <FacebookIcon fontSize="large" sx={{ color: "white" }}></FacebookIcon>
                    </a>
                  </Grid>
                  <Grid item>
                    <a href="https://www.linkedin.com/company/finndoff/" target="_blank" rel="noreferrer">
                      <LinkedInIcon fontSize="large" sx={{ color: "white" }}></LinkedInIcon>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Bottom content */}
          <Grid item sx={{ marginTop: { xs: "1rem", sm: "2rem" } }}>
            <Grid alignItems="center" alignContent="center" container direction="row" spacing={3}>
              <Grid item lg={6} xl={6} md={6} sm={6}>
                <Box
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    textDecoration: "none",
                    textTransform: "none",
                    color: "white",
                  }}
                >
                  © {currentYear} Finndoff. All rights reserved.
                </Box>
              </Grid>
              {isAuthenticated && (
                <Grid item lg={5} xl={5} md={5} sm={5}>
                  <Grid
                    container
                    direction="row"
                    sx={{ justifyContent: { xs: "flex-start", sm: "flex-end" } }}
                    spacing={3}
                  >
                    <Grid item>
                      <Button
                        onClick={openVilkaarModal}
                        sx={{
                          color: paths.vilkaar === activePage ? "secondary.main" : "white",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          textDecoration: "none",
                          textTransform: "none",
                        }}
                      >
                        Vilkår
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <VilkaarModal />
      </Box>
    </>
  );
};
