export interface AnbudSummaryCpvCode {
  id: number;
  kode: string;
  beskrivelse: string;
}

export interface AnbudSummaryPlace {
  id: number;
  navn: string;
}

export interface AnbudSummary {
  doffinAnbudId: string;
  tittel: string;
  beskrivelse: string;
  cpvKoder: AnbudSummaryCpvCode[];
  steder: AnbudSummaryPlace[];
  doffinUrl: string;
  fileDate: string;
  dispatchDate: string;
  publicationType: string;
}

export interface PagedList<T> {
  items: T[];
  currentPage: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export enum HierarchyType {
  Kategori,
  Sted,
}
