import { BrowserRouter } from "react-router-dom";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import { UserProvider } from "../utils/contexts/UserContext";
import { theme } from "../theme/theme";
import { WaitForAuth0Loaded } from "../utils/WaitForAuth0Loaded";
import { Auth0Provider } from "@auth0/auth0-react";
import { config } from "../config";
import { RoutingComponent } from "../components/RoutingComponent/RoutingComponent";
import TagManager from "react-gtm-module";
import styled from "@emotion/styled";
import React, { StrictMode } from "react";
import { HelmetProvider } from "react-helmet-async"; // Import HelmetProvider

const tagManagerArgs = {
  gtmId: config.gtmId,
};

TagManager.initialize(tagManagerArgs);

const StyledSnackbar = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: theme.palette.primary.main,
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: theme.palette.error.main,
  },
  "&.notistack-MuiContent-warning": {
    backgroundColor: theme.palette.warning.main,
  },
  "&.notistack-MuiContent-info": {
    backgroundColor: theme.palette.info.main,
  },
}));

// Custom onRedirectCallback function
const customOnRedirectCallback = (appState?: { returnTo?: string }): void => {
  window.location.href = appState?.returnTo || window.location.pathname;
};

export const App = () => (
  <Auth0Provider
    {...config.auth0.client}
    onRedirectCallback={customOnRedirectCallback}
  >
    <WaitForAuth0Loaded>
      <UserProvider>
        <StyledEngineProvider injectFirst>
          <SnackbarProvider
            maxSnack={3}
            Components={{
              success: StyledSnackbar,
              error: StyledSnackbar,
              warning: StyledSnackbar,
              info: StyledSnackbar,
            }}
          >
            <CssBaseline />
            {/* Wrap the app in HelmetProvider */}
            <HelmetProvider>
              <BrowserRouter>
                <RoutingComponent />
              </BrowserRouter>
            </HelmetProvider>
          </SnackbarProvider>
        </StyledEngineProvider>
      </UserProvider>
    </WaitForAuth0Loaded>
  </Auth0Provider>
);
