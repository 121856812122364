import { useEffect, useState } from "react";
import { useSearchGetApi, useSearchSaveApi } from "./useSearchApi";
import { initialSearch, createSearch, Search } from "../../../Types/Search";

export const useSearchEditState = (id?: string) => {
  const [loading, setLoading] = useState(true);
  const [currentSearch, setCurrentSearch] = useState<Search>(initialSearch);

  const { error, getSearch } = useSearchGetApi();
  const { error: saveError, saveSearch: apiSaveSearch } = useSearchSaveApi();

  const saveSearch = async (search: Search): Promise<Search> => {
    const savedSearch = await apiSaveSearch(search);
    if (!saveError) {
      setCurrentSearch(savedSearch);
      return savedSearch;
    }

    console.error(saveError);
    return Promise.reject(saveError);
  };

  useEffect(() => {
    const load = async (id?: string) => {
      if (!id) {
        setCurrentSearch(createSearch());
        setLoading(false);
        return;
      }
      setLoading(true);
      const search = await getSearch(id);
      setCurrentSearch(search);
      setLoading(false);
    };

    load(id).catch(console.error);
  }, [id]);

  return { error, loading, currentSearch, setCurrentSearch, saveSearch };
};
