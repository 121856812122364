import React, { useState } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, ModalProps, TextField } from "@mui/material";
import { filterNodes, immutablySet, Tree } from "../../../utils/tree";
import { Hierarchy } from "../Search/SearchForm/Hierarchy";
import { Search, setSearchCheckedKategori, setSearchCheckedSted } from "../../../Types/Search";
import { HierarchyType } from "./datatypes";
import { Node } from "../../../utils/tree";
import { PrimaryButton } from "../../../components/Buttons/PrimaryButton";

interface HierarchyDialogProps {
  open: ModalProps["open"];
  closeDialog: () => void;
  title: string;
  tree: Tree;
  type: HierarchyType;
  updateCurrentSearch: (currentSearch: Search) => void;
  currentSearch: Search;
}

export const HierarchyDialog: React.FC<HierarchyDialogProps> = ({
  open,
  closeDialog,
  title,
  tree,
  type,
  updateCurrentSearch,
  currentSearch,
}) => {
  const [nodesFiltered, setNodesFiltered] = useState<Node[]>(
    type === HierarchyType.Sted ? tree.rootNodes[0]?.children : tree.rootNodes
  );
  const [filterString, setFilterString] = useState<string>("");

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== "") {
      const result = filterNodes(
        type === HierarchyType.Sted ? tree.rootNodes[0].children : tree.rootNodes,
        event.target.value
      );

      setNodesFiltered(result);
    }
    setFilterString(event.target.value);
  };

  const saveAndClose = () => {
    updateCurrentSearch(currentSearch);
    closeDialog();
  };

  const [openedNodes, setOpenedNodes] = useState<Set<number>>(new Set());
  const setOpenedNode = (id: number, opened: boolean) => setOpenedNodes(immutablySet(openedNodes, id, opened));
  const setCheckedNode = (id: number, checked: boolean) => {
    if (type === HierarchyType.Kategori)
      updateCurrentSearch(setSearchCheckedKategori(currentSearch, tree, id, checked));
    else if (type === HierarchyType.Sted) updateCurrentSearch(setSearchCheckedSted(currentSearch, tree, id, checked));
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      maxWidth="sm"
      onClose={saveAndClose}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent sx={{ height: "500px" }}>
        <Box
          sx={{
            borderStyle: "solid",
            border: "0px",
            borderColor: "gray",
            borderRadius: "2px",
          }}
        >
          <TextField
            onChange={handleFilterChange}
            placeholder="Filter"
            inputProps={{ "aria-label": "filter" }}
            value={filterString}
            color="primary"
            variant="standard"
            size="small"
            sx={{
              backgroundColor: "white",
              justifyContent: "center",
              alignItems: "left",
              width: "100%",
            }}
          />
        </Box>
        <Hierarchy
          tree={tree}
          nodes={nodesFiltered}
          openedNodeIds={openedNodes}
          checkedNodeIds={type === HierarchyType.Kategori ? currentSearch.kategoriIds : currentSearch.stedIds}
          setChecked={setCheckedNode}
          setOpened={setOpenedNode}
        />
      </DialogContent>
      <DialogActions>
        <Box marginRight={"1rem"}>
          <PrimaryButton onClick={saveAndClose} text="Lukk" />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
