import { FunctionComponent } from "react";
import { Grid, CircularProgress, Typography, Box } from "@mui/material";

type LoadingIndicatorProps = {
  label?: string;
};

export const LoadingIndicator: FunctionComponent<LoadingIndicatorProps> = (props) => {
  return (
    <Box paddingTop="24px">
      <Grid container spacing={4} alignItems="center" direction="column">
        <Grid item>
          <CircularProgress />
        </Grid>
        {props.label && (
          <Grid item>
            <Typography>{`${props.label}`}</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
