import { FunctionComponent } from "react";
import { Box, Container, Grid, Hidden, Typography } from "@mui/material";
import { paths } from "../../app/paths";
import helloImage from "../../images/Hello.svg";
import { SecondaryButton } from "../Buttons/SecondaryButton";

interface RegisterCardProps {
  maintext?: string;
  buttontext?: string;
}

export const RegisterCard: FunctionComponent<RegisterCardProps> = ({
  maintext = "Vi er Norges beste anbudsvarsling og kan hjelpe deg i gang med å finne relevante kunngjøringer for deg og din bedrift.",
  buttontext = "Start din prøveperiode nå",
}) => {
  return (
    <Container
      sx={{
        backgroundColor: "primary.main",
        borderRadius: "8px",
        color: "secondary.main",
      }}
    >
      <Grid container direction="row" margin="1rem">
        <Hidden smDown>
          <Grid item lg={1} xl={1} md={2} sm={3}>
            <Box component="img" src={helloImage} height="115px" />
          </Grid>
        </Hidden>
        <Grid item lg={11} xl={11} md={10} sm={12} xs={12}>
          <Grid container direction="column">
            <Grid item>
              <Box margin="1rem">
                <Typography variant="h5" color="turquoise.light">
                  {maintext}
                </Typography>
              </Box>
              <Box margin="1rem">
                <SecondaryButton text={buttontext} link={paths.signup}></SecondaryButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
