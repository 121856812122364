import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { paths, portalUrl } from "../../app/paths";
import { ReferenceDataProvider } from "../../utils/contexts/ReferenceDataContext";
import { Portal } from "../../pages/Portal/Portal";
import { theme } from "../../theme/theme";
import { ThemeProvider } from "@mui/material/styles";
import { routes, portalRoutes } from "../../pages/Portal/routes";
import { TopToolBar } from "../../pages/Portal/TopToolbar/TopToolBar";
import { BottomToolBar } from "../../pages/Portal/BottomToolBar";
import TenderRedirect from "../../pages/DoffinRedirect/TenderRedirect"; // Import your redirect page

export const RoutingComponent = () => {
  const location = useLocation();
  const searchParams = location.search;
  
  // Check if the current path is the redirect route
  const isRedirectRoute = location.pathname === "/tender";

  return (
    <ReferenceDataProvider>
      {/* Conditionally render the layout components only if not on the redirect route */}
      {isRedirectRoute ? (
        <Routes>
          {/* Define the redirect route without layout */}
          <Route path="/tender" element={<TenderRedirect />} />
        </Routes>
      ) : (
        <ThemeProvider theme={theme}>
          <TopToolBar />
          <Routes>
            <Route path={portalUrl} element={<Portal />}>
              <Route path="signup" element={<Navigate to={`${paths.signup}${searchParams}`} />} />
              {portalRoutes.map((route, i) => {
                return <Route key={i} {...route} />;
              })}
            </Route>
            {routes.map((route, i) => {
              return <Route key={i} {...route} />;
            })}
          </Routes>
          <BottomToolBar activePage={location.pathname.split("/").pop()} />
        </ThemeProvider>
      )}
    </ReferenceDataProvider>
  );
};
