import React, { useCallback, useEffect, useState } from "react";
import { Box, Fab, Fade, Grid, Typography, Zoom } from "@mui/material";
import { SearchCard } from "./SearchCard";
import AddIcon from "@mui/icons-material/Add";
import { useApi } from "../../../utils/hooks/useApi";
import { NavLink } from "react-router-dom";
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Search } from "../../../Types/Search";
import { SearchApiResponse, toSearch } from "../Search/useSearchApi";
import { paths } from "../../../app/paths";
import { isAuthenticated, useUserContext } from "../../../utils/contexts/UserContext";
import { AnbudBrukerFavorite, CompanyFollower } from "../Search/datatypes";
import { FavoriteAnbudCard } from "./FavoriteAnbudCard";
import { FollowedCompanyCard } from "./CompanyFollowedCard";
import { BrukerPlannedProcurementDto, CombinedFavoriteData } from "../../Portal/ProcurementPlanner/datatypes";
import { FavoritePlannedProcurementCard } from "./FavoritePlannedProcurementCard";
import { SupplierBulletin } from "../SupplierBulletins/datatypes";
import { SupplierBulletinCard } from "./SupplierBulletinCard";

const SearchesPure: React.FC = () => {
  const { error, loading, data } = useApi("/api/me/brukerdefinertsok", {}, []);
  const {
    data: favoritesData,
    loading: favoritesLoading,
    error: favoritesError,
  } = useApi("/api/me/bruker/favorites", {}, []);

  // Fetch followed companies
  const {
    data: followingData,
    loading: followingLoading,
    error: followingError,
  } = useApi("/api/me/bruker/following", {}, []);

  // Fetch favorite planned procurements
  const {
    data: favoritePlannedProcurementsData,
    loading: favoritePlannedProcurementsLoading,
    error: favoritePlannedProcurementsError,
  } = useApi('/api/procurement/favorites', {}, []);

  // Fetch supplier bulletins with a reload key
  const [supplierBulletinsRefreshKey, setSupplierBulletinsRefreshKey] = useState(0);

  // Fetch supplier bulletins, including the refresh key in the dependencies array
  const {
    data: supplierBulletinsData,
    loading: supplierBulletinsLoading,
    error: supplierBulletinsError,
  } = useApi('/api/bulletin/user', {}, [supplierBulletinsRefreshKey]);

  // Function to reload supplier bulletins by updating the refresh key
  const reloadSupplierBulletins = () => {
    setSupplierBulletinsRefreshKey((prevKey) => prevKey + 1);
  };

  // Define getProcurementPlan function using useApi
  const { get: getProcurementPlan } = useApi('/api/procurement');

  // Combine data in state
  const [favoriteProcurementData, setFavoriteProcurementData] = useState<CombinedFavoriteData[]>([]);
  const [favoriteProcurementDataLoading, setFavoriteProcurementDataLoading] = useState(true);

  useEffect(() => {
    const fetchFavoriteProcurementData = async () => {
      if (favoritePlannedProcurementsData && favoritePlannedProcurementsData.length > 0) {
        const planPromises = favoritePlannedProcurementsData.map((fav: BrukerPlannedProcurementDto) =>
          getProcurementPlan(`/${fav.plannedProcurement.procurementPlanId}`).catch(error => null)
        );
        try {
          const plans = await Promise.all(planPromises);
          const combinedData = favoritePlannedProcurementsData.map((fav: BrukerPlannedProcurementDto, index: number) => ({
            plannedProcurement: fav.plannedProcurement,
            procurementPlan: plans[index],
          }));
          setFavoriteProcurementData(combinedData);
        } catch (error) {
          console.error('Error fetching procurement plans', error);
        }
      } else {
        setFavoriteProcurementData([]);
      }
      setFavoriteProcurementDataLoading(false);
    };
    fetchFavoriteProcurementData();
  }, [favoritePlannedProcurementsData, getProcurementPlan]);

  if (
    loading ||
    favoritesLoading ||
    followingLoading ||
    favoritePlannedProcurementsLoading ||
    favoriteProcurementDataLoading ||
    supplierBulletinsLoading
  ) {
    return <LoadingIndicator />;
  }

  if (
    error ||
    favoritesError ||
    followingError ||
    favoritePlannedProcurementsError ||
    supplierBulletinsError
  ) {
    const errorMsg = (error || favoritesError || followingError || favoritePlannedProcurementsError || supplierBulletinsError).toString();
    return (
      <>
        <Typography variant="subtitle1">Noe gikk galt:</Typography>
        <p>{errorMsg}</p>
      </>
    );
  }

  const searches = (data as SearchApiResponse[]).map(toSearch);
  const reloadSearches = () => {
    window.location.reload();
  };

  return (
    <SearchesContent
      initialSearches={searches}
      favorites={favoritesData as AnbudBrukerFavorite[]}
      following={followingData as CompanyFollower[]}
      favoriteProcurementData={favoriteProcurementData}
      supplierBulletins={supplierBulletinsData as SupplierBulletin[]}
      reloadSearches={reloadSearches}
      reloadSupplierBulletins={reloadSupplierBulletins}
    />
  );
};
interface SearchesContentProps {
  initialSearches: Search[];
  reloadSearches: () => void;
  favorites: AnbudBrukerFavorite[];
  following: CompanyFollower[];
  favoriteProcurementData: CombinedFavoriteData[];
  supplierBulletins: SupplierBulletin[];
  reloadSupplierBulletins: () => void; // Added this prop
}

export const SearchesContent: React.FC<SearchesContentProps> = ({
  initialSearches,
  favorites,
  following,
  favoriteProcurementData,
  supplierBulletins,
  reloadSupplierBulletins,
}) => {
  const [searches, setSearches] = useState(initialSearches);
  const [favoriteAnbuds, setFavoriteAnbuds] = useState(favorites.map((fav) => fav.anbud));
  const [followedCompanies, setFollowedCompanies] = useState(following);
  const [favoriteProcurementList, setFavoriteProcurementList] = useState<CombinedFavoriteData[]>([]);
  const [supplierBulletinList, setSupplierBulletinList] = useState<SupplierBulletin[]>(supplierBulletins);

  // Update favoriteProcurementList when favoriteProcurementData changes
  useEffect(() => {
    setFavoriteProcurementList(favoriteProcurementData);
  }, [favoriteProcurementData]);

  // Update supplierBulletinList when supplierBulletins change
  useEffect(() => {
    setSupplierBulletinList(supplierBulletins);
  }, [supplierBulletins]);

  const [isInviteLoading] = useState(false);
  const [numberOfAvailableSearches, setNumberOfAvailableSearches] = useState<number>(15);

  const removeSearch = (search: Search) => {
    const newSearches = searches.filter((s) => s !== search);
    setSearches(newSearches);
  };

  const removeFavoriteAnbud = (anbudId: string) => {
    setFavoriteAnbuds((prev) => prev.filter((anbud) => anbud.doffinAnbudId !== anbudId));
  };

  const unfollowCompany = (companyExternalId: string) => {
    setFollowedCompanies((prev) =>
      prev.filter((companyFollower) => companyFollower.company.externalId !== companyExternalId)
    );
  };

  // API call to remove favorite planned procurement
  const { del: removeFavoriteApi, response: removeFavoriteResponse } = useApi('/api/procurement/favorites/remove');

  const handleRemoveFavoritePlannedProcurement = useCallback(async (plannedProcurementId: number) => {
    try {
      const success = await removeFavoriteApi(`?id=${plannedProcurementId}`);
      if (removeFavoriteResponse.ok && success) {
        setFavoriteProcurementList((prev) =>
          prev.filter((data) => data.plannedProcurement.id !== plannedProcurementId)
        );
      } else {
        console.error('Failed to remove favorite');
      }
    } catch (error) {
      console.error('Error removing favorite planned procurement:', error);
    }
  }, [removeFavoriteApi, removeFavoriteResponse]);

  // API call to remove supplier bulletin
  const { del: removeSupplierBulletinApi, response: removeSupplierBulletinResponse } = useApi('/api/bulletin');

  const handleRemoveSupplierBulletin = useCallback(async (supplierBulletinId: number) => {
    try {
      await removeSupplierBulletinApi(`/${supplierBulletinId}`);
      if (removeSupplierBulletinResponse.ok) {
        // Refetch the supplier bulletins
        reloadSupplierBulletins();
      } else {
        console.error('Failed to remove supplier bulletin');
      }
    } catch (error) {
      console.error('Error removing supplier bulletin:', error);
    }
  }, [removeSupplierBulletinApi, removeSupplierBulletinResponse, reloadSupplierBulletins]);

  const { finndoffUser } = useUserContext();

  useEffect(() => {
    if (finndoffUser && isAuthenticated) {
      // Set numberOfAvailableSearches based on user's product
      switch (finndoffUser?.selectedProduct?.productName) {
        case "PREMIUM":
          setNumberOfAvailableSearches(15);
          break;
        case "PLUSS":
          setNumberOfAvailableSearches(3);
          break;
        case "SMART":
          setNumberOfAvailableSearches(1);
          break;
        default:
          setNumberOfAvailableSearches(15); // default fallback
          break;
      }
    }
  }, [finndoffUser]);

  // Check if the user can add more searches
  const canAddSearch = searches.length < numberOfAvailableSearches;
  return (
    <Box padding={4}>
      {/* Existing Searches Section */}
      <Typography variant="h5" gutterBottom>
        Dine søk
      </Typography>
      <Grid container spacing={6}>
        {searches.map((search, i) => (
          <Fade key={i} in={true} timeout={Math.min(200 * (i + 1), 600)}>
            <Grid item key={i} md={4} sm={6} xs={12}>
              <SearchCard search={search} onDelete={removeSearch} finndoffUser={finndoffUser} />
            </Grid>
          </Fade>
        ))}

        {/* Conditionally render the Add button only if the user can add more searches */}
        {canAddSearch && (
          <Zoom in={true} timeout={500}>
            <Grid item style={{ display: "flex", justifyContent: "center" }} xs={12}>
              {!isInviteLoading ? (
                <NavLink to={`/portal/${paths.search}`} style={{ paddingTop: "7px" }}>
                  <Fab color="secondary" aria-label="add">
                    <AddIcon />
                  </Fab>
                </NavLink>
              ) : (
                <div>
                  <LoadingIndicator />
                </div>
              )}
            </Grid>
          </Zoom>
        )}
      </Grid>

      {/* Followed Companies Section */}
      {followedCompanies.length > 0 && (
        <Box mt={4}>
          <Typography variant="h5" gutterBottom>
            Bedrifter du følger
          </Typography>
          <Grid container spacing={6}>
            {followedCompanies.map((companyFollower, i) => (
              <Fade key={i} in={true} timeout={Math.min(200 * (i + 1), 600)}>
                <Grid item key={i} md={4} sm={6} xs={12}>
                  <FollowedCompanyCard
                    companyFollower={companyFollower}
                    onUnfollowCompany={unfollowCompany}
                    finndoffUser={finndoffUser}
                  />
                </Grid>
              </Fade>
            ))}
          </Grid>
        </Box>
      )}

      {/* Favorites Section */}
      {favoriteAnbuds.length > 0 && (
        <Box mt={4}>
          <Typography variant="h5" gutterBottom>
            Arkiverte kunngjøringer
          </Typography>
          <Grid container spacing={6}>
            {favoriteAnbuds.map((anbud, i) => (
              <Fade key={i} in={true} timeout={Math.min(200 * (i + 1), 600)}>
                <Grid item key={i} md={4} sm={6} xs={12}>
                  <FavoriteAnbudCard
                    anbud={anbud}
                    onRemoveFavorite={removeFavoriteAnbud}
                    finndoffUser={finndoffUser}
                  />
                </Grid>
              </Fade>
            ))}
          </Grid>
        </Box>
      )}

      {/* Favorite Planned Procurements Section */}
      {favoriteProcurementList.length > 0 && (
        <Box mt={4}>
          <Typography variant="h5" gutterBottom>
            Lagrede planlagte anskaffelser
          </Typography>
          <Grid container spacing={2}>
            {favoriteProcurementList.map((data, i) => (
              <Fade key={i} in={true} timeout={Math.min(200 * (i + 1), 600)}>
                <Grid item key={i} md={12} sm={12} xs={12}>
                  <FavoritePlannedProcurementCard
                    plannedProcurement={data.plannedProcurement}
                    procurementPlan={data.procurementPlan}
                    onRemoveFavorite={handleRemoveFavoritePlannedProcurement}
                  />
                </Grid>
              </Fade>
            ))}
          </Grid>
        </Box>
      )}

      {/* Supplier Bulletins Section */}
      {supplierBulletinList.length > 0 && (
        <Box mt={4}>
          <Typography variant="h5" gutterBottom>
            Dine leverandørbulletiner
          </Typography>
          <Grid container spacing={6}>
            {supplierBulletinList.map((bulletin, i) => (
              <Fade key={i} in={true} timeout={Math.min(200 * (i + 1), 600)}>
                <Grid item key={i} md={4} sm={6} xs={12}>
                  <SupplierBulletinCard
                    bulletin={bulletin}
                    onRemoveBulletin={handleRemoveSupplierBulletin}
                    finndoffUser={finndoffUser}
                  />
                </Grid>
              </Fade>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export const Searches = withAuthenticationRequired(SearchesPure, {
  onRedirecting: () => <LoadingIndicator />,
});
