import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, CircularProgress, Backdrop, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../../utils/hooks/useApi'; // Adjust the import according to your project structure
import { SupplierBulletin } from './datatypes';
import { paths } from '../../../app/paths';

const SupplierBulletinList: React.FC = () => {
    const [bulletins, setBulletins] = useState<SupplierBulletin[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const navigate = useNavigate();
    const { get: getBulletins, error, loading: apiLoading } = useApi('/api/bulletin');

    const fetchBulletins = async () => {
        setLoading(true);
        const result = await getBulletins();
        if (result) {
            setBulletins(result);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchBulletins().catch(console.error);
    }, []);
    const handleCardClick = (id: string) => {
        const detailPath = `${paths.portal}/underleverandorbulletin?bulletinTenderId=${id}`;
        navigate(detailPath);
    };
    
    return (
        <Box sx={{ paddingLeft: 2, paddingRight: 2, position: 'relative' }}>
            {(loading || apiLoading) && (
                <Backdrop open={loading || apiLoading} style={{ zIndex: 1000, position: 'absolute', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.85)' }}>
                    <CircularProgress sx={{ color: 'white' }} />
                    <Typography variant="h6" style={{ marginTop: '10px', color: 'white' }}>
                        Laster søknader...
                    </Typography>
                </Backdrop>
            )}
            <Grid container spacing={2}>
                {bulletins.map((bulletin) => (
                    <Grid item xs={12} sm={6} md={4} key={bulletin.id}>
                        <Card 
                            style={{ borderRadius: '10px', marginBottom: '20px', backgroundColor: 'white', padding: '0', boxSizing: 'border-box', cursor: 'pointer' }}
                            onClick={() => handleCardClick(bulletin.tender?.doffinAnbudId)}
                        >
                            <div style={{ borderRadius: '10px 10px 0 0', backgroundColor: '#0B2333', color: "white", padding: '10px 20px', fontSize: '1.1244rem' }}>
                                <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '1.1244rem', color: "white" }}>{bulletin.title}</Typography>
                                {/* <Typography variant="body2" style={{ color: '#0B2333' }}>{bulletin.tender.noticeType}</Typography> */}
                            </div>
                            <CardContent style={{ padding: '20px' }}>
                                <Typography variant="body2" style={{ marginBottom: '10px' }}><strong>Interesserte brukere: </strong>{bulletin.userInterests.length}</Typography>
                                <Typography variant="body2" style={{ marginBottom: '10px' }}><strong>Opprettet: </strong>{new Date(bulletin.createdAt).toLocaleDateString()}</Typography>
                                <Typography variant="body2" style={{ marginBottom: '10px' }}><strong>Oppdatert: </strong>{new Date(bulletin.updatedAt).toLocaleDateString()}</Typography>
                                <Typography variant="body2" style={{ marginBottom: '10px' }}><strong>Søknadsfrist: </strong>{bulletin.applicationDeadline ? new Date(bulletin.applicationDeadline).toLocaleDateString() : 'N/A'}</Typography>
                                <Typography variant="body2" style={{ marginBottom: '10px' }}><strong>Beskrivelse: </strong>{bulletin.description}</Typography>
                                <Typography variant="body2" style={{ marginBottom: '10px' }}><strong>Status: </strong>{bulletin.status}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default SupplierBulletinList;
