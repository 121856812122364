import { FunctionComponent, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";

import { FinndoffUser } from "../../../utils/contexts/UserContext";
import { SecondaryButton } from "../../../components/Buttons/SecondaryButton";
import { RegisterDialog } from "./RegisterDialog";
import { LightButton } from "../../../components/Buttons/LightButton";

type MyCompanyCardProps = { finndoffUser: FinndoffUser };

export const MyCompanyCard: FunctionComponent<MyCompanyCardProps> = ({ finndoffUser }) => {
  const [openRegisterDialog, setOpenRegisterDialog] = useState(false);
  const handleOpenRegisterDialog = () => setOpenRegisterDialog(true);
  const handleCloseRegisterDialog = () => setOpenRegisterDialog(false);

  const orgnr = finndoffUser.orgNummer ? finndoffUser.orgNummer : "-";
  const addr1 = finndoffUser.adresselinje1 ? finndoffUser.adresselinje1 : "-";
  const addr2 = finndoffUser.adresselinje2 ? finndoffUser.adresselinje2 : "";
  const postnnr = finndoffUser.postNummer ? finndoffUser.postNummer : "";
  const telefon = finndoffUser.kontaktpersonMobil ? finndoffUser.kontaktpersonMobil : "-";
  const firmaNavn = finndoffUser.firmaNavn ? finndoffUser.firmaNavn : "";

  return (
    <Grid container>
      <Grid item xs={9} sx={{ backgroundColor: "transparent" }}>
        <Typography variant="h5" gutterBottom color="turquoise.main">
          Organisasjonsnummer
        </Typography>
        <Box>
          {firmaNavn} {orgnr}
        </Box>
        <Typography variant="h5" gutterBottom marginTop="1rem" color="turquoise.main">
          Adresse
        </Typography>
        <Box>{addr1}</Box>
        <Box>{addr2}</Box>
        <Box>
          {postnnr} {finndoffUser.postSted}
        </Box>
        <Typography variant="h5" gutterBottom marginTop="1rem" color="turquoise.main">
          Telefon
        </Typography>
        <Box>{telefon}</Box>
        {/* <div className={classes.cardSpacer} /> */}
        {/* <Typography color="primary">Ønsker nyhetsbrev </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={finndoffUser.nyhetsbrev} disabled />}
                    label="Ønsker nyhetsbrev"
                  />
                </FormGroup> */}
        <Grid marginTop="1rem">
          <LightButton
            onClick={handleOpenRegisterDialog}
            text={finndoffUser?.orgNummer ? "Administrer selskap" : " Registrer selskap"}
          />
        </Grid>
      </Grid>
      <RegisterDialog open={openRegisterDialog} closeModal={handleCloseRegisterDialog} />
    </Grid>
  );
};
