import { Chip } from "@mui/material";
import { FunctionComponent } from "react";

export const StyledChip: FunctionComponent<{ label: string }> = ({ label }) => {
  return (
    <Chip
      label={label}
      sx={{
        backgroundColor: "#CCE6E7",
      }}
    />
  );
};
