import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { config } from '../../config';
import { Link } from '@mui/material';

const TenderRedirect: React.FC = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        const tenderId = queryParams.get('tenderId');

        if (!token || !tenderId) {
            setError('Mangler token eller anbud ID');
            setLoading(false);
            return;
        }

        const fetchTenderUrl = async () => {
            try {
                const response = await fetch(config.backendUrl + `/api/tender?token=${encodeURIComponent(token)}&tenderId=${encodeURIComponent(tenderId)}`, {
                    method: 'GET'
                });

                if (!response.ok) {
                    throw new Error('Kunne ikke hente anbuds-URL');
                }

                const tenderUrl = await response.text();

                if (tenderUrl) {
                    console.log('Omdirigerer til:', tenderUrl); // Logging the URL to be redirected to
                    window.location.href = tenderUrl; // Redirect to the tender URL
                } else {
                    setError('Anbuds-URL ble ikke funnet');
                }
            } catch (err) {
                console.error('Feil ved henting av anbuds-URL:', err); // Logging the error
                setError('Feil ved uthenting av anbudet!');
            } finally {
                setLoading(false);
            }
        };

        fetchTenderUrl();
    }, [location.search]);

    return (
        <Container>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="40vh"
                paddingTop="20px"
                paddingBottom="20px"
            >
                {loading ? (
                    <CircularProgress />
                ) : error ? (
                    <>
                        <Typography variant="h6" color="error" align="center">
                            {error}
                        </Typography>
                        <Link
                            href="https://share-eu1.hsforms.com/183V6W1IGRcikwgsn9G8Nngfai8j"
                            target="_blank"
                            rel="noreferrer"
                            sx={{
                                fontSize: "1.2rem",
                                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 500,
                                lineHeight: "1.6",
                                letterSpacing: "0.0075em",
                                position: "relative",
                                color: "#008489",
                                marginBottom: "24px",
                                marginTop: "10px",
                                textAlign: "center",
                            }}
                        >
                            Si gjerne ifra om du ser denne meldingen!
                        </Link>
                    </>
                ) : null}
            </Box>
        </Container>
    );
};

export default TenderRedirect;
