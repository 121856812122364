import { FunctionComponent } from "react";
import { Box, Button, Card, CardActions, CardContent, Grid, Link, Typography } from "@mui/material";
import { useApi } from "../../../utils/hooks/useApi";
import { useVilkaarModal } from "../../../components/Modal/VilkaarModal";
import { config } from "../../../config";

import abonnement from "../../../images/myabo.png";
import { useUserContext } from "../../../utils/contexts/UserContext";
import { SecondaryButton } from "../../../components/Buttons/SecondaryButton";
import dayjs from "dayjs";
import { theme } from "../../../theme/theme";
import { LightButton } from "../../../components/Buttons/LightButton";

export const SubscriptionCard: FunctionComponent = () => {
  const { response: portalSessionResponse, post: postPortalSession } = useApi("/api/me/abonnement/portal-session");
  const { response: aboCreateSessionResponse, post: postPaymentCreateSession } = useApi(
    "/api/me/abonnement/create-session"
  );

  const { finndoffUser } = useUserContext();
  const { openVilkaarModal, VilkaarModal } = useVilkaarModal();

  const createPortalSession = async () => {
    await postPortalSession({
      returnUrl: window.location.href,
    }).then(() => {
      if (portalSessionResponse.ok) window.location.href = portalSessionResponse.data.url;
      else console.log(portalSessionResponse);
    });
  };

  const createPaymentCreateSession = async (stripePriceId: string) => {
    await postPaymentCreateSession({
      stripePriceId: stripePriceId,
      successUrl: window.location.href,
      cancelUrl: window.location.href,
    }).then(() => {
      if (aboCreateSessionResponse.ok) window.location.href = aboCreateSessionResponse.data.url;
      else console.log(aboCreateSessionResponse);
    });
  };

  const translatePaymentMethod = (method: string) => {
    switch (method) {
      case 'Invoice':
        return 'Faktura';
      case 'Card':
        return 'Kort';
      case 'None':
        return 'Ingen satt';
      default:
        return '-';
    }
  };

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return '';
    return dayjs(dateString).format('DD.MM.YYYY');
  };

  const getSubscriptionType = (trialEndDate: string | undefined) => {
    if (!trialEndDate) return 'Prøveperiode';
    return dayjs(trialEndDate).isBefore(dayjs()) ? 'Kunde' : 'Prøveperiode';
  };

  const getIsCustomer = (trialEndDate: string | undefined) => {
    if (!trialEndDate) return false;
    return dayjs(trialEndDate).isBefore(dayjs()) ? false : true;
  };

  return (
    <Card sx={{ backgroundColor: "transparent" }}>
      <Grid container>
        <Grid
          item
          xs={2}
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            backgroundPosition: "center top",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
        <Box
          component="img"
          src={abonnement}
          alt="Finndoff, Norges beste anbudsvarsling"
          sx={{
            display: "flex",
            marginBottom: "16px",
            marginTop: "6%",
            margin: "8px",
            maxWidth: "100%",
            maxHeight: "100%",
            borderRadius: "4px",                
            width: 200,
            height: 200,
          }}
        />
        </Grid>
        <Grid item xs={10} sx={{ backgroundColor: "transparent" }}>
          <CardContent>
            {finndoffUser?.active ? (
            <Grid container>
              <Grid item xs={12} sx={{marginBottom: "16px"}}>
                <Box>
                  <Typography variant="h5" gutterBottom>
                    JA! Du har et aktivt abonnement på Norges beste anbudsvarsling!
                  </Typography>
                  <Typography variant="body2">
                    Du er i trygge hender og Finndoff vil overvåke markedet og sende deg varslinger så fort søkene dine får treff på spennende kunngjøringer!
                    {finndoffUser?.selectedProduct?.productName === "SMART" && (
                      <>
                        <br />
                        <br />
                        <Box
                          sx={{
                            backgroundColor: '#f5f5f5', 
                            borderRadius: '8px',
                            padding: '16px', 
                            marginBottom: '24px',
                          }}
                        >
                          <strong>
                            Ønsker du ytterligere epost-mottakere eller fakturabetaling, kan du oppgradere til PLUSS. 
                            For å i tillegg få innsikt i offentlige investeringsplaner som kan være avgjørende for din bedrift, prøv du vårt PREMIUM-abonnement.
                          </strong>
                          <Grid
                            container
                            spacing={2}
                            alignItems="flex-start"
                          >
                            <Link
                              href="https://share-eu1.hsforms.com/183V6W1IGRcikwgsn9G8Nngfai8j"
                              target="_blank"
                              rel="noreferrer"
                              sx={{
                                fontSize: "1.0rem",
                                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 500,
                                lineHeight: "1.6",
                                letterSpacing: "0.0075em",
                                position: "center",
                                color: "#008489",
                                marginTop: "15px",                      
                                marginLeft: "10px",
                                padding: "5px",
                                "&:visited": {
                                  color: "#008489",
                                },
                                "&:hover": {
                                  backgroundColor: "#008489",
                                  color: "#FFFFFF",
                                },
                              }}
                            >
                              Kontakt oss her for å oppgradere!
                            </Link>
                          </Grid>
                        </Box>
                      </>
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3} sx={{ backgroundColor: 'transparent' }}>
                <Box>
                  <Typography variant="h5" gutterBottom sx={{color: theme.palette.turquoise.main}}>
                    Produktpakke
                  </Typography>
                  <Box>
                    {finndoffUser?.selectedProduct?.productName ? finndoffUser?.selectedProduct.productName : "Ikke satt"}
                  </Box>
                </Box>
                <Box>
                  <Typography variant="h5" gutterBottom sx={{color: theme.palette.turquoise.main}}>
                    Din betalingsmetode
                  </Typography>
                  <Box>
                    {translatePaymentMethod(finndoffUser?.paymentMethod)}
                  </Box>
                </Box>
                {finndoffUser?.paymentPeriodExpiryDate && (
                  <Box>
                    <Typography variant="h5" gutterBottom color={{color: theme.palette.turquoise.main}}>
                      Neste betaling
                    </Typography>
                    <Box>
                      {formatDate(finndoffUser.paymentPeriodExpiryDate)}
                    </Box>
                  </Box>
                )}
                {finndoffUser?.trialEndDate && getIsCustomer(finndoffUser?.trialEndDate) && (
                  <Box>
                    <Typography variant="h5" gutterBottom color={{color: theme.palette.turquoise.main}}>
                      Abonnementstype
                    </Typography>
                    <Box>
                      {getSubscriptionType(finndoffUser?.trialEndDate)}
                    </Box>
                  </Box>
                )}
                <Box sx={{ float: "left" }} width="100%">
                  {/* Sjekke om brukeren har et aktivt abonnement. Endre tekst om ab er aktivt eller ikke */}
                  {finndoffUser?.active ? (
                    <>
                      {finndoffUser?.paymentMethod === "Invoice" ? (
                        <Grid
                          container
                          // className={classes.container}
                          spacing={2}
                          alignItems="flex-start"
                        >
                          <Link
                            href="https://share-eu1.hsforms.com/183V6W1IGRcikwgsn9G8Nngfai8j"
                            target="_blank"
                            rel="noreferrer"
                            sx={{
                              fontSize: "1.2rem",
                              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                              fontWeight: 500,
                              lineHeight: "1.6",
                              letterSpacing: "0.0075em",
                              position: "relative",
                              color: "#008489",
                              marginBottom: "24px",
                              marginTop: "24px",                      
                              marginLeft: "10px",
                              padding: "5px",
                              "&:visited": {
                                color: "#008489",
                              },
                              "&:hover": {
                                backgroundColor: "#008489",
                                color: "#FFFFFF",
                              },
                            }}
                          >
                            Kontakt oss for å administrere fakturaabonnement
                          </Link>
                        </Grid>
                      ) : (
                        <SecondaryButton onClick={createPortalSession} text={"Administrer abonnement"} />
                      )}
                    </>
                  ) : (
                    <Box>
                      <SecondaryButton
                        onClick={() => createPaymentCreateSession(config.stripePrice299)}
                        text={"Opprett årsabonnement"}
                      />
                      {/* <div className={classes.spacer} />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => createPaymentCreateSession(config.stripePrice499)}
                      >
                        Opprett PLUSS-abonnement
                      </Button> */}
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
            ) : (
              <Box>
                <Typography variant="h5" gutterBottom>
                  Du har dessverre ikke et aktivt abonnement på Norges beste anbudsvarsling!
                </Typography>
                <Typography variant="body2" marginTop="16px">
                  Vårt mest populære abonnement koster KUN 499 i måneden (ved 12 måneders binding) og gir deg mulighet
                  til å sette opp så mange søk du ønsker. Spar verdifull tid ved å la Finndoff overvåke markedet for deg
                  og sende deg spennende oppdrag så fort de publiseres på Doffin! Om det er noe du lurer på, så ikke nøl
                  med å ta kontakt med oss så hjelper vi deg i gang!
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                marginTop: (theme) => theme.spacing(1),
                marginBottom: (theme) => theme.spacing(1),
              }}
            >
              <LightButton
                onClick={openVilkaarModal}
                text={"Vilkår for tjenesten"}
              />
            </Box>
          </CardContent>
          <CardActions
            sx={{
              justifyContent: "flex-end",
              "& a": {
                textDecoration: "none",
              },
            }}
          >
          </CardActions>
        </Grid>
        <VilkaarModal />
      </Grid>
    </Card>
  );
};
