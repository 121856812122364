import { ModalProps, useModal } from "./Modal";
import React from "react";
import { Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { FinndoffUser, useUserContext } from "../../utils/contexts/UserContext";
import { useAuth0 } from "@auth0/auth0-react";

export const useVilkaarModal = () => {
  const { openModal: openVilkaarModal, closeModal: closeVilkaarModal, Modal } = useModal();
  const modal: React.FC = () => <VilkaarModal Modal={Modal} closeModal={closeVilkaarModal} />;

  return { openVilkaarModal, closeVilkaarModal, VilkaarModal: modal };
};

interface VilkaarModalProps {
  Modal: React.FC<ModalProps>;
  closeModal: () => void;
}

const VilkaarModal: React.FC<VilkaarModalProps> = ({ Modal, closeModal }) => {
  const { user: auth0User, isAuthenticated } = useAuth0();
  const { finndoffUser, setFinndoffUser, isLoading } = useUserContext();

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <VilkaarModalContent
      Modal={Modal}
      closeModal={closeModal}
      auth0User={auth0User}
      finndoffUser={finndoffUser}
      setFinndoffUser={setFinndoffUser}
    />
  );
};

interface VilkaarModalContentProps extends VilkaarModalProps {
  auth0User: any;
  finndoffUser?: FinndoffUser;
  setFinndoffUser: (user: FinndoffUser) => void;
}

const VilkaarModalContent: React.FC<VilkaarModalContentProps> = ({
  Modal,
  closeModal,
  finndoffUser,
  setFinndoffUser,
}) => {
  const hasAccepted = finndoffUser ? true : false;

  const save = async () => {
    closeModal();
  };

  return (
    <Modal title={"Generelle Vilkår for Finndofftjenestene"}>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ position: "relative" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={save}
            sx={{
              top: "0%",
              right: "0%",
              position: "absolute",
              transform: "translateY(-50px)",
            }}
          >
            Lukk
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>1. Avtaleparter</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Avtaleparter er Finndoff AS (Org nr 927 436 442), heretter kalt Finndoff, og bestiller, heretter kalt
                Kunden. Med Kunden menes den juridiske person eller enkelte næringsdrivende som oppgis i
                ordrebekreftelsen. Avtalen, inkludert disse Generelle Vilkårene og Databehandleravtalen som er vedlagt
                de samme Generelle Vilkårene som Vedlegg 1, anses som akseptert og godkjent av Kunden ved bestilling av
                en eller flere Finndofftjenester via Finndoffs nettsider eller plattformer, eller ved bestilling på
                annen måte når Kunden har bekreftet bestillingen skriftlig.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>2. Avtalens omfang m.m.</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Ved inngåelse av avtalen med Finndoff tildeler kunden Finndoff, i tråd med den/de
                brukerprofil/brukerinnstillinger som er opprettet for Kunden, til å gi opplysninger om offentlige
                anskaffelser i samsvar med hva som inngår i den eller de Finndofftjenestene Kunden har bestilt. Kunden
                har rett til å søke, hente og motta denne informasjonen om offentlige anskaffelser for eget bruk.
                Automatiserte hentinger av informasjon av Finndoff sine Finndofftjenester og nettsider er ikke tillatt.
                Kunden har ansvaret for å kontrollere at fakturerings- og påloggingsinformasjon er korrekt registrert
                hos Finndoff. Kunden er videre ansvarlig for at all informasjon Kunden eller Kundens brukere lagrer,
                bruker eller på annen måte behandler innenfor Finndofftjenestenes rammer, er i samsvar med gjeldende
                lovverk. Kunden har en ikke-eksklusiv rett via Finndoff sine Finndofftjenester å benytte den informasjon
                og de tjenester som beskrives i ordrebekreftelsen. For Finndoff sine Finndofftjenester og nettsider
                gjelder bruksretten kun for det antall brukere som er oppgitt i ordrebekreftelsen. Kunden har
                imidlertid, i de tilfeller der en navngitt person slutter i sin jobb eller får endret stilling, rett til
                å endre bruker av Finndofftjenesten. I slike tilfeller skal Kunden informere Finndoff i forkant av at
                den nye brukeren benytter seg av adgangen til informasjon og tjenestene. Avtalens omfang fremgår av
                ordrebekreftelsen.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>3. Free Trial konto</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Disse Generelle Vilkårene gjelder også på aktuelle områder for Kunder med en såkalt “Free Trial” konto
                (gratis prøveperiode). En Free Trial konto er en gratislisens der Kunden og deres registrerte brukere
                kun gis en ikke-eksklusiv og begrenset tilgang til kunngjøringer til eget bruk i Finndoffdatabasen.
                Kundens Free Trial bruker vil motta e-postvarsler om oppdateringer for kunngjøringer som brukeren har
                valgt å følge. Dette vil skje så lenge brukeren er koblet til en aktiv og gjeldende Free Trial lisens.
                Free Trial lisensen er tidsbegrenset etter nærmere avtale (typisk 21 dager), er gratis og leveres av
                Finndoff i sin nåværende form.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>4. Avtaleperiode, oppsigelse m.m</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Avtalen løper i ett (1) år, med mindre annen løpetid er avtalt, fra bestillingen er gjort via Finndoffs
                nettsider eller plattformer, eller når Kunden har bekreftet bestillingen skriftlig i tilfeller der
                bestillingen gjøres på annen måte. Oppsigelse av avtalen skal skje skriftlig til den andre parten senest
                nitti (90) dager før avtaleperiodens utløp. Dersom det ikke foreligger oppsigelse, fornyes avtalen med
                ett år av gangen. Dersom det avtales månedlig avtaleperiode skal oppsigelsen skje senest fjorten (14)
                dager før avtaleperiodens utløp. Dersom det ikke foreligger oppsigelse, fornyes avtalen med en (1) måned
                av gangen. I de tilfeller Kunden sier opp brukerlisensen til en av Finndofftjenestene, eller der
                prøveperioden for Free Trial konto utløper, vil Finndoff deaktivere den oppsagte brukerlisensen etter
                endt avtaleperiode. Det betyr at Kundens brukere ikke lenger kan logge inn på tjenesten og at eventuelle
                e-post-varslinger deaktiverses. Kunden kan be Finndoff reaktivere lisensen til gjeldene kommersielle
                betingelser og vil da igjen få tilgang til tidligere registrerte data, søk og varslinger tilknyttet sin
                brukerprofil. Finndoff vil automatisk avslutte og slette brukerprofiler, uten forvarsel til Kunden,
                dersom Kundens brukere har vært inaktive (ikke påloggede) på kontoen i en sammenhengende periode på mer
                enn tre år.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>5. Tilgang til Finndofftjenestene</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Finndofftjenestene er normalt tilgjengelige døgnet rundt. Finndoff forbeholder seg retten til å stenge
                av Finndofftjenestene for normalt vedlikehold. Vedlikehold vil hovedsakelig skje på natten og i helger
                for i så liten grad som mulig å påvirke Kundens bruk av tjenestene.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>6. Priser m.m.</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Priser for de Finndofftjenester som omfattes av avtalen fremgår av ordrebekreftelsen. Prisene som er
                oppgitt gjelder for 1 bruker. Kunden står fritt til å registrere flere brukere og fakturert beløp vil da
                øke tilsvarende. På alle priser påløper merverdiavgift og eventuelle andre offentlige avgifter. Finndoff
                forbeholder seg retten til årlig prisjustering.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>7. Betalingsbetingelser</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Betaling skal skje forskuddsvis per år mot faktura med betalingsforfall 30 dager etter fakturadato. Ved
                forsinket betaling påløper forsinkelsesrente i henhold til renteloven. Refusjon av vederlag vil kun
                foretas hvis Kunden har lidd tap i henhold til punkt 8 siste avsnitt i denne avtalen.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>8. Ansvar</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Informasjonen Kunden mottar fra Finndoff sine Finndofftjenester og nettsider er innhentet fra offentlig
                tilgjengelig informasjon. Finndoff har ikke kontrollert riktigheten eller fullstendigheten av denne
                informasjonen og har ikke ressurser til å utføre slike kontroller. Finndoff påtar seg derfor ikke ansvar
                for den registrerte informasjon. Ansvar for den registrerte informasjon påhviler den enkelte
                informasjonsgiver. Finndoff vil likevel så langt det går korrigere eventuell feilaktig informasjon så
                snart Finndoff får kjennskap til feilen. Finndoffs nettsider og Finndofftjenester kan inneholde linker
                til andre nettsider. Finndoff har ikke ansvar for tilgjengeligheten på slike nettsider eller for
                innholdet i disse, for deres sikkerhet eller for hvordan de behandler personopplysninger. Finndoff skal
                ikke være ansvarlig for direkte eller indirekte tap eller følgeskader relatert til Finndofftjenestene,
                inkludert uteblitt inntekt eller lignende, også selv om Finndoff informeres om muligheten for slikt tap.
                Finndoff er ansvarlig for direkte tap som følge av forsett eller grov uaktsomhet. Finndoffs ansvar er
                under enhver omstendighet begrenset til direkte tap på et samlet beløp høyest tilsvarende en (1)
                årsavgift.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>9. Konfidensialitet m.m. </b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Kunden forplikter seg til å sørge for at brukernavn og passord hemmeligholdes slik at det ikke benyttes
                av uvedkommende. Kunden forplikter seg, unntatt når slik funksjonalitet følger med Finndofftjenesten,
                til ikke å avdekke eller på annet vis tilgjengeliggjøre informasjon, som er innhentet direkte av
                Finndoff eller via Finndoff sine Finndofftjenester til andre, samt påse at ingen andre enn ansatte i
                arbeid hos Kunden får tilgang til slik informasjon. Kunden har videre ikke rett til slik informasjon med
                unntak av det som følger av avtalen. Med unntak av offentlig tilgjengelig informasjon har Finndoff og
                deres leverandører full eiendomsrett og opphavsrett til den informasjon som er innhentet direkte av
                Finndoff eller via Finndoff sine Finndofftjenester og nettsider.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>10. Behandling av personopplysninger og databehandleravtale</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                For Finndoff er det viktig å møte kravene kundene stiller til overholdelse av de til enhver tid
                gjeldende regler for behandling av personopplysninger. I forholdet mellom Finndoff og Kunden vil
                Finndoff være behandlingsansvarlig der Finndoff bestemmer formålet og virkemidlene for behandlingen av
                personopplysningene. Der Finndoff er behandlingsansvarlig henvises det til vår personvernerklæring. Der
                behandlingen av personopplysningene skjer på vegne av Kunden vil Finndoff være databehandler. Det er
                derfor inngått en egen databehandleravtale som regulerer partenes roller og ansvar knyttet til
                behandlingen av personopplysninger som faller under avtalen. Databehandleravtalen, som inngår som en
                integrert del av avtalen, er lagt til som vedlegg 1 nederst i disse Generelle Vilkårene.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>11. Force Majeure</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Dersom en part er forhindret i å oppfylle sine forpliktelser i henhold til avtalen som følge av
                omstendigheter utenfor partenes kontroll, som for eksempel lynnedslag, arbeidskonflikt, brudd i den
                offentlige kraftforsyning, brann, beslutning av offentlig myndighet, systembrudd eller bristende
                tilgjengelighet i eksternt nettverk, skal dette utgjøre fritaksgrunn som medfører utsettelse av
                oppfyllelsesfrister og ansvarsfrihet.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>12. Forandringer og endringer i vilkårene</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Finndoff forbeholder seg retten til å endre Finndofftjenestenes utseende og funksjonalitet. Finndoff har
                videre rett til å endre vilkårene for avtalen, inkludert disse Generelle Vilkårene, Databehandleravtale
                samt gjeldende tekniske og administrative rutiner. Vesentlige endringer i vilkårene vil tre i kraft 30
                dager etter at Kunden og/eller Kundens brukere er blitt varslet om disse på en måte som Finndoff finner
                hensiktsmessig. I de tilfeller Kunden ikke aksepterer Finndoffs endrede vilkår, har Kunden rett til å si
                opp Finndofftjenesten med 30 dagers oppsigelsestid. Dette skal skje innen 30 dager fra og med det
                tidspunkt Kunden eller Kundens brukere fikk innsyn i de endrede vilkårene. En slik oppsigelse skal
                leveres skriftlig til Finndoff. Dersom Kunden ikke har benyttet seg av denne muligheten til å si opp
                Finndofftjenesten, anser man at Kunden har akseptert de endrede vilkårene. Innbetalt årsavgift vil ikke
                bli refundert når Kunden benytter seg av oppsigelsesretten i samsvar med nevnte punkt 12.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>13. Overdragelse</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Kunden har ikke rett til å overdra avtalen til andre, uten skriftlig forhåndsgodkjennelse fra Finndoff.
                Forutsetningen for slik godkjennelse er at den nye brukeren overtar avtalen under samme vilkår eller med
                nye vilkår tilpasset nye brukeren av Finndoff. Kunden har heller ikke rett til å oppgi eller overlevere
                brukernavn eller passord til andre.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>14. Mislighold</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Dersom Kunden misligholder vilkår i avtalen, kan Finndoff med umiddelbar virkning si opp avtalen og
                stenge tilgangen til ordrebekreftelsens oppgitte Finndofftjenester. Videre har Finndoff rett til å si
                opp avtalen med øyeblikkelig virkning i de tilfeller der Kunden erklæres konkurs, Kunden får oppnevnt
                forvalter, ved tvangsakkord, ved selskapsrekonstruksjon eller lignende, eller hvis kunden for øvrig
                anses for å være insolvent. Årsavgift på abonnement vil ikke bli tilbakebetalt.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>15. Tolkning</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Dersom det skulle forekomme motstridende opplysninger i konkurransegrunnlaget, skal dokumentene gis
                prioritet i følgende rekkefølge, om ikke annet er uttrykkelig uttalt: 1. Ordrebekreftelse, 2. Faktura,
                3. Databehandleravtale, 4. Generelle Vilkår.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>16. Tvist</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Tvist vedrørende tolkning eller oppfyllelse av avtalen skal avgjøres av norsk domstol etter norsk lov,
                der Oslo tingrett skal være første instans.
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: "32px" }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>Vedlegg 1 Databehandleravtale Innledning</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Denne databehandleravtalen (“Databehandleravtalen”) inngår i og regulerer Behandlingen av
                Personopplysninger i avtalen for Finndofftjenester mellom Partene ("Tjenesteavtalen"). Definisjoner
                Definisjonene av Personopplysning, Sensitiv Personopplysning, Behandling av Personopplysning, den
                Registrerte, Behandlingsansvarlig og Databehandler skal forstås slik de brukes og tolkes i henhold til
                gjeldende personvernlovgivning, inkludert personvernforordningen av 4. mai 2016 - General Data
                Protection Regulation (GDPR) som gjelder for denne Databehandleravtalen og i Europa fra 25 mai 2018.
                Formål Databehandleravtalen regulerer Databehandlerens Behandling av Personopplysninger på vegne av den
                Behandlingsansvarlige, og beskriver hvordan Databehandleren gjennom tekniske og organisatoriske
                virkemidler skal bidra til å sikre den registrertes rettigheter på vegne av den Behandlingsansvarlige.
                Formålet med Databehandlerens Behandling av Personopplysninger er å oppfylle Tjenesteavtalen og
                Databehandleravtalen. Ved eventuell motstrid mellom bestemmelser om Behandling av Personopplysninger har
                Databehandleravtalen forrang over Tjenesteavtale eller andre avtaler inngått mellom Partene. Denne
                Databehandleravtalen varer så lenge Databehandleren Behandler Personopplysninger til formål
                Behandlingsansvarlig har bestemt. Databehandlerens plikter Databehandler skal bare Behandle
                Personopplysninger på vegne av og i henhold til instruksjoner fra Behandlingsansvarlig. Ved å inngå
                denne Databehandleravtalen instruerer Behandlingsansvarlig Databehandler om å Behandle
                Personopplysninger på følgende måte: i) bare i henhold til gjeldende lovgivning, ii) for å oppfylle alle
                plikter i henhold til Tjenesteavtale, iii) som instruert via Behandlingsansvarlig sin bruk av
                Databehandlers ordinære tjenester og iv) som spesifisert i denne Databehandleravtalen. Databehandleren
                har ved avtaleinngåelsen ingen grunn til å anta at det foreligger regulatoriske hindringer mot å følge
                instruksjonene fra Behandlingsansvarlige. Dersom Databehandleren ved et senere tidspunkt blir klar over
                at Behandlingsansvarliges instruksjoner eller Behandling av Personopplysninger strider mot gjeldende
                personvernlovgivning, skal Databehandleren melde dette til Behandlingsansvarlige. Typen
                Personopplysninger og kategorier av registrerte som er gjenstand for Behandling under denne
                Databehandleravtalen er angitt i Vedlegg A. Databehandleren skal sikre konfidensialitet, integritet og
                tilgjengelighet til Personopplysningene i henhold til de regulatoriske krav som gjelder for
                Databehandleren. Dette inkluderer å implementere systematiske, organisatoriske og tekniske virkemidler
                for å sikre et tilstrekkelig nivå for sikkerhet. Ved avgjørelsen av hva som er et tilstrekkelig nivå
                skal hensyn til den teknologiske utviklingen og kostnaden ved implementering av tiltak veies mot
                risikoen ved Behandlingen og typen Personopplysninger som behandles. Databehandleren skal ved tekniske
                og organisatoriske virkemidler bistå Behandlingsansvarlig med å ivareta Behandlingsansvarliges plikter
                under GDPR artikkel 32 til 36, samt bistå i arbeidet med å Behandle forespørsler fra registrerte i
                henhold til GDPR kapittel III. Pliktens omfang avgrenses av formen for Behandling av Personopplysninger
                og hvilken informasjon som er tilgjengelig for Databehandleren. Behandlingsansvarlige kan kreve
                informasjon om de sikkerhetstiltak, dokumentasjon og annen informasjon om hvordan Databehandleren
                Behandler Personopplysninger. Dersom Behandlingsansvarlige ber om mer informasjon enn det som
                Databehandleren tilgjengeliggjør for å oppfylle kravene til rollen som Databehandler i henhold til
                gjeldende personvernlovgivning, kan Databehandleren kreve betaling for slike eventuelle
                tilleggstjenester. Databehandleren og dennes ansatte skal sørge for konfidensialitet ved Behandling av
                Personopplysninger som Behandles under denne Databehandleravtalen. Denne plikten gjelder også etter at
                Databehandleravtalen opphører. Databehandleren vil, gjennom å varsle Behandlingsansvarlig uten ugrunnet
                opphold om brudd på personopplysningssikkerheten, gjøre det mulig for Behandlingsansvarlig å oppfylle de
                rettslige krav som gjelder for informasjon til relevante datatilsynsmyndigheter og Registrerte gjeldende
                personopplysningsavvik. Videre vil Databehandleren, i den utstrekning det er praktisk mulig og lovlig,
                varsle Behandlingsansvarlig om; i) innsynsbegjæringer fra registrerte, ii) innsynsbegjæringer fra
                offentlige myndigheter Databehandleren vil kun besvare forespørsler fra registrerte i den grad
                Behandlingsansvarlig har gitt tillatelse til det. Databehandleren vil kun varsle Behandlingsansvarlig om
                innsynsbegjæringer fra offentlige myndigheter i den grad slikt varsel er lovlig, samt kun utlevere
                informasjon til offentlige myndigheter dersom rettslig pålegg foreligger. Databehandleren har ikke
                eierskap til eller kontroll med hvorvidt og hvordan Behandlingsansvarlig velger å benytte seg av
                eventuelle tredjeparts integrasjoner via Databehandlers API, via direkte databasekobling eller lignende.
                Ansvaret for slike integrasjoner med tredjepart påhviler utelukkende Behandlingsansvarlig.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>Behandlingsansvarliges plikter</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Ved å inngå Databehandleravtalen bekrefter Behandlingsansvarlig: ● All Behandling av Personopplysninger
                i forbindelse med Tjenesteavtalen skal foregå i overensstemmelse med gjeldende lovgivning. ●
                Behandlingsansvarlig har rett til å Behandle Personopplysninger og til å gi Databehandleren og dennes
                underleverandører adgang til å Behandle Personopplysninger. ● Behandlingsansvarlig er ansvarlig for at
                Personopplysningene som overlates til Databehandleren er lovlig innsamlet, er korrekte og
                tilstrekkelige. ● At alle regulatoriske krav vedrørende varsel til eller tillatelse fra
                tilsynsmyndigheten for Behandlingen av Personopplysninger er oppfylt. ● At forpliktelsen til å formidle
                relevant informasjon til registrerte vedrørende Behandlingen av Personopplysninger er oppfylt. ●
                Personopplysningsansvarlig bekrefter at Behandlingsansvarlig har gitt garanti for implementering av
                tekniske og organisatoriske sikkerhetstiltak som anses som tilstrekkelige for å beskytte den
                Registrertes integritet og Personopplysninger. ● At sensitive Personopplysninger kun vil bli behandlet
                som en del av bruk av tjenestene under Tjenesteavtalen der dette er uttrykkelig avtalt i Vedlegg A til
                Databehandleravtalen. ● At Behandlingsansvarlig vil ha et oppdatert register over typen
                Personopplysninger og kategorier av registrerte som behandles i forbindelse med Tjenesteavtalen.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>Bruk av underleverandører og overføring av Personopplysninger</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Som en del av leveransen under Tjenesteavtale og denne Databehandleravtalen vil Databehandleren bruke
                underleverandører. Databehandleren har plikt til å påse at underleverandører påtar seg tilsvarende
                forpliktelser som de som følger av denne Databehandleravtalen. Behandlingsansvarlig har rett til å be om
                en oversikt over hvilke underleverandører av Databehandler som har tilgang til Personopplysninger. Slik
                oversikt inkluderes i Vedlegg B. Behandlingsansvarlig kan når som helst be om en fullstendig oversikt og
                mer detaljert informasjon om underleverandørene som er involverte i Tjenesteavtalen.
                Behandlingsansvarlig informeres om bruk av nye underleverandører, eller opphør av eksisterende, på en
                måte som Databehandleren finner hensiktsmessig. Dersom det er klart at den nye underleverandøren ikke
                oppfyller gjeldende personvernlovgivning, og hvis underleverandøren fortsatt ikke følger gjeldende
                personvernlovgivning etter at Databehandleren har fått rimelig tid på seg til å sørge for at
                underleverandøren overholder regelverket, så kan Behandlingsansvarlig si opp Databehandleravtalen. Slik
                oppsigelse kan gi Behandlingsansvarlig rett til å terminere Tjenesteavtalen helt eller delvis i tråd med
                Tjenesteavtalens bestemmelser om terminering, der det særlig skal vektlegges i hvilken grad den aktuelle
                Behandlingen av Personopplysninger er en nødvendig del av Tjenesteavtalen. Endring av underleverandør
                vil i seg selv ikke bli ansett som et brudd på Tjenesteavtale. Ved å inngå denne Databehandleravtalen
                samtykker Behandlingsansvarlig til den bruk av underleverandører som er beskrevet ovenfor.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>Sikkerhet</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Databehandler skal sørge for et høyt sikkerhetsnivå i sine produkter og tjenester. Dette skal skje ved
                organisatoriske, tekniske og fysiske sikkerhetstiltak, i henhold kravene til informasjonssikkerhet som
                fremgår av GDPR artikkel 32. Finndoffs rammeverk for personvern skal sikre Personopplysningenes
                konfidensialitet, integritet, robusthet og tilgjengelighet. Følgende tiltak er særlig viktig i denne
                forbindelse: ● Klassifisering av Personopplysninger for å vurdere sikkerhetstiltakene på bakgrunn av
                risikovurderinger. ● Vurdere bruk av kryptering og pseudonymisering for å avhjelpe risiko ● Begrense
                tilgang til Personopplysninger til personell som trenger tilgang for å oppfylle plikter i henhold til
                denne Databehandleravtalen eller Tjenesteavtale. ● Systemer som avdekker, retter, forhindrer og
                rapporterer avvik ● Benytte sikkerhetsrevisjoner til å analysere hvorvidt de til enhver tids gjeldende
                tekniske og organisatoriske tiltak for å beskytte Personopplysninger er tilstrekkelig, sett i lys av
                gjeldende lovgivning.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>Rett til tilsyn</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Behandlingsansvarlig kan revidere Databehandler sin etterlevelse av denne Databehandleravtalen inntil en
                gang i året. Hvis lovgivning som Behandlingsansvarlig er underlagt krever det, kan Behandlingsansvarlig
                kreve flere revisjoner. For å be om revisjon må Behandlingsansvarlig sende en detaljert tilsynsplan
                minimum 4 uker i forkant av ønsket tilsynsdato, med oversikt over forslagets omfang, varighet og
                oppstart. Hvis tredjeparter skal gjennomføre tilsynet, skal dette som hovedregel avtales mellom Partene.
                Hvis Behandlingsansvarlig Behandling av Personopplysninger skjer i et skybasert miljø, aksepterer
                Behandlingsansvarlig likevel at tilsynet gjennomføres av en tredjepart utpekt av Databehandler. Hvis
                tilsynets omfang er behandlet i ISAE, ISO eller lignende rapport av kvalifisert tredjepart i løpet av de
                siste 12 månedene, og Databehandler bekrefter at det ikke finnes kjente endringer fra dette, skal
                Behandlingsansvarlig akseptere disse rapportene i stedet for å forespørre nytt tilsyn. I alle tilfeller
                skal tilsyn utføres i samråd med virksomhetens ordinære åpningstider, i henhold til virksomhetens
                retningslinjer og ikke forstyrre den ordinære virksomheten. Behandlingsansvarlig er ansvarlig for
                kostnader forårsaket av sitt tilsyn. Dersom Behandlingsansvarlige ber om mer assistanse enn den som
                tilbys av Databehandleren for å oppfylle gjeldende personvernlovgivning, kan Databehandleren kreve
                betaling for denne tilleggstjenesten.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>Varighet og oppsigelse</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Databehandleravtalen gjelder så lenge Databehandler Behandler Personopplysninger på vegne av
                Behandlingsansvarlig i henhold til Tjenesteavtale. Ved opphør av Databehandleravtalen, skal
                Databehandler slette, anonymisere eller returnere Personopplysninger som er behandlet på vegne av
                Behandlingsansvarlig i tråd med Vedlegg A. Med mindre annet er avtalt mellom Partene, skal arbeidet
                forbundet med dette kompenseres basert på; i) betaling for medgått tid og ii) kompleksiteten ved
                forespørselen. Databehandler kan beholde Personopplysninger etter opphøret av Databehandleravtalen i
                henhold til gjeldende lovgivning, underlagt de samme typer tekniske og organisatoriske tiltak som
                skissert i denne Databehandleravtalen.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>Endringer og ugyldighet</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Hvis bestemmelser i Databehandleravtalen kjennes ugyldig, skal ikke dette påvirke de øvrige
                bestemmelsene i Databehandleravtalen. Partene skal erstatte den ugyldige bestemmelsen med en gyldig
                bestemmelse som reflekterer intensjonen til Partene bak bestemmelsen.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>Mislighold</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Begge Parter har et individuelt ansvar og skal holdes selvstendig ansvarlig for å betale alle bøter som
                ilegges den respektive Part i henhold til GDPR. Ansvaret for øvrige brudd på Databehandleravtalen eller
                GDPR reguleres av den Tjenesteavtale som foreligger mellom Partene. Dette gjelder også for avvik
                forårsaket av Databehandler sine underleverandører. Gjeldende rett og verneting Denne
                Databehandleravtalen er underlagt gjeldende lov og jurisdiksjon som oppgis i respektive Tjenesteavtale
                mellom partene.
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: "32px" }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>Vedlegg A - Kategorier av Registrerte og Personopplysninger</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                1. Kategorier av Registrerte og Personopplysninger a. Kategorier av Registrerte Kategorier for
                Registrerte består først og fremst av Behandlingsansvarliges brukere, Behandlingsansvarliges
                kontaktpersoner samt personer Behandlingsansvarliges brukere har invitert og som får tilgang til
                Finndofftjenesten via Free Trial konto eller på annen måte. b. Kategorier av Personopplysninger m.m.
                Databehandler vil på oppdrag fra Behandlingsansvarlig behandle Personopplysninger som brukeren
                registrerer i Finndofftjenestene, for eksempel e-postadresser som angis ved deling av kunngjøringer og
                Personopplysninger som angis i fritekstfelt slik som i delinger av kunngjøringer, notater og
                påminnelser. Andre Personopplysninger som registreres vil bl.a. være hvilke kunngjøringer bruker mottatt
                i sin overvåking samt brukerens aktiviteter i Finndofftjenesten (slik som hvilke kunngjøringer en bruker
                lest, hvilke dokumenter som en bruker lastet ned m.m.), inkludert e-postkommunikasjon og
                chat-kommunikasjon. Formålet med behandlingen er å gi Behandlingsansvarliges brukere mulighet til å
                utnytte tjenestens funksjonalitet samt kommunisere med Behandlingsansvarliges brukere ved behov. Utover
                dette vil Databehandleren, på oppdrag fra Behandlingsansvarlig, registrere brukernes aktiviteter i
                Finndofftjenestene og på Databehandlerens nettsider. Informasjonen nevnt ovenfor utgjør også grunnlaget
                for at Databehandleren, på oppdrag fra Behandlingsansvarlig, skal kunne formidle informasjon til
                Behandlingsansvarlig og Behandlingsansvarliges brukere innenfor tjenestens rammer. Videre kan
                Databehandleren ved supportsaker få innsyn i brukeres registrerte aktiviteter for å kunne yte
                brukerstøtte samt rette eventuelle feil i tjenesten. 2. Sletting av Personopplysninger I de tilfeller
                der Behandlingsansvarlig sier opp brukerlisensen til en av Finndofftjenestene vil Databehandleren
                deaktivere den oppsagte brukerlisensen ved utløpt avtaleperiode, noe som innebærer at
                Behandlingsansvarliges bruker ikke lenger kan logge inn på eller benytte tjenesten. Når en konto
                avsluttes, sletter eller anonymiserer Databehandleren Personopplysningene som kan knyttes til
                gjestekontoen. Loggføringen av en brukeraktivitet i Finndofftjenesten slettes 3 år etter loggens
                opprettelse. De notater en bruker har gjort i Finndofftjenesten slettes ikke, uten anonymiseres når
                brukerens konto anonymiseres. 3. Retur av Personopplysninger På Behandlingsansvarliges etterspørsel
                returneres Personopplysninger til Behandlingsansvarlig i et maskinlesbart format innenfor 90 dager fra
                etterspørsel. Databehandler debiterer Behandlingsansvarlig for reelle kostnader for å ta fram og
                overlevere ovenfor beskrevne Personopplysninger.
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: "32px" }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>Vedlegg B - Oversikt underleverandører</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                For å kunne levere Finndofftjenestene benytter Databehandleren seg av følgende underleverandører, som
                Behandler Personopplysninger på vegne av Databehandleren. Oversikten over underleverandører kan endres
                under Databehandleravtalens løpetid. Databehandleren med Yalmar Solutions AS Oslo, Norge Daglig leder
                Vivende Rebase AS Oslo, Norge Systemutvikling Vivende AS Oslo, Norge Salg og brukerstøtte Microsoft
                Azure Lagring på server Twilio SendGrid Email adresser HubSpot CRM CRM kundeoppfølgingssystem
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: "32px" }} />
            <Grid item xs={12} sx={{ marginTop: "12px" }}>
              <FormControlLabel
                label="Ved å aktivere et abonnement hos Finndoff, har du godkjent de generelle brukervilkårene."
                control={<Checkbox checked={hasAccepted} />}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};
