import React, { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { SearchPage } from "./SearchPage";

const SearchEditPagePure: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  return <SearchPage id={id} />;
};

export const SearchEditPage = withAuthenticationRequired(SearchEditPagePure, {
  onRedirecting: () => <LoadingIndicator />,
});
