import { Navigate, RouteProps } from "react-router-dom";
import { SearchPage } from "./Search/SearchPage";
import { Searches } from "./Searches/Searches";
import { SearchEditPage } from "./Search/SearchEditPage";
import { StartPage } from "./Start/StartPage";
import { Signup } from "../Signup/Signup";
import { Profile } from "./Profile/Profile";
import { IFrame } from "./IFrame/IFrame";
import { paths } from "../../app/paths";
import { Logout } from "../../components/Logout/Logout";
import { Login } from "../../components/Login/Login";
import ProcurementPlanner from "./ProcurementPlanner/ProcurementPlanner";
import { SignupResult } from "../Signup/SignupResult";
import { SignupBransje } from "../Signup/SignupBransje";
import TenderRedirect from "../DoffinRedirect/TenderRedirect";
import TenderSummary from "../TenderSummary/TenderSummary";
import CompanySearch from "./Companies/Companies";
import CreateSupplierBulletin from "./SupplierBulletins/CreateSupplierBulletin";
import SupplierBulletinList from "./SupplierBulletins/SupplierBulletinList";
import SupplierBulletinDetail from "./SupplierBulletins/SupplierBulletinDetail";
import RegisterContactMePage from "../ContactMePage/RegisterContactMePage";
import { CpvLookup } from "../CpvLookup/CpvLookup";

export const contactUrl = "https://share-eu1.hsforms.com/183V6W1IGRcikwgsn9G8Nngfai8j";
export const wixBaseUrl = "https://market.finndoff.no";
export const wixPaths = {
  ressurser: `${wixBaseUrl}/nyheter`,
  omoss: `${wixBaseUrl}/product`,
  faq: `${wixBaseUrl}/faq`,
  price: `${wixBaseUrl}/priser`,
  tnc: `${wixBaseUrl}/tnc`,
  privacypolicy: `${wixBaseUrl}/privacypolicy`,
  start: `${wixBaseUrl}`,
};

export const routes: Array<RouteProps> = [
  {
    path: paths.signup,
    element: <Signup />,
  },
  {
    path: paths.industrysignup,
    element: <SignupBransje />,
  },
  {
    path: paths.logout,
    element: <Logout />,
  },
  {
    path: paths.login,
    element: <Login />,
  },
  {
    path: `${paths.login}/`,
    element: <Login />,
  },
  {
    path: `${paths.signupresult}/:externalUserId`,
    element: <SignupResult />,
  },
  {
    path: `${paths.tender}/`,
    element: <TenderRedirect />,
  },  
  {
    path: `${paths.tendersummary}/`,
    element: <TenderSummary />,
  },
  {
    path: `${paths.contactMeRegistration}/`,
    element: <RegisterContactMePage />,
  },
  {
    path: "*",
    element: <Navigate to={`${paths.portal}/start`} />,
  },
];

export const portalRoutes: Array<RouteProps> = [
  {
    path: paths.start,
    element: <StartPage />,
  },
  {
    path: paths.search,
    element: <SearchPage />,
  },
  {
    path: paths.searches,
    element: <Searches />,
  },
  {
    path: paths.searchEdit,
    element: <SearchEditPage />,
  },
  {
    path: paths.profile,
    element: <Profile />,
  },
  {
    path: paths.procurementplanner,
    element: <ProcurementPlanner />,
  },
  {
    path: paths.createsupplierbulletin,
    element: <CreateSupplierBulletin />,
  },
  {
    path: paths.supplierbulletinlist,
    element: <SupplierBulletinList />,
  },
  {
    path: paths.supplierbulletindetail,
    element: <SupplierBulletinDetail />,
  },
  {
    path: paths.companies,
    element: <CompanySearch />,
  },
  {
    path: paths.about,
    element: <IFrame src={wixPaths.omoss} id={"about_iframe"} />,
  },
  {
    path: paths.news,
    element: <IFrame src={wixPaths.ressurser} id={"news_iframe"} />,
  },
  {
    path: paths.contact,
    element: <IFrame src={contactUrl} id={"contact_iframe"} defaultHeight={"1100px"} />,
  },
  {
    path: paths.prices,
    element: <IFrame src={wixPaths.price} id={"price_iframe"} backgroundColor="#0B2333" />,
  },
  {
    path: paths.faq,
    element: <IFrame src={wixPaths.faq} id={"faq_iframe"} />,
  },
  {
    path: paths.tnc,
    element: <IFrame src={wixPaths.tnc} id={"tnc_iframe"} />,
  },
  {
    path: paths.privacypolicy,
    element: <IFrame src={wixPaths.privacypolicy} id={"privacypolicy_iframe"} />,
  },
  {
    path: paths.cpvoppslag,
    element: <CpvLookup />,
  },
  {
    path: paths.portal,
    element: <Navigate to={`${paths.portal}/start`} />,
  },
  {
    path: "*",
    element: <Navigate to={`${paths.portal}/start`} />,
  },
];
