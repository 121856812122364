import React from "react";
import { Dialog, Grid, IconButton, Link } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { paths } from "../../../app/paths";
import { LinkType } from "../Portal";
import { SecondaryButton } from "../../../components/Buttons/SecondaryButton";
import { LightButton } from "../../../components/Buttons/LightButton";
import styled from "@emotion/styled";

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
`;

interface MenuProps {
  links: Array<LinkType>;
  linksPremium: Array<LinkType>;
  handleLoginWithRedirect: () => void;
  isAuthenticated: boolean;
  isPremium: boolean;
}

export const ToolBarMenu: React.FunctionComponent<MenuProps> = ({
  handleLoginWithRedirect,
  links,
  isAuthenticated,
  linksPremium,
  isPremium
}) => {
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClick = (event: any) => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <IconButton aria-controls="customized-menu" aria-haspopup="true" onClick={handleClick}>
        <MenuIcon fontSize="large" sx={{ color: "black" }}></MenuIcon>
      </IconButton>
      <Dialog
        sx={{
          overflow: "auto",
        }}
        PaperProps={{
          sx: {
            backgroundColor: (theme) => theme.palette.primary.main,
          },
        }}
        fullScreen={true}
        open={openDialog}
        onClose={handleClose}
      >
        <Grid marginTop="1rem" container justifyContent="flex-end">
          <IconButton onClick={handleClose}>
            <CloseIcon
              fontSize="large"
              sx={{
                color: "white",
                fontSize: "6vw",
              }}
            />
          </IconButton>
        </Grid>
        <Grid container alignItems="center" justifyContent="center" direction="column" spacing={6}>
          {links
            .filter((link) => !link.onlyWhenAuthenticated)
            .map((link, index) => {
              return (
                <Grid
                  item
                  key={`${index}_menu_grid`}
                  sx={{
                    fontFamily: "Work Sans",
                    fontWeight: 600,
                    fontSize: "5vw",
                    color: "white",
                  }}
                >
                  <StyledLink
                    href={`/portal/${link.path}`}
                    key={`${index}_bottom`}
                    onClick={() => setOpenDialog(false)}
                  >
                    {link.text}
                  </StyledLink>
                </Grid>
              );
            })}
          {isAuthenticated &&
            links
              .filter((link) => link.onlyWhenAuthenticated)
              .map((link, index) => {
                return (
                  <Grid
                    item
                    key={`${index}_menu_grid`}
                    sx={{
                      fontFamily: "Work Sans",
                      fontWeight: 600,
                      fontSize: "5vw",
                    }}
                  >
                    <StyledLink
                      href={`/portal/${link.path}`}
                      key={`${index}_bottom`}
                      onClick={() => setOpenDialog(false)}
                    >
                      {link.text}
                    </StyledLink>
                  </Grid>
                );
              })}
              {isAuthenticated && isPremium &&
            linksPremium
              .filter((link) => link.onlyWhenAuthenticated)
              .map((link, index) => {
                return (
                  <Grid
                    item
                    key={`${index}_menu_grid`}
                    sx={{
                      fontFamily: "Work Sans",
                      fontWeight: 600,
                      fontSize: "5vw",
                    }}
                  >
                    <StyledLink
                      href={`/portal/${link.path}`}
                      key={`${index}_bottom`}
                      onClick={() => setOpenDialog(false)}
                    >
                      {link.text}
                    </StyledLink>
                  </Grid>
                );
              })}
        </Grid>
        <Grid marginTop="2rem" container justifyContent="center" direction="row" spacing={4}>
          {!isAuthenticated ? (
            <>
              <Grid item>
                <SecondaryButton link={paths.signup} text="Prøv oss" dynamicSize="3" />
              </Grid>
              <Grid item>
                <LightButton onClick={handleLoginWithRedirect} text="Logg inn" dynamicSize="3" />
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                <LightButton link={paths.logout} text="Logg ut" dynamicSize="3" />
              </Grid>
            </>
          )}
        </Grid>
      </Dialog>
    </>
  );
};
