import React from "react";
import { Typography } from "@mui/material";

interface ErrorsProps {
  errors: Error[];
}
export const Errors: React.FC<ErrorsProps> = ({ errors }) => {
  return (
    <>
      <Typography variant="subtitle1">Noe gikk galt:</Typography>
      <ul>
        {errors
          .filter((error) => !!error)
          .map((error, i) => (
            <li key={i}>{error.toString()}</li>
          ))}
      </ul>
    </>
  );
};
