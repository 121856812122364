import { useApi } from "../../../utils/hooks/useApi";
import { initialSearch, isNew, Search } from "../../../Types/Search";

export type SearchApiResponse = {
  eksternId: string;
  navn: string;
  searchText: string;
  cpvKodeIds: number[];
  cpvKodeCPV: number[];
  eksternBrukerId: string;
  stedIds: number[];
  mottakere: string[];
  erAktiv: boolean;
  visAktive: boolean;
  noticeTypes: string[];
  includeParentCodes: boolean;
  textOnlyAlerts: boolean;
  filterNationalTenders: boolean;
  filterRegionalTenders: boolean;
  valueMin:number;
  valueMax:number;
  isCanceled: boolean;
};

type SearchApiRequestBody = {
  navn: string;
  cpvKodeIds: number[];
  cpvKodeCPV: number[];
  stedIds: number[];
  mottakere: string[];
  searchText: string;
  erAktiv: boolean;
  noticeTypes: string[];
  includeParentCodes: boolean;
  textOnlyAlerts: boolean;
  filterNationalTenders: boolean;
  filterRegionalTenders: boolean;
  valueMin:number;
  valueMax:number;
  isCanceled: boolean;
};

export const toSearch = (input: SearchApiResponse): Search => ({
  eksternId: input.eksternId,
  navn: input.navn,
  searchText: input.searchText,
  kategoriIds: new Set(input.cpvKodeIds),
  kategoriCPV: new Set(input.cpvKodeCPV),
  stedIds: new Set(input.stedIds),
  mottakere: input.mottakere,
  erAktiv: input.erAktiv,
  visAktive: input.visAktive,
  noticeTypes: input.noticeTypes,
  includeParentCodes: input.includeParentCodes,
  filterNationalTenders: input.filterNationalTenders,
  filterRegionalTenders: input.filterRegionalTenders,
  textOnlyAlerts: input.textOnlyAlerts,
  valueMin: input.valueMin,
  valueMax: input.valueMax,
  isCanceled: input.isCanceled,
});

export const toSearchApiBody = (input: Search): SearchApiRequestBody => ({
  navn: input.navn,
  cpvKodeIds: Array.from(input.kategoriIds),
  cpvKodeCPV: Array.from(input.kategoriCPV),
  stedIds: Array.from(input.stedIds),
  searchText: input.searchText,
  mottakere: input.mottakere,
  erAktiv: input.erAktiv,
  noticeTypes: input.noticeTypes,
  includeParentCodes: input.includeParentCodes,
  filterNationalTenders: input.filterNationalTenders,
  filterRegionalTenders: input.filterRegionalTenders,
  textOnlyAlerts: input.textOnlyAlerts,
  valueMin: input.valueMin,
  valueMax: input.valueMax,
  isCanceled: input.isCanceled,
});

export const useSearchGetApi = () => {
  const { error, loading, response, get } = useApi(`/api/me/brukerdefinertsok`);
  return {
    error,
    loading,
    getSearch: (id: string): Promise<Search> =>
      get(id).then((result) => (response.ok ? toSearch(result) : initialSearch)),
  };
};

export const useSearchSaveApi = () => {
  const { error, loading, post, put } = useApi(`/api/me/brukerdefinertsok`);
  return {
    error,
    loading,
    saveSearch: async (search: Search): Promise<Search> => {
      const body = toSearchApiBody(search);
      const apiResponse = isNew(search) ? await post("", body) : await put(search.eksternId, body);
      return toSearch(apiResponse);
    },
  };
};
