import { CacheLocation } from "@auth0/auth0-react";
import { portalUrl } from "./app/paths";

const isProd =
  document.location.hostname.includes("finndoff.no") ||
  document.location.hostname.includes("finndoff-cdn-prod.azureedge.net");

const backendUrls: { [frontendUrl: string]: string } = {
  // When running the frontend locally, point to the API in test by default:
  "http://localhost:3000": "https://localhost:35002", //"https://localhost:59973", //"https://doffin-alerts.azurewebsites.net",
  // When the frontend runs in test, we point to the API in test:
  "https://doffin-alerts.azureedge.net": "https://doffin-alerts.azurewebsites.net",
  // When the frontend runs in production, we point to the API in production:
  "https://finndoff-cdn-prod.azureedge.net": "https://doffin-alerts-api-prod.azurewebsites.net",
  "https://www.finndoff.no": "https://doffin-alerts-api-prod.azurewebsites.net",
  "https://finndoff.no": "https://doffin-alerts-api-prod.azurewebsites.net",
};

const auth0Domain = isProd ? "login.finndoff.no" : "finndoff-test.eu.auth0.com";
const auth0ClientId = isProd ? "JUyVC6RVtRAO9UXQYo04ajlllEo2MEPb" : "UCfqpn8c36l6c1LtdADjucj08FhhfPTy";
const auth0Audience = isProd
  ? "https://doffin-alerts-api-prod.azurewebsites.net/"
  : "https://doffin-alerts.azurewebsites.net/";

const stripePrice299 = isProd ? "price_1LdJQnLnaQuCzuA32TeBvr1i" : "price_1LleVVLnaQuCzuA3e5wgqH0A";
const stripePrice499 = isProd ? "price_1LdJTkLnaQuCzuA32vYJnwVP" : "price_1LleWVLnaQuCzuA39BSuwNWR";

const gaId = isProd ? "G-FXE40LCHLP" : "G-27QF6KDRL2";
const gtmId = isProd ? "GTM-N2Q9Q6Z" : "GTM-NP3C572";

const isInvoicePaymentsActivated = false;

export const config = {
  auth0: {
    client: {
      domain: auth0Domain,
      clientId: auth0ClientId,
      cacheLocation: "localstorage" as CacheLocation,
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      authorizationParams: {
        redirect_uri: document.location.origin + portalUrl,
      },
    },
    token: {
      scope: "DoffinAlertsApiAccess",
      audience: auth0Audience,
    },
  },
  backendUrl: backendUrls[window.location.origin],
  gaId: gaId,
  gtmId: gtmId,
  stripePrice299: stripePrice299,
  stripePrice499: stripePrice499,
  isInvoicePaymentsActivated: isInvoicePaymentsActivated,
  isProd: isProd,
};
