import { Button } from "@mui/material";
import { FunctionComponent } from "react";

type Props = {
  link?: string;
  text: string;
  onClick?: () => void;
  dynamicSize?: string;
  disabled?: boolean;
};

export const SecondaryButton: FunctionComponent<Props> = ({ disabled, link, text, onClick, dynamicSize }) => {
  return (
    <>
      <Button
        disabled={disabled}
        size="large"
        href={link}
        onClick={onClick}
        sx={{
          fontSize: dynamicSize ? `${dynamicSize}vw` : "14px",
          width: dynamicSize && `${dynamicSize}0vw`,
          color: "black",
          backgroundColor: (theme) => theme.palette.secondary.main,
          "&:hover": {
            backgroundColor: (theme) => theme.palette.secondary.dark,
          },
        }}
      >
        {text}
      </Button>
    </>
  );
};
