import React, { FunctionComponent } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import BusinessIcon from '@mui/icons-material/Business';
import { useApi } from "../../../utils/hooks/useApi";
import { useSnackbar } from "../../../utils/hooks/useSnackbar";
import { FinndoffUser } from "../../../utils/contexts/UserContext";
import { CompanyFollower } from "../Search/datatypes"; 
import { paths } from "../../../app/paths";
import dayjs from "dayjs";
import { PlannedProcurement, ProcurementPlan } from "../ProcurementPlanner/datatypes";

interface FavoritePlannedProcurementCardProps {
  plannedProcurement: PlannedProcurement;
  procurementPlan: ProcurementPlan | null;
  onRemoveFavorite: (plannedProcurementId: number) => void;
}

export const FavoritePlannedProcurementCard: React.FC<FavoritePlannedProcurementCardProps> = ({
  plannedProcurement,
  procurementPlan,
  onRemoveFavorite,
}) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{plannedProcurement.title}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onRemoveFavorite(plannedProcurement.id)}
          >
            Fjern fra Min Oversikt
          </Button>
        </Box>
        <Typography variant="body2" color="textSecondary">
          {procurementPlan?.organizationName || 'Organisasjon ikke tilgjengelig'}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {procurementPlan?.reportYear
            ? `Rapportår: ${dayjs(procurementPlan.reportYear).format('YYYY')}`
            : 'Rapportår: Ikke oppgitt'}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {plannedProcurement.finishingYear
            ? `Fullførelsesår: ${dayjs(plannedProcurement.finishingYear).format('YYYY')}`
            : 'Fullførelsesår: Ikke oppgitt'}
        </Typography>
        <Typography variant="body1" mt={2}>
          {plannedProcurement.description}
        </Typography>
      </CardContent>
    </Card>
  );
};