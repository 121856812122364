import { Box, Grid, Link, Typography } from "@mui/material";
import { FunctionComponent, useRef } from "react";
import finndofflogo from "../../images/Finndoff_logo_color_white_icon.png";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface SignupProps {
  referralCode: any;
}

export const SignupSideContent: FunctionComponent<SignupProps> = ({ referralCode }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const scrollTargetRef = useRef(null);

  const setMemberTitle = () => {
    switch (referralCode) {
      case "mefmember":
        return "VELKOMSTTILBUD PÅ ANBUDSVARSLING!";
      case "clickedtitle":
        return "PRØV NORGES BESTE ANBUDSVARSLING!";
      case "anbud365":
        return "PRØV NORGES BESTE ANBUDSVARSLING!";
      case "arkitektbedriftene":
        return "MEDLEMSTILBUD - ANBUDSVARSLING OFFENTLIGE ANBUD";
      case "byggeborsenfauske2024":
        return "DAGSTILBUD - ANBUDSVARSLING OFFENTLIGE ANBUD";
      case "byggmesterforbundet":
        return "MEDLEMSTILBUD - ANBUDSVARSLING OFFENTLIGE ANBUD";
      case "neso":
        return "MEDLEMSTILBUD - ANBUDSVARSLING OFFENTLIGE ANBUD";
      default:
        return "PRØV NORGES BESTE ANBUDSVARSLING!";
    }
  };
  const setMemberInfo = () => {
    switch (referralCode) {
      case "mefmember":
        return "Som medlem av MEF gir vi deg en ekstra god deal på spesialtilpasset anbudsvarsling for bygg- og anleggsbransjen i Norge!";
      case "mefsorost":
        return "Som medlem av MEF Sørøst gir vi deg en ekstra god deal på spesialtilpasset anbudsvarsling for bygg- og anleggsbransjen i Norge!";
      case "mefvest":
        return "Som medlem av MEF Vest gir vi deg en ekstra god deal på spesialtilpasset anbudsvarsling for bygg- og anleggsbransjen i Norge!";
      case "mefmidt":
        return "Som medlem av MEF Region Midt gir vi deg en ekstra god deal på spesialtilpasset anbudsvarsling for bygg- og anleggsbransjen i Norge!";
      case "mefsorvest":
        return "Som medlem av MEF Sørvest gir vi deg en ekstra god deal på spesialtilpasset anbudsvarsling for bygg- og anleggsbransjen i Norge!";
      case "mefost":
        return "Som medlem av MEF Øst gir vi deg en ekstra god deal på spesialtilpasset anbudsvarsling for bygg- og anleggsbransjen i Norge!";
      case "clickedtitle":
        return "Bruk to minutter til å registrere selskapet ditt og få full tilgang til tusenvis av doffinkunngjøringer.";
      case "anbud365":
        return "Bruk to minutter til å registrere selskapet ditt og få full tilgang til tusenvis av doffinkunngjøringer, skreddersydde søk og varslingsprofil!";
      case "arkitektbedriftene":
        return "Få fordeler som medlem av Arkitektbedriftene. Prøv GRATIS i 3 uker nå, med kun 2 minutters registrering.";
      case "elektroinst":
        return "Bruk to minutter til å registrere selskapet ditt og få full tilgang til tusenvis av doffinkunngjøringer, skreddersydde søk og varslingsprofil.";
      case "rorlegger":
        return "Bruk to minutter til å registrere selskapet ditt og få full tilgang til tusenvis av doffinkunngjøringer, skreddersydde søk og varslingsprofil.";
      case "byggeborsenfauske2024":
        return "Bruk to minutter til å registrere selskapet ditt og få full tilgang til tusenvis av doffinkunngjøringer, skreddersydde søk og varslingsprofil!";
      case "byggmesterforbundet":
          return "Som medlem av Byggmesterforbundet gir vi deg en ekstra god deal på spesialtilpasset anbudsvarsling for byggebransjen i Norge!";
      case "neso":
        return "Som medlem av NESO gir vi deg en ekstra god deal på spesialtilpasset anbudsvarsling for byggebransjen i Norge!";
      case "entreprenor":
        return "Bruk to minutter til å registrere selskapet ditt og få full tilgang til tusenvis av doffinkunngjøringer, skreddersydde søk og varslingsprofil.";
      default:
        return "Bruk to minutter til å registrere selskapet ditt og få full tilgang til tusenvis av doffinkunngjøringer, skreddersydde søk og varslingsprofil.";
    }
  };
  const setMemberOfferInfo = () => {
    switch (referralCode) {
      case "mefmember":
        return "Gjør som mange andre MEF-medlemmer og registrer et PLUSS-abonnement i dag med MEF-rabatt!";
      case "mefsorost":
        return "Gjør som mange andre MEF-medlemmer og registrer et PLUSS-abonnement i dag med MEF-rabatt!";
      case "mefvest":
        return "Gjør som mange andre MEF-medlemmer og registrer et PLUSS-abonnement i dag med MEF-rabatt!";
      case "mefmidt":
        return "Gjør som mange andre MEF-medlemmer og registrer et PLUSS-abonnement i dag med MEF-rabatt!";
      case "mefsorvest":
        return "Gjør som mange andre MEF-medlemmer og registrer et PLUSS-abonnement i dag med MEF-rabatt!";
      case "mefost":
        return "Gjør som mange andre MEF-medlemmer og registrer et PLUSS-abonnement i dag med MEF-rabatt!";
      case "clickedtitle":
        return "Prøv løsningen vår GRATIS I 10 DAGER før du bestemmer deg! Ingen binding. Ingen forpliktelser."; //trial date
      case "arkitektbedriftene":
        return "Få tilpasset anbudsvarsling for arkitektbransjen i Norge, satt opp av våre eksperter. Geografisk område kan tilpasses etter ønske. Velg et abonnement hos Finndoff for gode medlemsrabatter på våre priser.";
      case "elektroinst":
        return "Prøv løsningen vår GRATIS I 10 DAGER før du bestemmer deg! Ingen binding. Ingen forpliktelser."; //trial date
      case "rorlegger":
        return "Prøv løsningen vår GRATIS I 10 DAGER før du bestemmer deg! Ingen binding. Ingen forpliktelser."; //trial date
      case "byggmesterforbundet":
        return "Gjør som mange andre medlemmer av Byggmesterforbundet og registrer et gratis, uforpliktende prøveabonnement med PLUSS i dag og få gode medlemsrabatter!";
      case "neso":
        return "Gjør som mange andre medlemmer av NESO og registrer et gratis, uforpliktende prøveabonnement med PLUSS i dag og få gode medlemsrabatter!";
      case "entreprenor":
        return "Prøv løsningen vår GRATIS I 10 DAGER før du bestemmer deg! Ingen binding. Ingen forpliktelser."; //trial date
      default:
        return "Prøv løsningen vår GRATIS I 10 DAGER før du bestemmer deg! Ingen binding. Ingen forpliktelser."; //trial date
    }
  };

  const handleScroll = () => {
    // Calculate the scroll position to be the height of the viewport
    const scrollHeight = window.innerHeight;

    // Scroll to the calculated position
    scrollTargetRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };
  
  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(3),
        backgroundColor: (theme) => theme.palette.primary.main,
        height: "100%",
        "& h4": {
          marginBottom: "1em",
        },
        "& h5": {
          marginBottom: "1em",
        },
        borderTopLeftRadius: "4px",
        borderBottomLeftRadius: "4px",
      }}
    >
      <Box textAlign="center" marginBottom="5%">
        <Box marginBottom="16px">
          <img src={finndofflogo} alt=""></img>
        </Box>
        <Box marginBottom="16px">
          <Typography variant="h4" color="common.white" textAlign="center">
            {setMemberTitle()}
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
        <Typography variant="h5" marginBottom="16px" color="common.white" textAlign="center">
          <Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block"  } }}>{setMemberInfo()} </Box>
        </Typography>

        <Typography variant="body1" marginBottom="8px" color="common.white" textAlign="center">
          <Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" }, marginLeft:"4%" }}>{setMemberInfo()}</Box>
        </Typography>

        <Typography variant="h5" marginBottom="16px" color="common.white" textAlign="center">
          <Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block"  } }}>{setMemberOfferInfo()} </Box>
        </Typography>

        <Typography variant="body1" marginBottom="8px" color="common.white" textAlign="center">
          <Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" }, marginLeft:"4%" }}>{setMemberOfferInfo()}</Box>
        </Typography>

        <Link
          href="https://share-eu1.hsforms.com/183V6W1IGRcikwgsn9G8Nngfai8j"
          target="_blank"
          rel="noreferrer"
          sx={{
            fontSize: "1.2rem",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: 500,
            lineHeight: "1.6",
            letterSpacing: "0.0075em",
            position: "relative",
            color: "white",
            marginBottom: "24px",
            marginTop: "24px",
            textDecoration: "underline",
          }}
        >
          Trenger du hjelp til registrering?
        </Link>


      </Grid>
      {isSmallScreen && (
        <Box textAlign="center" onClick={handleScroll} style={{ cursor: "pointer" }}>
          <div style={{ color: "white" }}>
            <ArrowDownwardIcon />
          </div>
        </Box>
      )}
      <div ref={scrollTargetRef} />
    </Box>
  );
};
