import React, { createContext, useContext } from "react";
import { Converter, createTree, emptyTree, Tree } from "../tree";
import { useApi } from "../hooks/useApi";
import { Errors } from "../../pages/Portal/Search/Errors";

export const ReferenceDataContext = createContext<{
  steder: Tree;
  kategorier: Tree;
  isLoading: boolean;
}>({
  steder: emptyTree,
  kategorier: emptyTree,
  isLoading: true,
});

export const useReferenceDataContext = () => {
  return useContext(ReferenceDataContext);
};

interface ReferenceDataProviderProps {
  children: React.ReactNode;
}

const useLoadData = () => {
  const { error: error1, loading: loading1, data: stedTreeRaw = [] } = useApi("/api/kodeliste/steder", {}, []);
  const { error: error2, loading: loading2, data: kategoriTreeRaw = [] } = useApi("/api/kodeliste/cpvkoder", {}, []);

  interface StedTree {
    stedId: number;
    navn: string;
    children: StedTree[];
    aliasIds: number[];
  }

  interface KategoriTree {
    cpvKodeId: number;
    kode: string;
    beskrivelse: string;
    children: KategoriTree[];
  }

  const steder = createTree<StedTree>(stedTreeRaw, (n) => ({ id: n.stedId, label: n.navn, aliasIds: n.aliasIds }));

  const kategoriConverter: Converter<KategoriTree> = (n: KategoriTree) => ({
    id: n.cpvKodeId,
    label: n.kode + " - " + n.beskrivelse,
  });
  const kategorier = createTree<KategoriTree>(kategoriTreeRaw, kategoriConverter);

  return {
    errors: [error1, error2],
    isLoading: loading1 || loading2,
    steder,
    kategorier,
  };
};

export const ReferenceDataProvider = (props: ReferenceDataProviderProps): JSX.Element => {
  const { errors, isLoading, steder, kategorier } = useLoadData();

  if (isLoading) {
    return <></>;
  }
  if (errors.some((error) => !!error)) {
    return <Errors errors={errors} />;
  }

  return (
    <ReferenceDataContext.Provider
      value={{
        steder,
        kategorier,
        isLoading,
      }}
    >
      {props.children}
    </ReferenceDataContext.Provider>
  );
};
