import _useFetch from "use-http";
import { CachePolicies } from "use-http/dist/cjs";
import { useAuth0 } from "@auth0/auth0-react";
import { config } from "../../config";
import { useState, useEffect } from "react";

export const createBackendUrl = (path: string) => {
  if (path[0] !== "/") {
    throw new Error("createBackendUrl: path should start with '/'");
  }
  if (config.backendUrl === undefined) {
    throw new Error("createBackendUrl: Found config.backendUrl to be undefined. Cannot proceed.");
  }
  return config.backendUrl + path;
};

interface Headers {
  Authorization?: string;
}

interface Options {
  headers?: Headers;
  timeout?: number;
  mode?: string;
  cachePolicy?: CachePolicies | undefined;
  interceptors: any;
}

export function useApi(path: string, options?: { [key: string]: any }, deps?: any[]) {
  const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const url = createBackendUrl(path);

  // Internal state to track reloads
  const [reloadFlag, setReloadFlag] = useState(0);
  
  // Custom reload function to trigger re-fetching
  const reload = () => setReloadFlag((prev) => prev + 1);

  const defaultOptions: Options = {
    mode: "cors",
    cachePolicy: CachePolicies.NO_CACHE,
    interceptors: {},
    headers: {},
  };
  if (isAuthenticated) {
    defaultOptions.interceptors.request = async ({ options }: { options: Options }) => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: config.auth0.token.scope,
            audience: config.auth0.token.audience,
          },
        });
        if (!options.headers) {
          options.headers = {};
        }
        options.headers.Authorization = `Bearer ${accessToken}`;
        return options;
      } catch (error) {
        const errorObj = error as { error?: string };
        if (errorObj.error === "missing_refresh_token" || errorObj.error === "invalid_grant") {
          console.log("Missing refresh token - using fallback loginWithRedirect");
          loginWithRedirect();
        }
      }
    };
  }

  const fetchOptions: { [key: string]: any } = { ...defaultOptions, ...(options || {}) };
  if (deps !== undefined) {
    return _useFetch(url, fetchOptions, [reloadFlag, ...(deps || [])]);
  }

  return _useFetch(url, fetchOptions);
}
