import React, { useState, useEffect } from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { AnbudSummary, PagedList } from "./datatypes";
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator";
import { SearchResult } from "./SearchResult";
import { useApi } from "../../../utils/hooks/useApi";

interface Anbud {
  doffinAnbudId: string;
  // Include other properties if needed
}

interface Bruker {
  // Define properties if needed
}

interface AnbudBrukerFavoriteDto {
  anbud: Anbud;
  bruker: Bruker;
  // Include other properties if needed
}

interface SearchResultsProps {
  isLoading: boolean;
  maybeSearchResponse: PagedList<AnbudSummary> | undefined;
  checkedStedIds: Set<number>;
  checkedKategoriIds: Set<number>;
  checkedKategoriCPV: Set<number>;
  setCheckedKategori: (id: number, checked: boolean) => void;
  setCheckedSted: (id: number, checked: boolean) => void;
  showActiveTendersOnly: boolean;
}

const isActiveTender = (anbud: AnbudSummary): boolean => {
  const contractDate = new Date(anbud.limitDate);

  if (anbud.limitDate === null || anbud.noticeType === "Award") return true;

  return contractDate.getTime() - Date.now() >= 0;
};

export const SearchResults: React.FC<SearchResultsProps> = ({
  isLoading,
  maybeSearchResponse,
  checkedStedIds,
  checkedKategoriIds,
  checkedKategoriCPV,
  setCheckedKategori,
  setCheckedSted,
  showActiveTendersOnly,
}) => {
  // Fetch favorites using useApi hook
  const {
    data: favorites,
    error: favoritesError,
    loading: favoritesLoading,
    get: getFavorites,
  } = useApi("/api/me/bruker/favorites");

  // API methods for adding and removing favorites
  const { post: postFavorite } = useApi("/api/me/bruker/favorites");
  const { del: deleteFavorite } = useApi("/api/me/bruker/favorites");

  // State to manage favorite IDs
  const [favoriteIds, setFavoriteIds] = useState<Set<string>>(new Set());

  // Fetch favorites on component mount
  useEffect(() => {
    getFavorites();
  }, []);

  // Update favorite IDs when favorites data changes
  useEffect(() => {
    if (favorites) {
      const newFavoriteIds = new Set<string>(
        favorites.map((fav: { anbud: { doffinAnbudId: any; }; }) => fav.anbud.doffinAnbudId)
      );
      setFavoriteIds(newFavoriteIds);
      console.log("Favorites fetched:", favorites);
    }
  }, [favorites]);

  // Handle adding/removing favorites
  const handleFavoriteToggle = async (doffinAnbudId: string, shouldFavorite: boolean) => {
    try {
      if (shouldFavorite) {
        // Add to favorites
        await postFavorite(`?doffinAnbudId=${doffinAnbudId}`);
        setFavoriteIds((prev) => new Set(prev).add(doffinAnbudId));
      } else {
        // Remove from favorites
        await deleteFavorite(`?doffinAnbudId=${doffinAnbudId}`);
        setFavoriteIds((prev) => {
          const newSet = new Set(prev);
          newSet.delete(doffinAnbudId);
          return newSet;
        });
      }
    } catch (error) {
      console.error("Error toggling favorite:", error);
    }
  };

  if (isLoading || favoritesLoading) {
    return <LoadingIndicator />;
  }
  if (!maybeSearchResponse) {
    return null;
  }
  const searchResponse = maybeSearchResponse as PagedList<AnbudSummary>;

  function getKunngjøringerTekst() {
    if (searchResponse.totalCount === 0) {
      return "Ingen kunngjøringer matcher søket ditt";
    } else if (searchResponse.totalCount === 1) {
      return "1 kunngjøring matcher søket ditt";
    } else {
      return `${searchResponse.totalCount} kunngjøringer matcher søket ditt`;
    }
  }

  return (
    <Paper style={{ padding: "16px" }} elevation={0}>
      {showActiveTendersOnly ? (
        <div>
          <Typography variant="h5" gutterBottom color="primary" align="center">
            Vi fant disse aktive kunngjøringene som matcher søket ditt
          </Typography>
        </div>
      ) : (
        <div>
          <Typography variant="h5" gutterBottom color="primary" align="center">
            {getKunngjøringerTekst()}
          </Typography>
        </div>
      )}

      {searchResponse.items.map((res, i) => (
        <div key={i}>
          {isActiveTender(res) && (
            <Box
              sx={{
                padding: 1,
                backgroundColor: "white",
              }}
            >
              <Divider />
              <SearchResult
                summary={res}
                checkedStedIds={checkedStedIds}
                checkedKategoriIds={checkedKategoriIds}
                checkedKategoriCPV={checkedKategoriCPV}
                setCheckedKategori={setCheckedKategori}
                setCheckedSted={setCheckedSted}
                isFavorited={favoriteIds.has(res.doffinAnbudId)}
                onFavoriteToggle={handleFavoriteToggle}
              />
            </Box>
          )}
          {!isActiveTender(res) && !showActiveTendersOnly && (
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.grey[200],
                opacity: "75%",
                paddingLeft: 1,
                paddingBottom: 1,
                paddingRight: 1,
                borderRadius: "4px",
                marginTop: 1,
              }}
            >
              <Typography variant="h6" gutterBottom align="left">
                Utløpt
              </Typography>
              <SearchResult
                summary={res}
                checkedStedIds={checkedStedIds}
                checkedKategoriIds={checkedKategoriIds}
                checkedKategoriCPV={checkedKategoriCPV}
                setCheckedKategori={setCheckedKategori}
                setCheckedSted={setCheckedSted}
                isFavorited={favoriteIds.has(res.doffinAnbudId)}
                onFavoriteToggle={handleFavoriteToggle}
              />
            </Box>
          )}
        </div>
      ))}
    </Paper>
  );
};
