export const cloneSet = <T>(set: Set<T>): Set<T> => new Set<T>(Array.from(set));

export const immutablyAdd = <T>(set: Set<T>, el: T): Set<T> => {
  set = cloneSet(set);
  set.add(el);
  return set;
};
export const immutablyDelete = <T>(set: Set<T>, el: T): Set<T> => {
  set = cloneSet(set);
  set.delete(el);
  return set;
};
