import { immutablySetNodeById, Tree } from "../utils/tree";

export type Search = {
  eksternId: string; //guid
  navn: string;
  searchText: string;
  kategoriIds: Set<number>;
  kategoriCPV: Set<number>;
  stedIds: Set<number>;
  mottakere: string[];
  erAktiv: boolean;
  visAktive: boolean;
  filterNationalTenders: boolean;
  filterRegionalTenders: boolean;
  noticeTypes: string[];
  includeParentCodes: boolean;
  valueMin: number;
  valueMax: number;
  textOnlyAlerts: boolean;
  isCanceled: boolean;
};

export const newId = "<new>";

export const isNew = (search: Search) => search.eksternId === newId;

export const initialSearch: Search = {
  eksternId: newId,
  navn: "",
  searchText: "",
  kategoriIds: new Set<number>(),
  kategoriCPV: new Set<number>(),
  stedIds: new Set<number>(),
  mottakere: [],
  visAktive: false,
  filterNationalTenders: false,
  filterRegionalTenders: false,
  erAktiv: true,
  noticeTypes: ["All"],
  includeParentCodes: false,
  valueMin: null,
  valueMax: null,
  textOnlyAlerts:false,
  isCanceled:false
};

export const createSearch = (data?: Search): Search => ({
  ...initialSearch,
  ...data,
  eksternId: newId,
  erAktiv: true,
  visAktive: false,
  filterNationalTenders: false,
});

export const setSearchFreeText = (
  currentSearch: Search,
  text: string,
  kategorier: Set<number>,
  steder: Set<number>,
  types: string[],
  filterNationalTenders:boolean,
  filterRegionalTenders:boolean,
  isCanceled:boolean
): Search => ({
  ...currentSearch,
  searchText: text, // update the freetext value for current search
  kategoriIds: kategorier,
  stedIds: steder,
  noticeTypes: types,
  filterNationalTenders: filterNationalTenders,
  filterRegionalTenders: filterRegionalTenders,
  isCanceled: isCanceled
});

export const setActiveTender = (currentSearch: Search, checked: boolean): Search => ({
  ...currentSearch,
  visAktive: checked, // update the results based on active or historic tenders
});

export const setSearchCheckedSted = (currentSearch: Search, steder: Tree, id: number, checked: boolean): Search => ({
  ...currentSearch,
  stedIds: immutablySetNodeById(steder, currentSearch.stedIds, id, checked),
});

export const setSearchNoticeType = (currentSearch: Search, types: string[]): Search => ({
  ...currentSearch,
  noticeTypes: types,
});

export const setSearchCheckedKategori = (
  currentSearch: Search,
  kategorier: Tree,
  id: number,
  checked: boolean
): Search => ({
  ...currentSearch,
  kategoriIds: immutablySetNodeById(kategorier, currentSearch.kategoriIds, id, checked),
});

export type SearchParameters = {
  freeText: string;
  steder: Set<number> | undefined;
  kategorier: Set<number> | undefined;
  noticeTypes: string[];
  filterNationalTenders: boolean;
  filterRegionalTenders: boolean;
  isCanceled: boolean;
};
