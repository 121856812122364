import { FunctionComponent, useState } from "react";
import { Box } from "@mui/material";
import { SearchComponent } from "./SearchComponent";
import { IFrame } from "../IFrame/IFrame";
import { wixPaths } from "../routes";

export const StartPagePure: FunctionComponent = () => {
  const [showSearchComponent, setShowSearchComponent] = useState(false);

  const urlChanged = (pathname: string) => {
    if (pathname.includes("nyheter") || pathname.includes("priser")) {
      setShowSearchComponent(false);
    } else {
      setShowSearchComponent(true);
    }
  };

  return (
    <div>
      {showSearchComponent && (
        <Box marginBottom="2rem">
          <SearchComponent />
        </Box>
      )}
      <IFrame
        src={wixPaths.start}
        id={"startpage_iframe"}
        urlChangedFunction={(pathname: string) => urlChanged(pathname)}
      />
    </div>
  );
};

export const StartPage = StartPagePure;
