import { Button } from "@mui/material";
import { FunctionComponent } from "react";

type Props = {
  link?: string;
  text: string;
  onClick?: () => void;
  dynamicSize?: string;
  variant?: string;
};

export const LightButton: FunctionComponent<Props> = ({ variant, link, text, onClick, dynamicSize }) => {
  return (
    <>
      <Button
        size="large"
        href={link}
        onClick={onClick}
        sx={{
          fontSize: dynamicSize ? `${dynamicSize}vw` : "14px",
          width: dynamicSize && `${dynamicSize}0vw`,
          color: "black",
          backgroundColor: (theme) => (variant === "text" ? "white" : theme.palette.info.light),
          "&:hover": {
            backgroundColor: (theme) => (variant === "text" ? theme.palette.info.light : theme.palette.info.main),
          },
        }}
      >
        {text}
      </Button>
    </>
  );
};
