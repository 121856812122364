import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles/createPalette" {
  interface PaletteOptions {
    turquoise?: PaletteColorOptions;
  }

  interface Palette {
    turquoise: PaletteColor;
  }
}
const themePure = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    turquoise: {
      main: "#008489",
      light: "#99ced0",
    },
    background: {
      default: "#ffffff",
    },
    mode: "light",
    primary: {
      main: "#0B2333",
      light: "#3c4f5c",
    },
    secondary: {
      main: "#1DE08E",
      light: "#D2F9E8",
      dark: "#3cb985",
    },
    info: {
      main: "#ced3d6",
      light: "#f1f1f1",
    },
    warning: {
      main: "#EC5B5B",
    },
    // success: {},
    // error: {},
    // warning: {},
    // info: {},
  },
  // typography: {
  //   h5: {
  //     color: "red",
  //     [createBreakpoints]
  //     "@media (min-width:600px)": {
  //       fontSize: "5rem",
  //       color: "blue",
  //     },
  //   },
  // },
  typography: {
    button: {
      textTransform: "none",
      fontSize: "1rem",
      letterSpacing: "0rem",
      fontWeight: 400,
    },
    h2: {
      fontFamily: "Work Sans",
      fontWeight: 600,
      color: "#0B2333",
    },
    h3: {
      fontFamily: "Work Sans",
      fontWeight: 600,
      color: "#0B2333",
    },
    h4: {
      fontFamily: "Work Sans",
      fontWeight: 600,
      color: "#0B2333",
    },
    h5: {
      fontFamily: "Roboto",
      fontWeight: 400,
      color: "#0B2333",
      fontSize: "1.2rem",
    },
    h6: {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "1.5rem",
      color: "#0B2333",
    },
  },
});

export const theme = responsiveFontSizes(themePure);
