import { OptionsObject, useSnackbar as _useSnackbar } from "notistack";

export function useSnackbar(): {
  enqueueSuccessMsg: (msg: string, options?: OptionsObject) => void;
  enqueueErrorMsg: (msg: string) => void;
} {
  const { enqueueSnackbar } = _useSnackbar();

  const enqueueSuccessMsg = (msg: string, options: OptionsObject = {}) => {
    enqueueSnackbar(msg, { variant: "success" });
  };

  const enqueueErrorMsg = (msg: string) => {
    enqueueSnackbar(msg, { variant: "error" });
  };

  return { enqueueSuccessMsg, enqueueErrorMsg };
}
