import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  SelectChangeEvent,
  FormLabel,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { SaveButtonAndModal } from "./SaveButtonAndModal";
import { canSaveSearch, useUserContext } from "../../../../utils/contexts/UserContext";
import { filterNodes, immutablySet  } from "../../../../utils/tree";

import { Hierarchy } from "./Hierarchy";
import {
  isNew,
  Search,
  setSearchCheckedKategori,
  setSearchCheckedSted,
  setSearchFreeText,
  setSearchNoticeType,
} from "../../../../Types/Search";
import { useReferenceDataContext } from "../../../../utils/contexts/ReferenceDataContext";
import { useSnackbar } from "../../../../utils/hooks/useSnackbar";
import InfoIcon from '@mui/icons-material/Info';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getNodeById } from "../../../../utils/tree";
import { StyledChip } from "../../../../components/StyledChip";

interface SearchFormProps {
  currentSearch: Search;
  setCurrentSearch: (search: Search) => void;
  setActiveTendersOnly: (checked: boolean) => void;
  setFilterNationalTenders: (checked: boolean) => void;
  setFilterRegionalTenders: (checked: boolean) => void;
  setFilterIsCenceledTenders: (checked: boolean) => void;
  saveSearch: (search: Search) => Promise<Search>;
  setInitCurrentPage: (id: number) => void;
  sortField: string;
  sortOrder: "asc" | "desc";
}

export const SearchForm: React.FC<SearchFormProps> = ({
  currentSearch,
  setCurrentSearch,
  saveSearch,
  setActiveTendersOnly,
  setFilterNationalTenders,
  setFilterRegionalTenders,
  setFilterIsCenceledTenders,
  setInitCurrentPage,
  sortField,
  sortOrder,
}) => {
  const { enqueueSuccessMsg } = useSnackbar();
  const { finndoffUser } = useUserContext();

  const {steder, kategorier } = useReferenceDataContext();
  const [openedKategorier, setOpenedKategorier] = useState<Set<number>>(new Set());
  const [openedSteder, setOpenedSteder] = useState<Set<number>>(new Set());
  const [searchText, setSearchText] = useState<string>(currentSearch.searchText || "");
  const [checkedActive, setCheckedActive] = useState(currentSearch.visAktive || false);
  const [checkedNational, setCheckedNational] = useState(currentSearch.filterNationalTenders || false);
  const [checkedRegional, setCheckedRegional] = useState(currentSearch.filterRegionalTenders || false);
  const [checkedIsCanceled, setCheckedIsCanceled] = useState(currentSearch.isCanceled || false);

  // State variables for valueMin and valueMax
  const [valueMin, setValueMin] = useState<string | null>(currentSearch.valueMin?.toString() || "");
  const [valueMax, setValueMax] = useState<string | null>(currentSearch.valueMax?.toString() || "");

  const isDefaultSort = sortField === "FileDate" && sortOrder === "desc";
  const isCheckedAndDisabled = !isDefaultSort;
  const [checkedWithParent, setCheckedWithParent] = useState(currentSearch.includeParentCodes || false);
  const setFilterOutRegionalTenders = (checked: boolean) => setFilterRegionalTenders(checked);
  const [filterString, setFilterString] = useState<string>("");
  const [isKategoriExpanded, setKategoriExpanded] = useState(false);

  const noticeTypeMapping: { [key: string]: string } = {
    All: "Alle",
    Pin: "Veiledende",
    Notice: "Kunngjøring av konkurranse",
    Award: "Tildeling",
    Intention: "Intensjon",
  };

  useEffect(() => {
    if (filterString.trim()) {
      setKategoriExpanded(true); // Expand when filter is active
    } else {
      setKategoriExpanded(false); // Collapse when filter is cleared
    }
  }, [filterString]);

  const setInputSearchText = (searchText: string) =>
    setCurrentSearch(
      setSearchFreeText(
        currentSearch,
        searchText,
        currentSearch.kategoriIds,
        currentSearch.stedIds,
        currentSearch.noticeTypes,
        currentSearch.filterNationalTenders,
        currentSearch.filterRegionalTenders,
        currentSearch.isCanceled
      )
    );

  const setInputNoticeType = (types: string[]) => setCurrentSearch(setSearchNoticeType(currentSearch, types));

  const setInputActiveTendersOnly = (checked: boolean) => setActiveTendersOnly(checked);
  const setFilterOutNationalTenders = (checked: boolean) => setFilterNationalTenders(checked);
  const setFilterIsCanceled = (checked: boolean) => setFilterIsCenceledTenders(checked);

  const setCheckedKategori = (id: number, checked: boolean) =>
    setCurrentSearch(setSearchCheckedKategori(currentSearch, kategorier, id, checked));

  const setCheckedSted = (id: number, checked: boolean) =>
    setCurrentSearch(setSearchCheckedSted(currentSearch, steder, id, checked));

  const setOpenedKategori = (id: number, opened: boolean) =>
    setOpenedKategorier(immutablySet(openedKategorier, id, opened));

  const setOpenedSted = (id: number, opened: boolean) => setOpenedSteder(immutablySet(openedSteder, id, opened));

  // Handle changes in the free text field
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setInitCurrentPage(0);
  };

  // Handle changes in the valueMin and valueMax fields
  const handleValueMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueMin(event.target.value);
    setInitCurrentPage(0);
  };

  const handleValueMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueMax(event.target.value);
    setInitCurrentPage(0);
  };

  // Unified search function
  const doSearch = (triggeredByUserAction = false) => {
    const searchQuery = searchText.length > 3 ? searchText : "";
    const newSearch = {
      ...currentSearch,
      searchText: searchQuery,
      valueMin: valueMin ? parseInt(valueMin) : null,
      valueMax: valueMax ? parseInt(valueMax) : null,
      visAktive: checkedActive,
      filterNationalTenders: checkedNational,
      isCanceled: checkedIsCanceled,
      includeParentCodes: checkedWithParent, // Include the latest value
    };
    setCurrentSearch(newSearch);
    setInitCurrentPage(0);

    if (triggeredByUserAction && searchText.length > 3) {
      enqueueSuccessMsg("Søker etter..." + searchText);
    }
  };

  // Debounce the search for text inputs
  useEffect(() => {
    const handler = setTimeout(() => {
      doSearch();
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText, valueMin, valueMax]);

  // Immediate search for checkbox changes
  useEffect(() => {
    doSearch();
  }, [checkedActive, checkedNational, checkedIsCanceled, checkedWithParent]);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent line break when Enter is pressed
      doSearch(true); // Indicate user action
    }
  };

  // Handle search button click
  const handleSearchButtonClick = () => {
    doSearch(true); // Indicate user action
  };

  // Handle checkbox changes
  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedActive(event.target.checked);
    setInputActiveTendersOnly(event.target.checked);
    setInitCurrentPage(0);
  };

  const handleFilterNationalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedNational(event.target.checked);
    setFilterOutNationalTenders(event.target.checked);
    setInitCurrentPage(0);
  };

  const handleFilterRegionalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedRegional(event.target.checked);
    setFilterOutRegionalTenders(event.target.checked);
    setCurrentSearch({ ...currentSearch, filterRegionalTenders: event.target.checked });
    setInitCurrentPage(0);
  };

  const handleFilterIsCanceled = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedIsCanceled(event.target.checked);
    setFilterIsCanceled(event.target.checked);
    setInitCurrentPage(0);
  };

  const doNoticeTypeSearch = (event: SelectChangeEvent<string[]>) => {
    let selectedValues = event.target.value as string[]; // Cast to string[] explicitly
    
    const clickedValue = selectedValues[selectedValues.length - 1]; // Get the last clicked value

    if (clickedValue === "All") {
      // If "Alle" is clicked, deselect all other options and keep only "Alle"
      setInputNoticeType(["All"]);
    } else {
      // Remove "Alle" if it's selected and any other option is clicked
      selectedValues = selectedValues.filter((value) => value !== "All");
  
      // Update with new selected options
      setInputNoticeType(selectedValues);
    }
  
    // Reset page after selection change
    setInitCurrentPage(0);
  };

  const tooltip = "Søket støtter også operatorene: * (wildcard)  + (OG) | (ELLER)";

  function stedText() {
    if (currentSearch.stedIds.size < 1) return "Hele Norge";
    else if (currentSearch.stedIds.size === 1)
      return Array.from(currentSearch.stedIds).map((stedId) => getNodeById(steder, stedId)?.label);
    else return "flere steder";
  }

  function kategoriShort() {
    if (currentSearch.kategoriIds.size < 1) return " alle CPV-nummer ";
    else if (currentSearch.kategoriIds.size === 1) return " ett CPV-nummer ";
    else return " flere CPV-nummer ";
  }

  function ProcessExistingSearchName(currentSearchText: string, newSearchName: string) {
    if (
      currentSearchText.includes("Alle anbud") ||
      currentSearchText.includes("CPV-nummer") ||
      currentSearchText.includes("flere steder") ||
      currentSearchText.includes("Hele Norge")
    )
      return newSearchName;
    else return currentSearchText;
  }

  function setValue() {
    var fritekstName = currentSearch.searchText ? currentSearch.searchText : "Alle anbud";
    fritekstName = fritekstName.length > 100 ? "Alle anbud" : fritekstName; // to limit the autogenerated search names

    var searchNameCategory = kategoriShort();
    var searchNameLocation = stedText();

    fritekstName = fritekstName + searchNameCategory + searchNameLocation;

    currentSearch.navn = isNew(currentSearch)
      ? fritekstName
      : ProcessExistingSearchName(currentSearch.navn, fritekstName);

    return currentSearch.navn;
  }

  const handleWithParentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedWithParent(event.target.checked);
    setInitCurrentPage(0);
    // The search will be triggered by the useEffect
  };

  const tooltipContent = (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="subtitle1" gutterBottom>
        Søkefunksjoner
      </Typography>
      <Divider sx={{ marginBottom: 1 }} />
      <List dense disablePadding>
        <ListItem>
          <ListItemText
            primary={<strong>"Frase"</strong>}
            secondary='Eksakt match. Bruk anførselstegn for eksakte fraser, f.eks. "kunstig intelligens".'
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={<strong>+</strong>}
            secondary='Obligatorisk ord. Legg til + foran ord som må være med i resultatene, f.eks. "+fornybar".'
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={<strong>-</strong>}
            secondary='Ekskluder ord. Legg til - foran ord for å utelate dem, f.eks. "sky -AWS".'
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={<strong>*</strong>}
            secondary='Jokertegn for flere tegn. F.eks. "bio*" finner "biologi", "biokjemi" osv.'
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={<strong>?</strong>}
            secondary='Jokertegn for ett tegn. F.eks. "tekn?" matcher "tekn" eller "tekns".'
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={<strong>OR</strong>}
            secondary='Enten/eller søk. Bruk OR for å finne enten ett ord eller et annet, f.eks. "sol OR vind".'
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={<strong>(...)</strong>}
            secondary='Gruppering. Bruk parenteser for å gruppere termer, f.eks. "(sol OR vind) energi".'
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={<strong>~</strong>}
            secondary='Nærhetssøk. Bruk ~ med et tall for å finne ord nær hverandre, f.eks. "data analyse"~5.'
          />
        </ListItem>
      </List>
    </Paper>
  );

  const handleFilterStringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterString(event.target.value);
    setInitCurrentPage(0);
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          color: "#484848",
          margin: "1rem",
        }}
      >
        <Grid container direction="column" spacing={2}>
          {!isNew(currentSearch) && (
            <>
              <Grid item spacing={2}>
                <Typography variant="h6" color="primary">
                  Ditt søk: {currentSearch.navn}
                </Typography>
              </Grid>
            </>
          )}
          {canSaveSearch(finndoffUser) && (
            <Grid item>
              <SaveButtonAndModal
                saveSearch={saveSearch}
                currentSearch={currentSearch}
                initialSearchName={setValue()}
              />
            </Grid>
          )}
          <Grid item>
            <Box>
            <Box
                sx={{
                  position: 'relative',
                  border: '1px solid lightgrey',
                  borderRadius: '8px',
                  padding: '16px',
                  marginTop: '1rem',
                }}
              >
                <Tooltip title={tooltipContent} placement="right" arrow>
                  <Box>

                  {/* Category label in the upper-left corner */}
                  <Typography
                    variant="h5"
                    sx={{
                      position: 'absolute',
                      top: '-12px',
                      left: '16px',
                      backgroundColor: 'white',
                      paddingX: '8px',
                    }}
                  >
                    Nøkkelord i Kunngjøring
                    <IconButton size="small" sx={{ marginLeft: 1 }}>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Typography>
                  </Box>
                </Tooltip>
                
                <Box
                  sx={{
                    borderStyle: "solid",
                    border: "0px",
                    borderColor: "gray",
                    borderRadius: "2px",
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      borderStyle: "solid",
                      border: "0px",
                      borderColor: "gray",
                      borderRadius: "2px",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <TextField
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                      placeholder="Søk"
                      inputProps={{ "aria-label": "fritekst" }}
                      value={searchText}
                      color="primary"
                      variant="outlined"
                      size="small"
                      multiline
                      maxRows={10}
                      fullWidth
                      sx={{
                        backgroundColor: "white",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        overflow: "hidden",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderTop: "none", // Remove the top border
                          borderLeft: "none", // Remove the left border
                          borderRight: "none", // Remove the right border
                          borderBottom: "none", // Only keep the bottom border (underline)
                        },
                      }}
                    />
                  </Box>

                  <Button
                    aria-label="search"
                    onClick={handleSearchButtonClick}
                    endIcon={<SearchIcon />}
                    sx={{
                      height: "inherit",
                      borderBottomRightRadius: "4px",
                      borderTopRightRadius: "4px",
                      float: "right",
                    }}
                    size="large"
                  />
                </Box>
              </Box>

              {/* Value Min and Max Fields */}
              <Box
                sx={{
                  position: 'relative',
                  border: '1px solid lightgrey',
                  borderRadius: '8px',
                  padding: '16px',
                  marginTop: '1rem',
                }}
              >
                {/* Category label in the upper-left corner */}
                <Typography
                  variant="h5"
                  sx={{
                    position: 'absolute',
                    top: '-12px',
                    left: '16px',
                    backgroundColor: 'white',
                    paddingX: '8px',
                  }}
                >
                  Verdi
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Min"
                      value={valueMin || ""}
                      onChange={handleValueMinChange}
                      type="number"
                      fullWidth
                      inputProps={{
                        step: 100000, // Increment and decrement by 100,000
                        min: 0, // Prevent values below 0
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderTop: "none", // Remove the top border
                          borderLeft: "none", // Remove the left border
                          borderRight: "2px solid lightgrey", // Remove the right border
                          borderBottom: "none", // Only keep the bottom border (underline)
                        },
                        backgroundColor: "white", // Ensure background color is consistent
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Max"
                      value={valueMax || ""}
                      onChange={handleValueMaxChange}
                      type="number"
                      fullWidth
                      inputProps={{
                        step: 100000, // Increment and decrement by 100,000
                        min: 0, // Prevent values below 0
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderTop: "none", // Remove the top border
                          borderLeft: "2px solid lightgrey", // Remove the left border
                          borderRight: "none", // Remove the right border
                          borderBottom: "none", // Only keep the bottom border (underline)
                        },
                        backgroundColor: "white", // Ensure background color is consistent
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  position: 'relative',
                  border: '1px solid lightgrey',
                  borderRadius: '8px',
                  padding: '16px',
                  marginTop: '1rem',
                }}
              >
                {/* Category label in the upper-left corner */}
                <Typography
                  variant="h5"
                  sx={{
                    position: 'absolute',
                    top: '-12px',
                    left: '16px',
                    backgroundColor: 'white',
                    paddingX: '8px',
                  }}
                >
                  Kunngjøringstype
                </Typography>

                <FormGroup>
                  <FormControl fullWidth>
                    <Select
                      labelId="kunngjoringstyper"
                      id="kunngjoringstyper-select"
                      multiple
                      value={currentSearch.noticeTypes || []}
                      label="Kunngjøringstyper"
                      onChange={doNoticeTypeSearch}
                      renderValue={(selected) =>
                        selected.map((value) => noticeTypeMapping[value] || value).join(', ')
                      }
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderTop: "none", // Remove the top border
                          borderLeft: "none", // Remove the left border
                          borderRight: "none", // Remove the right border
                          borderBottom: "2px solid lightgrey", // Only keep the bottom border (underline)
                        },
                        backgroundColor: "white", // Ensure background color is consistent
                      }}
                    >
                      <MenuItem value="All">
                        <Checkbox checked={currentSearch.noticeTypes.includes('All')} />
                        Alle
                      </MenuItem>
                      <MenuItem value="Pin">
                        <Checkbox checked={currentSearch.noticeTypes.includes('Pin')} />
                        Veiledende
                      </MenuItem>
                      <MenuItem value="Notice">
                        <Checkbox checked={currentSearch.noticeTypes.includes('Notice')} />
                        Kunngjøring av konkurranse
                      </MenuItem>
                      <MenuItem value="Award">
                        <Checkbox checked={currentSearch.noticeTypes.includes('Award')} />
                        Tildeling
                      </MenuItem>
                      <MenuItem value="Intention">
                        <Checkbox checked={currentSearch.noticeTypes.includes('Intention')} />
                        Intensjon
                      </MenuItem>
                    </Select>
                  </FormControl>
                </FormGroup>

                {/* 'Vis kun' FormGroup */}
                <FormGroup sx={{ marginTop: '20px', marginLeft: '15px' }}>
                  <FormLabel component="legend">Vis kun</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedIsCanceled}
                          onChange={handleFilterIsCanceled}
                          color="primary"
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: '0.9rem', color: '#333' }}>
                          Avlyste
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCheckedAndDisabled || checkedActive}
                          onChange={handleActiveChange}
                          color="primary"
                          disabled={isCheckedAndDisabled} // Disabled if non-default sorting
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: '0.9rem', color: '#333' }}>
                          Aktive
                        </Typography>
                      }
                    />
                  </FormGroup>
                </FormGroup>
              </Box>

            </Box>
          </Grid>
          <Grid item>
              <Box
                sx={{
                  position: 'relative',        // Allows absolute positioning of the label
                  border: '1px solid lightgrey',  // Add border around all controls
                  borderRadius: '8px',         // Rounded corners
                  padding: '16px',             // Padding inside the box for spacing
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    position: 'absolute',
                    top: '-12px',              
                    left: '16px',              
                    backgroundColor: 'white',  
                    paddingX: '8px',           
                  }}
                >
                  Steder
                </Typography>

                <Accordion
                  sx={{
                    boxShadow: 'none',
                    border: 'none',    
                  }}
                >
                  <AccordionDetails>
                    <Hierarchy
                      tree={steder}
                      nodes={steder.rootNodes[0].children}
                      openedNodeIds={openedSteder}
                      checkedNodeIds={currentSearch.stedIds}
                      setChecked={setCheckedSted}
                      setOpened={setOpenedSted}
                    />
                  </AccordionDetails>
                </Accordion>

                {/* FormGroup with margin for spacing */}
                <FormGroup sx={{ marginTop: '10px', marginLeft: '15px' }}>
                  <FormLabel component="legend">
                    Skjul kunngjøringer med
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedNational}
                          onChange={handleFilterNationalChange}
                          color={"secondary"}
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: '0.9rem', color: '#333' }}>
                          Hele landet som sted
                        </Typography>
                      }
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedRegional}
                          onChange={handleFilterRegionalChange}
                          color={"secondary"}
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: '0.9rem', color: '#333' }}>
                          Fylke som sted
                        </Typography>
                      }
                    />
                  </FormGroup>
                </FormGroup>
                {Array.from(currentSearch.stedIds).map((stedId) => {
                  return <StyledChip key={"sted_chip_" + stedId} label={steder.nodeMap[stedId]?.label} />;
                })}
              </Box>


              <Box
                sx={{
                  position: 'relative',        
                  border: '1px solid lightgrey',  
                  borderRadius: '8px',        
                  padding: '16px',
                  marginTop: '20px',
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    position: 'absolute',
                    top: '-12px',
                    left: '16px',
                    backgroundColor: 'white',
                    paddingX: '8px',
                  }}
                >
                  Koder
                </Typography>
       
                <TextField
                  placeholder="Filtrer på beskrivelse eller koder"
                  value={filterString}
                  onChange={handleFilterStringChange}
                  variant="outlined"
                  size="small"
                  multiline
                  maxRows={5}
                  fullWidth
                  sx={{
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderTop: "none", // Remove the top border
                      borderLeft: "none", // Remove the left border
                      borderRight: "none", // Remove the right border
                      borderBottom: "2px solid lightgrey", // Only keep the bottom border (underline)
                    },
                  }}
                />

                {/* Accordion without border */}
                <Accordion
                  expanded={isKategoriExpanded}
                  onChange={() => setKategoriExpanded((prev) => !prev)}
                  sx={{
                    boxShadow: "none",
                    border: "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography variant="h5">CPV-nummer</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Hierarchy
                      tree={kategorier}
                      nodes={filterNodes(kategorier.rootNodes, filterString)}
                      openedNodeIds={openedKategorier}
                      checkedNodeIds={currentSearch.kategoriIds}
                      setChecked={setCheckedKategori}
                      setOpened={setOpenedKategori}
                    />
                  </AccordionDetails>
                </Accordion>

                {/* Chips for selected CPV categories */}
                {Array.from(currentSearch.kategoriIds).map((kategoriId) => {
                  return (
                    <StyledChip
                      key={"kategori_chip_" + kategoriId}
                      label={kategorier.nodeMap[kategoriId].label.split(" ")[0]}
                    />
                  );
                })}

                {/* FormGroup for the checkbox */}
                <FormGroup sx={{ marginTop: '10px', marginLeft: '15px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedWithParent}
                        onChange={handleWithParentChange}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: '0.9rem', color: '#333' }}>
                        Inkluder CPV-foreldrekoder
                      </Typography>
                    }
                  />
                </FormGroup>
              </Box>

          </Grid>
          {!isNew(currentSearch) && currentSearch.mottakere.length > 0 && (
            <Grid item>
              <Typography variant="subtitle1">Mottakere:</Typography>
              <Grid container spacing={1}>
                {currentSearch.mottakere.map((x, i) => {
                  return (
                    <Grid item key={x}>
                      <StyledChip key={i} label={x} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  );
};
