// SearchForm.tsx

import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { FinndoffUser } from "../../utils/contexts/UserContext";
import { useSnackbar } from "../../utils/hooks/useSnackbar";
import { useApi } from "../../utils/hooks/useApi";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ClearIcon from "@mui/icons-material/Clear";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { config } from "../../config";
import { useNavigate } from "react-router-dom";

interface SignupFormProps {
  searchesFormTrigger: (setSearchesForm: boolean) => void;
  inviteError: (hasInviteError: boolean) => void;
  externalUserId: string;
  industryCode: string;
}

export const SearchForm: FunctionComponent<SignupFormProps> = ({
  searchesFormTrigger,
  inviteError,
  externalUserId,
  industryCode,
}) => {
  const { enqueueErrorMsg } = useSnackbar();
  const [items, setItems] = useState<Industry[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [filterText, setFilterText] = useState("");
  const { getAllIndustries: apiAllIndustriesMethod } = GetIndustriesMethods();
  const [preselectedIndustryName, setPreselectedIndustryName] = useState("");
  const [isSearchesLoading, setIsSearchesLoading] = useState(false);
  const [userId, setUserId] = useState(0);
  const frontendUrl = window.location.origin;
  const navigate = useNavigate();

  const getIndustries = async () => {
    try {
      const results = await apiAllIndustriesMethod();
      setItems(results);
    } catch (e: any) {
      enqueueErrorMsg(`Error fetching industries: ${e.message || e}`);
    }
  };

  const setPreselectedIndustry = async (industryCode: string) => {
    try {
      const preselectedItem = items.find((item) =>
        industryCode.startsWith(item.code)
      );
      if (preselectedItem) {
        setPreselectedIndustryName(preselectedItem.shortName);
        setSelectedItems([preselectedItem.id]);
      } else {
        console.log(
          `No item found with code starting with '${industryCode}'.`
        );
      }
    } catch (e: any) {
      console.log(`Error finding with code starting with '${industryCode}':`, e);
    }
  };

  const findIndustryCodesForSelectedIds = () => {
    const industryCodes = selectedItems
      .map((id) => {
        const selectedItem = items.find((item) => item.id === id);
        return selectedItem ? selectedItem.code : null;
      })
      .filter((code) => code !== null) as string[];

    return industryCodes;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getIndustries();
      } catch (error: any) {
        enqueueErrorMsg(`Error fetching industries: ${error.message || error}`);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (items.length > 0) {
      setPreselectedIndustry(industryCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const handleFilterTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setFilterText(value);
  };

  const handleClearFilter = () => {
    setFilterText("");
  };

  const columns: GridColDef[] = [
    { field: "shortName", headerName: "Bransjenavn", width: 250 },
  ];

  const filteredItems = items
    .filter((item) => {
      const nameMatches = item.name
        .toLowerCase()
        .includes(filterText?.toLowerCase());
      const notesMatches = item.notes
        .toLowerCase()
        .includes(filterText?.toLowerCase());
      const subIndustryMatches = item?.subIndustriesDescription
        ?.toLowerCase()
        .includes(filterText?.toLowerCase());
      return nameMatches || notesMatches || subIndustryMatches;
    })
    .sort((a, b) => {
      const aIsSelected = selectedItems.includes(a.id);
      const bIsSelected = selectedItems.includes(b.id);

      if (aIsSelected && !bIsSelected) {
        return -1;
      } else if (!aIsSelected && bIsSelected) {
        return 1;
      } else {
        return 0;
      }
    });

    const handleCellClick = (params: { id: any; }) => {
      const clickedItemId = params.id;
      const isItemSelected = selectedItems.includes(clickedItemId);
  
      if (isItemSelected) {
        setSelectedItems(selectedItems.filter((id) => id !== clickedItemId));
      } else if (selectedItems.length < 3) {
        setSelectedItems([...selectedItems, clickedItemId]);
      } else {
        enqueueErrorMsg("You can only select up to 3 industries.");
      }
    };

  const findItemById = (id: number) => {
    return items.find((item) => item.id === id);
  };

  const handleRemoveSelectedItem = (itemId: number) => {
    setSelectedItems(selectedItems.filter((item) => item !== itemId));
  };

  const toUserIndustrySearchesApiBody = (): UserSearches => ({
    ExternalUserId: externalUserId,
    Industries: findIndustryCodesForSelectedIds(),
  });

  const toUserPaymentUpdateApiBody = (): PaymentRedirect => ({
    ExternalUserId: externalUserId,
    SuccessUrl: `${frontendUrl}/signupresult/`,
    CancelUrl: `${frontendUrl}/signupresult/`,
  });

  const {
    userIndustrySearchesMethod: apiIndustryUserSearches,
  } = IndustrySearchesApiMethods(toUserIndustrySearchesApiBody());
  const { userPaymentUpdateMethod: apiUserPaymentUpdate } =
    UpdatePaymentsApiMethods(toUserPaymentUpdateApiBody());

  const saveSearches = async () => {
    try {
      setIsSearchesLoading(true);

      const results = await apiIndustryUserSearches();
      if (results?.externalUserId) {
        setUserId(results.id);
        await setSearchResultsAction(results);
      } else {
        enqueueErrorMsg("Feil på lagring av bransjesøk");
        inviteError(true);
      }
    } catch (e: any) {
      enqueueErrorMsg(`Kunne ikke lagre bransjesøk: ${e.message || e}`);
      inviteError(true);
    } finally {
      setIsSearchesLoading(false);
    }
  };

  const setSearchResultsAction = async (user: FinndoffUser) => {
    if (user?.paymentMethod === "Card") {
      try {
        const results = await apiUserPaymentUpdate();
        if (results?.url) {
          window.location.href = results.url;
        } else {
          enqueueErrorMsg("Feil på lagring av bransjesøk");
          inviteError(true);
        }
      } catch (error: any) {
        enqueueErrorMsg(
          "En feil oppsto under oppdatering av brukerbetaling"
        );
        inviteError(true);
      }
    } else {
      searchesFormTrigger(false);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ backgroundColor: "#E5F3F3", marginTop: "0.5%" }}
      >
        <Grid item xs={4} md={4} style={{ marginLeft: "10%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Valgte bransjer</Typography>
            </Grid>
            <Grid item>
              <div>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  {selectedItems.map((item) => (
                    <li
                      key={item}
                      style={{ display: "inline-block", margin: "4px" }}
                    >
                      <div
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "15px",
                          padding: "4px 8px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ marginRight: "4px" }}>
                          {findItemById(item)?.shortName}
                        </span>
                        <IconButton
                          onClick={() => handleRemoveSelectedItem(item)}
                          size="small"
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={5}
          md={5}
          style={{
            marginLeft: "1%",
            marginBottom: "0.5%",
            paddingRight: "1%",
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h6">Bransjer</Typography>
          </Grid>
          <Grid
            item
            style={{
              backgroundColor: "white",
              borderRadius: "8px",
              padding: "2%",
              flexGrow: 1,
              position: "relative", // Ensure the loading indicator overlays correctly
            }}
          >
            <Typography style={{ paddingLeft: "10px", paddingBottom: "10px" }}>
              Velg bransjer du ønsker varslinger for. {preselectedIndustryName}
            </Typography>
            <TextField
              type="text"
              placeholder="Søk etter bransje..."
              value={filterText}
              onChange={handleFilterTextChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {filterText && (
                      <IconButton
                        onClick={handleClearFilter}
                        edge="end"
                        tabIndex={-1}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              style={{
                width: "100%",
                paddingBottom: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            />
            <Grid item style={{ paddingBottom: "10px", paddingLeft: "10px" }}>
              <DataGrid
                rows={filteredItems}
                columns={columns}
                getRowId={(row) => row.id} // Ensure each row has a unique id
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                onCellClick={handleCellClick}
                rowSelectionModel={selectedItems.map((item) => item.toString())}
                hideFooterSelectedRowCount
                disableRowSelectionOnClick // Correct prop name
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            textAlign="end"
            sx={{
              "& > *": {
                margin: (theme) => theme.spacing(1),
              },
            }}
          >
            <PrimaryButton
              onClick={saveSearches}
              text={"Registrer bransjesøk!"}
              disabled={isSearchesLoading || selectedItems.length === 0}
            />
            {isSearchesLoading && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  borderRadius: "8px",
                }}
              >
                <LoadingIndicator />
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

// Type Definitions
export type PaymentSessionResponse = {
  url: string;
};

export type UserSearches = {
  ExternalUserId: string;
  Industries: string[];
};

export type Industry = {
  id: number;
  code: string;
  parentCode: string;
  level: string;
  name: string;
  shortName: string;
  notes: string;
  subIndustriesDescription: string;
};

export type PaymentRedirect = {
  ExternalUserId: string;
  SuccessUrl: string;
  CancelUrl: string;
};

export const GetIndustriesMethods = () => {
  const { error, loading, get } = useApi("/api/industry");
  return {
    error,
    loading,
    getAllIndustries: async (): Promise<Industry[]> => {
      const apiResponse = await get("");
      return apiResponse;
    },
  };
};

export const IndustrySearchesApiMethods = (industries: UserSearches) => {
  const { error, loading, post } = useApi("/api/me/bruker/searchessignup");
  return {
    error,
    loading,
    userIndustrySearchesMethod: async (): Promise<FinndoffUser> => {
      const body = industries;
      const apiResponse = await post("", body);
      return apiResponse;
    },
  };
};

export const UpdatePaymentsApiMethods = (paymentUser: PaymentRedirect) => {
  const { error, loading, post } = useApi("/api/me/bruker/cardsignup");
  return {
    error,
    loading,
    userPaymentUpdateMethod: async (): Promise<PaymentSessionResponse> => {
      const body = paymentUser;
      const apiResponse = await post("", body);
      return apiResponse;
    },
  };
};
