import { FunctionComponent } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";

export const Login: FunctionComponent = () => {
  const { loginWithRedirect } = useAuth0();

  const userHintParam = new URLSearchParams(useLocation().search).get("_userhint");
  const userHint = userHintParam ? userHintParam : "";

  const handleLoginWithRedirect = () => {
    loginWithRedirect({ authorizationParams: { display: "page", ui_locales: "nb", login_hint: userHint } });
  };

  handleLoginWithRedirect();
  return null;
};
