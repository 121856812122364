import React, { ReactNode, useCallback, useEffect, useReducer } from "react";
import classnames from "classnames";
import MaterialModal from "@mui/material/Modal";
import css from "./modal.module.scss";

export interface ModalProps {
  onClose?: Function;
  title: ReactNode;
  children?: React.ReactNode;
  className?: string[] | string;
  width?: string;
}

interface Props extends ModalProps {
  isOpen: boolean;
}

const Modal: React.FC<Props> = ({ isOpen, onClose, children, title, className, width }) => {
  return (
    <MaterialModal
      open={isOpen}
      onClose={() => (onClose ? onClose() : "")}
      sx={{
        display: "flex",
        width: "90%",
        height: "100%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div
        className={classnames(css.doffinBaseModal, className)}
        style={{ width, maxHeight: "100%", color: "black", backgroundColor: "white" }}
      >
        <div className={css.doffinModalHeader}>
          <h2>{title}</h2>
        </div>
        <div className={css.doffinModalContents}>{children}</div>
      </div>
    </MaterialModal>
  );
};

interface State {
  modalId: string;
  isOpen: boolean;
  modal: React.FC<ModalProps>;
}

type Action =
  | { type: "open"; modalId: string }
  | { type: "close"; modalId: string }
  | { type: "setModal"; modalId: string; modal: React.FC<ModalProps> };

const emptyState: State = {
  modalId: "",
  isOpen: false,
  modal: () => null,
};

function reducer(state: State, action: Action): State {
  let stateCopy = { ...state };
  switch (action.type) {
    case "open":
      stateCopy.isOpen = true;
      break;
    case "close":
      stateCopy.isOpen = false;
      break;
    case "setModal":
      if (action.modal) {
        if (!stateCopy) {
          stateCopy = emptyState;
        }
        stateCopy.modal = action.modal as React.FC<ModalProps>;
      }
      break;
    default:
      return state;
  }
  return stateCopy;
}

export const useModal = (modalId: string = "__default") => {
  const [state, dispatch] = useReducer(reducer, { ...emptyState, modalId });

  const open = () => {
    dispatch({ type: "open", modalId });
  };
  const close = useCallback(() => {
    dispatch({ type: "close", modalId });
  }, [modalId]);

  useEffect(() => {
    const modal: React.FC<ModalProps> = ({ children, ...props }) => {
      const onClose = () => {
        if (typeof props.onClose === "function") {
          props.onClose();
        }
        close();
      };

      if (state.isOpen) {
        return (
          <Modal isOpen={state.isOpen} onClose={onClose} {...props}>
            {children}
          </Modal>
        );
      }

      return <></>;
    };
    dispatch({ type: "setModal", modalId, modal });
  }, [state.isOpen, modalId, close]);

  return {
    isOpen: state.isOpen,
    openModal: open,
    closeModal: close,
    Modal: state.modal,
  };
};
