import React, { FunctionComponent } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import BusinessIcon from '@mui/icons-material/Business';
import { useApi } from "../../../utils/hooks/useApi";
import { useSnackbar } from "../../../utils/hooks/useSnackbar";
import { FinndoffUser } from "../../../utils/contexts/UserContext";
import { CompanyFollower } from "../Search/datatypes"; 
import { paths } from "../../../app/paths";

interface FollowedCompanyCardProps {
  companyFollower: CompanyFollower;
  finndoffUser: FinndoffUser;
  onUnfollowCompany: (companyExternalId: string) => void;
}

export const FollowedCompanyCard: FunctionComponent<FollowedCompanyCardProps> = ({
  companyFollower,
  finndoffUser,
  onUnfollowCompany,
}) => {
  const { error, response, del } = useApi("/api/me/bruker/following");
  const { enqueueSuccessMsg, enqueueErrorMsg } = useSnackbar();

  const unfollowCompany = async (companyExternalId: string) => {
    if (!window.confirm("Er du sikker på at du vil slutte å følge denne bedriften?")) {
      return;
    }
    await del(`/${companyExternalId}`);
    if (response.ok) {
      onUnfollowCompany(companyExternalId);
      enqueueSuccessMsg("Du har sluttet å følge bedriften.");
    } else if (error) {
      enqueueErrorMsg("Kunne ikke slutte å følge bedriften");
    }
  };

  const company = companyFollower.company;

  return (
    <Card sx={{ backgroundColor: "transparent" }}>
      {/* <Link
        href={`/portal/${paths.companyDetail.replace(":id", company.externalId)}`}
        sx={{ textDecoration: "none" }}
      > */}
        <Grid container>
          <Grid
            item
            xs={3}
            sx={{
              backgroundColor: "rgba(224, 240, 241)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BusinessIcon color="primary" fontSize="large" />
          </Grid>
          <Grid item xs={9} sx={{ backgroundColor: "white" }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                {company.navn}
              </Typography>
              <Typography variant="body2">
                Organisasjonsnummer: {company.organisasjonsNummer}
              </Typography>
              <Typography variant="body2">
                Næringskode: {company.naeringskode1Kode} - {company.naeringskode1Beskrivelse}
              </Typography>
              {/* Include other fields if available */}
            </CardContent>
            <CardActions
              sx={{
                justifyContent: "flex-end",
                "& a": {
                  textDecoration: "none",
                },
              }}
            >
              <Button
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  unfollowCompany(company.externalId);
                }}
              >
                Slutt å følge
              </Button>
            </CardActions>
          </Grid>
        </Grid>
      {/* </Link> */}
    </Card>
  );
};
