import { cloneSet, immutablyAdd, immutablyDelete } from "./immutableSet";

export interface Tree {
  rootNodes: Node[];
  nodeMap: { [key: number]: Node };
  aliasMap: { [key: number]: number };
}

export const emptyTree: Tree = { rootNodes: [], nodeMap: {}, aliasMap: {} };

export interface Node {
  id: number;
  label: string;
  children: Node[];
  parent?: Node;
  aliasIds?: number[];
}

export const resolveAliasId = (tree: Tree, id: number): number => tree.nodeMap[id]?.id || tree.aliasMap[id];

export const getNodeById = (tree: Tree, id: number): Node => tree.nodeMap[id] || tree.nodeMap[tree.aliasMap[id]];

export const someChildrenCheckedById = (tree: Tree, checkedNodeIds: Set<number>, id: number): boolean =>
  someChildrenChecked(tree.nodeMap[resolveAliasId(tree, id)], checkedNodeIds);

export const someChildrenChecked = (node: Node, checkedNodeIds: Set<number>): boolean => {
  if (node !== undefined) {
    return node.children.some((child) => checkedNodeIds.has(child.id) || someChildrenChecked(child, checkedNodeIds));
  }
};

export const nodeIdOrParentChecked = (tree: Tree, checkedNodeIds: Set<number>, id: number): boolean => {
  const node = getNodeById(tree, id);
  return nodeOrParentChecked(node, checkedNodeIds);
};
export const nodeOrParentChecked = (node: Node, checkedNodeIds: Set<number>): boolean => {
  if (node !== undefined) {
    return checkedNodeIds.has(node.id) || (!!node.parent && nodeOrParentChecked(node.parent, checkedNodeIds));
  }
};

export type Converter<T> = (node: T) => { id: number; label: string; aliasIds?: number[] };
export type Convertible<T> = { children: Array<T>; aliasIds?: number[] };

export const createTree = <T extends Convertible<T>>(rootNodesRaw: Array<T>, converter: Converter<T>): Tree => {
  const rootNodes = convertNodesAndChildren(rootNodesRaw, converter);
  populateParents(rootNodes);
  const nodeMap: { [key: number]: Node } = {};
  populateNodeMap(nodeMap, rootNodes);
  const aliasMap: { [key: number]: number } = {};
  populateAliasMap(aliasMap, rootNodes);
  return { rootNodes, nodeMap, aliasMap };
};

export const convertNodesAndChildren = <T extends Convertible<T>>(
  nodesRaw: Array<T>,
  converter: Converter<T>
): Node[] =>
  nodesRaw.map((node) => ({ ...converter(node), children: convertNodesAndChildren(node.children, converter) }));

const populateParents = (nodes: Node[], parent?: Node): void =>
  nodes.forEach((node) => {
    node.parent = parent;
    populateParents(node.children, node);
  });

const populateNodeMap = (nodeMap: { [key: number]: Node }, nodes: Node[]): void =>
  nodes.forEach((node) => {
    nodeMap[node.id] = node;
    populateNodeMap(nodeMap, node.children);
  });

const populateAliasMap = (aliasMap: { [key: number]: number }, nodes: Node[]): void =>
  nodes.forEach((node) => {
    node.aliasIds?.forEach((aliasId) => (aliasMap[aliasId] = node.id));
    populateAliasMap(aliasMap, node.children);
  });

export const immutablySet = (set: Set<number>, id: number, checked: boolean): Set<number> =>
  checked ? immutablyAdd(set, id) : immutablyDelete(set, id);

export const omitImplicitlyCheckedChildren = (nodes: Node[], allCheckedNodeIds: Set<number>): Set<number> => {
  //include checked nodes and don't recurse to children
  const checkedNodeIds = nodes
    .filter((node) => nodeCheckedEquals(allCheckedNodeIds, node, true))
    .map((node) => node.id);

  //don't include unchecked nodes but recurse
  const uncheckedNodesChildren = nodes
    .filter((node) => !allCheckedNodeIds.has(node.id))
    .flatMap((node) => node.children);

  return new Set([
    ...checkedNodeIds,
    ...(uncheckedNodesChildren.length === 0
      ? []
      : Array.from(omitImplicitlyCheckedChildren(uncheckedNodesChildren, allCheckedNodeIds))),
  ]);
};

export const immutablySetNodeById = (
  tree: Tree,
  checkedNodeIds: Set<number>,
  id: number,
  checked: boolean
): Set<number> => {
  checkedNodeIds = cloneSet(checkedNodeIds);
  id = resolveAliasId(tree, id);
  updateCheckedNodeById(tree, checkedNodeIds, id, checked);
  return omitImplicitlyCheckedChildren(tree.rootNodes, checkedNodeIds);
};

export const updateCheckedNodeById = (tree: Tree, checkedNodeIds: Set<number>, id: number, checked: boolean): void => {
  const node = tree.nodeMap[id];
  if (!node) {
    throw new Error("node not found");
  }

  if (nodeOrParentChecked(node, checkedNodeIds) === checked) {
    return;
  }

  updateCheckedNode(checkedNodeIds, node, checked);
  updateCheckedNodeChildren(checkedNodeIds, node, checked);

  checked ? updateCheckNodeParents(checkedNodeIds, node) : updateUncheckNodeParents(checkedNodeIds, node);
};

const nodeCheckedEquals = (checkedNodeIds: Set<number>, node: Node, checked: boolean): boolean =>
  checkedNodeIds.has(node.id) === checked;

const updateCheckedNode = (checkedNodeIds: Set<number>, node: Node, checked: boolean) =>
  checked ? checkedNodeIds.add(node.id) : checkedNodeIds.delete(node.id);

const updateCheckedNodeChildren = (checkedNodeIds: Set<number>, node: Node, checked: boolean): void => {
  node.children.forEach((child) => {
    if (!nodeCheckedEquals(checkedNodeIds, child, checked)) {
      updateCheckedNode(checkedNodeIds, child, checked);
      updateCheckedNodeChildren(checkedNodeIds, child, checked);
    }
  });
};

const updateCheckNodeParents = (checkedNodeIds: Set<number>, node: Node): void => {
  if (!node.parent) return;

  // set parent, if all siblings are explicitly set
  const allSiblingsExplicitlySet = node.parent.children.every((child) =>
    nodeCheckedEquals(checkedNodeIds, child, true)
  );
  if (allSiblingsExplicitlySet && !nodeOrParentChecked(node.parent, checkedNodeIds)) {
    updateCheckedNode(checkedNodeIds, node.parent, true);
    updateCheckNodeParents(checkedNodeIds, node.parent);
  }
};

const updateUncheckNodeParents = (checkedNodeIds: Set<number>, node: Node): void => {
  if (!node.parent) return;

  // if parent is set, then set all siblings and uncheck parent
  if (nodeOrParentChecked(node.parent, checkedNodeIds)) {
    //check all siblings except self
    node.parent.children
      .filter((child) => child.id !== node.id)
      .forEach((sibling) => updateCheckedNode(checkedNodeIds, sibling, true));
    //unset parent
    updateCheckedNode(checkedNodeIds, node.parent, false);
    //recurse
    updateUncheckNodeParents(checkedNodeIds, node.parent);
  }
};

export const filterNodes = (nodes: Node[], filterTerm: string): Node[] => {
  const matches: Node[] = [];

  nodes.forEach((node) => {
    const childMatches = filterNodes(node.children, filterTerm);
    if (node.label.toLowerCase().includes(filterTerm.toLowerCase())) {
      const nodeCopy = { ...node, children: childMatches };
      matches.push(nodeCopy);
    } else if (childMatches.length > 0) {
      const nodeCopy = { ...node, children: childMatches };
      matches.push(nodeCopy);
    }
  });

  return matches;
};

export const filterNodesWithAncestors = (nodes: Node[], filterTerm: string, parent: Node | null = null): Node[] => {
  const matches: Node[] = [];

  nodes.forEach((node) => {
    // Check if this node matches the filter term
    const nodeMatches = node.label.toLowerCase().includes(filterTerm.toLowerCase());

    // Recursively filter the children
    const filteredChildren = filterNodes(node.children, filterTerm);

    if (nodeMatches || filteredChildren.length > 0) {
      // Create a copy of the node
      const nodeCopy: Node = { ...node, parent, children: [] };

      // Assign parent to nodeCopy
      nodeCopy.parent = parent;

      // Assign filtered children and set their parent to nodeCopy
      nodeCopy.children = filteredChildren.map((child) => {
        const childCopy = { ...child, parent: nodeCopy };
        return childCopy;
      });

      matches.push(nodeCopy);
    }
  });

  return matches;
};

export const assignParents = (nodes: Node[], parent: Node | null = null) => {
  nodes.forEach((node) => {
    node.parent = parent;
    if (node.children && node.children.length > 0) {
      assignParents(node.children, node);
    }
  });
};

export const collectParentIds = (node: Node | undefined, expandedNodes: Set<number>) => {
  if (!node) return; 
  if (expandedNodes.has(node.id)) return; 
  expandedNodes.add(node.id); 
  if (node.parent) {
    collectParentIds(node.parent, expandedNodes); 
  }
};