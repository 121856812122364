import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Grid, Link, Paper, Typography } from "@mui/material";
import { FinndoffUser, useUserContext } from "../../utils/contexts/UserContext";
import { SignupForm } from "./SignupForm";
import { SignupSideContent } from "./SignupSideContent";
import { useLocation, useParams } from "react-router-dom";
import { paths } from "../../app/paths";
import { SearchForm } from "./SearchForm";
import { useApi } from "../../utils/hooks/useApi";
import { useSnackbar } from "../../utils/hooks/useSnackbar";

interface SignupResultProps {
}

export const SignupResult: FunctionComponent<SignupResultProps> = () => {
  let { externalUserId } = useParams();
  const { isLoading } = useUserContext();
  const { enqueueErrorMsg } = useSnackbar();
  const [isSuccess, setIsSuccess] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const cancelString = params.get("_iscancel") ? params.get("_iscancel")?.toLowerCase() : "false";
  const [isCancel, setIsCancel] = useState(false);
  const { userSignupResultMethod: apiGetSignupResultMethod } = GetSignupResultApiMethods({externalUserId : externalUserId, isCancel: cancelString});

  
  const GetSignupResult = async () => {
    try {
      const result = await apiGetSignupResultMethod();
      setIsSuccess(result);
    } catch (e) {
      enqueueErrorMsg(`Error fetching  signup result: ${e}`);
    }
  };

  useEffect(() => {
    if (cancelString === "true") {
      setIsCancel(true);
    }
    console.log(isCancel)
  }, [cancelString]);  

  useEffect(() => {
    const fetchData = async () => {
      try {
        await GetSignupResult();
      } catch (error) {
        console.error('Error fetching signup result:', error);
        enqueueErrorMsg(`Error fetching  signup result: ${error}`);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <>
          {isSuccess && (
            <Box paddingTop="20%" paddingBottom="20%" width="100%" textAlign="center">
              <Typography variant="h5">Takk for at du registrerte deg hos Finndoff!</Typography>
              <Typography variant="h6">
                Sjekk innboksen din, og fullfør registreringen fra epost sendt til din epostadresse.
              </Typography>
              <Box marginTop="25px">
                <Link href={paths.portal} sx={{ textDecoration: "none" }}>
                  Tilbake til forsiden
                </Link>
              </Box>
            </Box>
          )}
          {!isSuccess && (
            <Box paddingTop="20%" paddingBottom="20%"  width="100%" textAlign="center">
              <Typography variant="h5">Noe gikk galt med registreringen!</Typography>
              <Link
                href="https://share-eu1.hsforms.com/183V6W1IGRcikwgsn9G8Nngfai8j"
                target="_blank"
                rel="noreferrer"
                sx={{
                  fontSize: "1.2rem",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  fontWeight: 500,
                  lineHeight: "1.6",
                  letterSpacing: "0.0075em",
                  position: "relative",
                  color: "#008489",
                  marginBottom: "24px",
                  marginTop: "24px",
                  textAlign: "center",
                }}
              >
                Kontakt oss her så hjelper vi deg!
              </Link>
            </Box>
          )}
    </>
  );
};

export type SignupResultRequest = {
  externalUserId: string;
  isCancel: string;
};
  
export const GetSignupResultApiMethods = (signResultReq: SignupResultRequest) => {
  const { error, loading, get } = useApi(`/api/me/bruker/signupresult/${signResultReq.externalUserId}?isCancel=${signResultReq.isCancel}`);
  
  return {
    error,
    loading,
    userSignupResultMethod: async (): Promise<boolean> => {
      const apiResponse = await get();
      return apiResponse;
    },
  };
};