import { FunctionComponent } from "react";

import { ReactComponent as FinndoffLogo } from "../../images/logo_finndoff_1.svg";
import { ReactComponent as FinndoffLogoDark } from "../../images/logo_finndoff_dark.svg";
import { Box } from "@mui/material";

interface LogoProps {
  size?: boolean;
  variant?: string;
}

export const Logo: FunctionComponent<LogoProps> = ({ size, variant }) => {
  return (
    <Box paddingTop="6px">
      {variant === "dark" ? (
        <FinndoffLogoDark width="180px" height="100%" />
      ) : (
        <FinndoffLogo width="180px" height="100%" />
      )}
    </Box>
  );
};
