import { Button } from "@mui/material";
import { FunctionComponent } from "react";

type Props = {
  link?: string;
  text: string;
  onClick?: () => void;
  dynamicSize?: string;
  disabled?: boolean;
};

export const PrimaryButton: FunctionComponent<Props> = ({ link, text, onClick, dynamicSize, disabled }) => {
  return (
    <>
      <Button
        disabled={disabled}
        size="large"
        href={link}
        onClick={onClick}
        sx={{
          color: "white",
          backgroundColor: (theme) => theme.palette.primary.main,
          fontSize: dynamicSize ? `${dynamicSize}vw` : "14px",
          width: dynamicSize && `${dynamicSize}0vw`,
          "&:hover": {
            backgroundColor: (theme) => theme.palette.primary.light,
          },
          "&:disabled": {
            backgroundColor: "#dadada",
            color: "grey",
          },
        }}
      >
        {text}
      </Button>
    </>
  );
};

export const SearchSaveButton: FunctionComponent<Props> = ({ link, text, onClick, dynamicSize, disabled }) => {
  return (
    <>
      <Button
        disabled={disabled}
        size="large"
        href={link}
        onClick={onClick}
        sx={{
          color: '#FFCB05',
          backgroundColor: (theme) => theme.palette.primary.dark,
          fontSize: dynamicSize ? `${dynamicSize}vw` : "20px",
          width: '100%',
          paddingTop: '10px',
          paddingBottom : '10px',
          paddingLeft : '10px',
          paddingRight : '10px',
          "&:hover": {
            backgroundColor: (theme) => theme.palette.primary.light,
            color: '#FFCB05',
          },
          "&:disabled": {
            backgroundColor: "#dadada",
            color: "grey",
          },
        }}
      >
        {text}
      </Button>
    </>
  );
};
