// SupplierBulletinUserDetails.tsx

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material';
import { UserInterest } from './datatypes';
import { useApi } from '../../../utils/hooks/useApi';

interface SupplierBulletinUserDetailsProps {
  userInterest: UserInterest;
  doffinAnbudId: string;
  onClose: () => void;
  onSave: () => void;
}

const SupplierBulletinUserDetails: React.FC<SupplierBulletinUserDetailsProps> = ({
  userInterest,
  doffinAnbudId,
  onClose,
  onSave,
}) => {
  const [status, setStatus] = useState<string>(userInterest.status || 'NotContacted');
  const [statusReason, setStatusReason] = useState<string>(userInterest.statusReason || '');
  const { post } = useApi(`/api/bulletin/byDoffinAnbudId/${doffinAnbudId}`);

  // List of reasons for declining
  const declineReasons = [
    { value: 'Mangel på kapasitet', label: 'Mangel på kapasitet' },
    { value: 'Ikke relevant kompetanse', label: 'Ikke relevant kompetanse' },
    { value: 'For høy pris', label: 'For høy pris' },
    { value: 'Ikke kompatibel tidsplan', label: 'Ikke kompatibel tidsplan' },
    { value: 'Andre prioriteringer', label: 'Andre prioriteringer' },
    { value: 'Ufullstendige dokumenter', label: 'Ufullstendige dokumenter' },
    { value: 'Annet', label: 'Annet' },
  ];

  const handleSave = async () => {
    try {
      await post(`/updateUserInterestStatus`, {
        UserId: userInterest.id,
        Status: status,
        StatusReason: status === 'Declined' ? statusReason : '',
      });
      onSave();
    } catch (error) {
      console.error('Failed to update user interest status:', error);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Button onClick={onClose} sx={{ mb: 2 }}>
        Tilbake
      </Button>
      <Typography variant="h5" gutterBottom>
        {userInterest.firmaNavn}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Organisasjonsnummer:</strong> {userInterest.orgNummer}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Kontaktperson:</strong> {userInterest.kontaktperson}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Telefonnummer:</strong> {userInterest.kontaktpersonMobil}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>E-postadresse:</strong> {userInterest.epostadresse}
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        Valgte CPV-koder:
      </Typography>
      <List>
        {userInterest.cpvKoder.map((cpv) => (
          <ListItem key={cpv.id}>
            <ListItemText primary={`${cpv.kode} - ${cpv.beskrivelse}`} />
          </ListItem>
        ))}
      </List>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel>Status</InputLabel>
        <Select value={status} label="Status" onChange={(e) => setStatus(e.target.value)}>
          <MenuItem value="NotContacted">Ikke kontaktet</MenuItem>
          <MenuItem value="Selected">Valgt</MenuItem>
          <MenuItem value="Declined">Avslått</MenuItem>
          {/* Add other status options if needed */}
        </Select>
        <FormHelperText>Velg status for denne interessenten</FormHelperText>
      </FormControl>

      {/* Conditional rendering of decline reasons */}
      {status === 'Declined' && (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Årsak til avslag</InputLabel>
          <Select
            value={statusReason}
            label="Årsak til avslag"
            onChange={(e) => setStatusReason(e.target.value)}
          >
            {declineReasons.map((reason) => (
              <MenuItem key={reason.value} value={reason.value}>
                {reason.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Velg årsak til avslag</FormHelperText>
        </FormControl>
      )}

      {/* Action Buttons */}
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Lagre
        </Button>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Avbryt
        </Button>
      </Stack>
    </Box>
  );
};

export default SupplierBulletinUserDetails;
