import { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";
import { ReferenceDataProvider } from "../../utils/contexts/ReferenceDataContext";
export interface LinkType {
  text: string;
  path: string;
  onlyWhenAuthenticated: boolean;
}

export const Portal: FunctionComponent = () => {
  return (
    <>
      <ReferenceDataProvider>
        <Outlet />
      </ReferenceDataProvider>
    </>
  );
};
