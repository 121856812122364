import React, { useEffect, useState, useCallback } from 'react';
import { Grid, TextField, List, ListItem, Typography, Box, InputAdornment, IconButton, Button, CircularProgress, Backdrop } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useApi } from '../../../utils/hooks/useApi';
import { Company } from './datatypes';
import { isAuthenticated, useUserContext } from '../../../utils/contexts/UserContext';
import { useNavigate } from 'react-router-dom';

const toTitleCase = (str: string | undefined): string => {
  if (!str) return '';
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const CompanySearch: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [loading, setLoading] = useState(true);
  const [orgNrFilter, setOrgNrFilter] = useState<string>('');
  const [orgNameFilter, setOrgNameFilter] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [totalItemCount, setTotalItemCount] = useState<number>(0);
  const [followingCompanies, setFollowingCompanies] = useState<string[]>([]);
  const { finndoffUser, hasCompanyFollower } = useUserContext();
  const navigate = useNavigate();

  const { get: getCompanies, error, loading: apiLoading } = useApi(
    `/api/company/paged?orgNrFilter=${orgNrFilter}&orgNameFilter=${orgNameFilter}&currentPage=${currentPage}&rowsPerPage=${rowsPerPage}`
  );

  const { get: getCompany, error: companyError, loading: companyLoading } = useApi(
    `/api/company`
  );

  const { get: listFollowing, error: followingError, loading: followingLoading } = useApi(
    `/api/me/bruker/following`
  );

  const { post: followCompanyApi } = useApi(
    `/api/me/bruker/following`
  );

  const { del: unfollowCompanyApi } = useApi(
    `/api/me/bruker/following`
  );

  const fetchCompanies = async () => {
    setLoading(true);
    const result = await getCompanies();
    if (result) {
      setCompanies(result.items);
      setTotalItemCount(result.totalItemCount);
    }
    setLoading(false);
  };

  const fetchFollowingCompanies = async () => {
    const result = await listFollowing();
    if (result) {
      setFollowingCompanies(result.map((follower: { company: { externalId: string } }) => follower.company.externalId));
    }
  };

  useEffect(() => {
    fetchCompanies().catch(console.error);
    fetchFollowingCompanies().catch(console.error);
  }, []);

  useEffect(() => {
    if (isAuthenticated && finndoffUser && finndoffUser?.selectedProduct?.productName !== 'PREMIUM') {
      navigate('/');
    }
  }, [isAuthenticated, hasCompanyFollower, finndoffUser, navigate]);

  const handleClearOrgNrFilter = () => {
    setOrgNrFilter('');
    handleSearch();
  };

  const handleClearOrgNameFilter = () => {
    setOrgNameFilter('');
    handleSearch();
  };

  const handleSearch = useCallback(() => {
    setCurrentPage(1);
    fetchCompanies().catch(console.error);
  }, [orgNrFilter, orgNameFilter, rowsPerPage]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    fetchCompanies().catch(console.error);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    fetchCompanies().catch(console.error);
  };

  const handleCompanySelect = async (companyExternalId: string) => {
    console.log(`Fetching details for company External ID: ${companyExternalId}`);
    setLoading(true);
    try {
      const result = await getCompany(`/${companyExternalId}`);
      setSelectedCompany(result);
      window.scrollTo(0, 0); // Scroll to top of the page
    } catch (error) {
      console.error('Error fetching company details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFollow = async () => {
    if (selectedCompany) {
      await followCompanyApi(`/${selectedCompany.externalId}`);
      await fetchFollowingCompanies();
    }
  };

  const handleUnfollow = async () => {
    if (selectedCompany) {
      await unfollowCompanyApi(`/${selectedCompany.externalId}`);
      await fetchFollowingCompanies();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleSearch();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSearch]);

  const isFollowing = selectedCompany && followingCompanies.includes(selectedCompany.externalId);

  return (
    <div style={{ position: 'relative' }}>
      {(loading || apiLoading || companyLoading) && (
        <Backdrop open={loading || apiLoading || companyLoading} style={{ zIndex: 1000, position: 'absolute', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.85)' }}>
          <CircularProgress sx={{ color: 'white' }} />
          <Typography variant="h6" style={{ marginTop: '10px', color: 'white' }}>
            Laster innhold...
          </Typography>
        </Backdrop>
      )}
      <Grid container spacing={1} sx={{ padding: '0 16px' }} direction={{ xs: 'column', sm: 'row' }}>
        <Grid item xs={12} sm={3} sx={{ padding: '0 10px' }}>
          <Grid sx={{ paddingBottom: '15px' }} item>
            <Typography variant="h6">Søkefelter</Typography>
          </Grid>
          <Grid item>
            <TextField
              label="Filtrer organisasjonsnummer"
              variant="outlined"
              sx={{ marginBottom: '1rem' }}
              fullWidth
              value={orgNrFilter}
              onChange={(e) => setOrgNrFilter(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {orgNrFilter && (
                      <IconButton onClick={handleClearOrgNrFilter} edge="end">
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
                disabled: loading || apiLoading,
              }}
            />
            <TextField
              label="Filtrer selskapsnavn"
              variant="outlined"
              sx={{ marginBottom: '1rem' }}
              fullWidth
              value={orgNameFilter}
              onChange={(e) => setOrgNameFilter(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {orgNameFilter && (
                      <IconButton onClick={handleClearOrgNameFilter} edge="end">
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
                disabled: loading || apiLoading,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              fullWidth
              disabled={loading || apiLoading}
              sx={{ marginBottom: '1rem' }}
            >
              Søk
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ padding: '0 10px' }}>
          <Grid sx={{ paddingBottom: '15px', paddingLeft: '15px' }} item>
            <Typography variant="h6">Selskaper</Typography>
          </Grid>
          <Grid item>
            <List>
              {companies.map((company) => (
                <ListItem 
                  button 
                  key={company.id} 
                  onClick={() => handleCompanySelect(company.externalId)}
                  selected={selectedCompany?.externalId === company.externalId}
                  sx={{ 
                    backgroundColor: followingCompanies.includes(company.externalId) ? '#d3d3d3' : 'transparent',
                    border: selectedCompany?.externalId === company.externalId ? '1px solid #1976d2' : 'none'
                  }}
                >
                  <Typography>{toTitleCase(company.navn)}</Typography>
                </ListItem>
              ))}
            </List>
            <Box display="flex" justifyContent="space-between">
              <Button
                onClick={handlePrevPage}
                disabled={loading || apiLoading || currentPage === 1}
              >
                Forrige
              </Button>
              <Button
                onClick={handleNextPage}
                disabled={loading || apiLoading || companies.length < rowsPerPage}
              >
                Neste
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5} sx={{ padding: '0 10px' }}>
          <Grid sx={{ paddingBottom: '15px' }} item>
            <Typography variant="h6">Detaljer</Typography>
          </Grid>
          {selectedCompany ? (
            <div>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h5">{toTitleCase(selectedCompany.navn)}</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <strong>Organisasjonsnummer:</strong> {selectedCompany.organisasjonsNummer}
                  </Typography>
                </Grid>
                {selectedCompany.naeringskode1Kode && (
                  <Grid item>
                    <Typography>
                      <strong>Næringskode:</strong> {selectedCompany.naeringskode1Kode}
                    </Typography>
                  </Grid>
                )}
                {selectedCompany.naeringskode1Beskrivelse && (
                  <Grid item>
                    <Typography>
                      <strong>Næringsbeskrivelse:</strong> {selectedCompany.naeringskode1Beskrivelse}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    variant="contained"
                    color={isFollowing ? "primary" : "secondary"}
                    onClick={isFollowing ? handleUnfollow : handleFollow}
                    fullWidth
                  >
                    {isFollowing ? "Stopp å følge selskap" : "Følg selskap"}
                  </Button>
                </Grid>
              </Grid>
            </div>
          ) : (
            !loading && !apiLoading && !companyLoading && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="50%"
              >
                <Typography variant="h5">Velg et selskap</Typography>
              </Box>
            )
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default CompanySearch;
