import { Box, Checkbox, FormControlLabel, Grid, Radio, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { FinndoffUser } from "../../utils/contexts/UserContext";
import { useSnackbar } from "../../utils/hooks/useSnackbar";
import { useApi } from "../../utils/hooks/useApi";
import { useNavigate, useLocation } from "react-router-dom";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";

interface Address {
  adresse: string[];
  postnummer: string;
  poststed: string;
}

interface Naeringskode {
  kode: string;
  beskrivelse: string;
}

interface OrgDetails {
  organisasjonsnummer: string;
  navn: string;
  forretningsadresse: Address;
  postadresse?: Address;
  naeringskode1?: Naeringskode;
}

interface SignupFormProps {
  emailSent: (isSent: boolean) => void;
  inviteError: (hasInviteError: boolean) => void;
  searchesFormTrigger: (setSearchesForm: boolean) => void;
  externalUserId: (setExternalUserId: string) => void;
  industryCode: (setindustryCode: string) => void;
  selectedProduct: string;
  setIsInviteLoading: (isInviteLoading: boolean) => void;

}

const ORG_NUM_LENGTH = 9;

export const SignupForm: FunctionComponent<SignupFormProps> = ({ emailSent, inviteError, searchesFormTrigger, externalUserId, industryCode, selectedProduct, setIsInviteLoading}) => {
  const navigate = useNavigate();

  const { enqueueErrorMsg } = useSnackbar();
  const [refOrg, setRefOrg] = useState("");

  const [orgNum, setOrgNum] = useState(refOrg ? refOrg : "");
  const [orgNumErrorMsg, setOrgNumErrorMsg] = useState("");
  const [isCorrectOrgNum, setIsCorrectOrgNum] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [naeringskode, setCompanyNaeringskode] = useState("");
  const [naeringskodeBeskrivelse, setCompanyNaeringskodeBeskrivelse] = useState("");
  const [billingAddressLine1, setBillingAddressLine1] = useState("");
  const [billingAddressLine2, setBillingAddressLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [postalPlace, setPostalPlace] = useState("");
  const [phone, setPhone] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [paymentMethodName, setPaymentMethodName] = useState("");
  const [invoiceSelected, setInvoiceSelected] = useState(false);
  const [cardSelected, setCardSelected] = useState(true);
  const [newsletterChosen, setNewsletterChosen] = useState(true);
  const [hasInvoice, setHasInvoice] = useState(false);

  const params = new URLSearchParams(useLocation().search);
  const referralCode = params.get("_referral");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  useEffect(() => {
    // Update state based on selectedProduct
    if (selectedProduct === "SMART") {
      setHasInvoice(false);
      handlePaymentMethodChange("Card"); // Select card payment method
    } else {
      setHasInvoice(true);
      handlePaymentMethodChange("Invoice"); // Select invoice payment method
    }
  }, [selectedProduct]);

  const onChangeOrgNum = async (orgIn: string) => {
    const onWrongOrgNum = (_orgNum: string, _status: string) => {
      // Avoid showing error message before the user has finished typing
      if (_orgNum.length !== ORG_NUM_LENGTH) {
        return;
      }

      if (orgNum.length !== ORG_NUM_LENGTH) {
        setIsCorrectOrgNum(false);

        setOrgNumErrorMsg("Ikke gyldig orgnummer.");
      }

      removeWrongParams();
      setOrgNumErrorMsg("Fant ikke organisasjonsnummeret i Br.reg.");
      resetOrgFields();
    };

    const removeWrongParams = () => {
      if (params.has("_org")) {
        params.delete("_org");
        navigate(params.toString()); // TODO ???
        setOrgNum("");
      }
    };

    const resetOrgFields = () => {
      setCompanyName("");
      setBillingAddressLine1("");
      setBillingAddressLine2("");
      setPostalCode("");
      setPostalPlace("");
    };

    const orgNumFromInputField = orgIn.replace(/\s/g, "");
    setOrgNum(orgNumFromInputField);

    if (orgNumFromInputField.length === 9) {
      setOrgNum(orgNumFromInputField);
      const resp = await fetch("https://data.brreg.no/enhetsregisteret/api/enheter/" + orgNumFromInputField);
      if (!resp.ok) {
        onWrongOrgNum(orgNumFromInputField, resp.statusText);
        setIsCorrectOrgNum(false);
        setRefOrg("");
        return;
      }
      const json = await resp.json();
      if (typeof json === "object" && json.hasOwnProperty("organisasjonsnummer")) {
        setIsCorrectOrgNum(true);
        setOrgDetails(json);
      }
    } else {
      setIsCorrectOrgNum(false);
      setOrgNumErrorMsg("Ikke gyldig orgnummer.");
      resetOrgFields();
    }
  };

  useEffect(() => {
    if (params.has("_org")) {
      onChangeOrgNum(params.get("_org"));
    }
  }, [params]);

  // Regular expression for validating a basic URL structure
  const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;


  const toSignupApiBody = (): NewFinndoffUser => ({
    orgNummer: orgNum,
    hjemmeside: "",
    firmaNavn: companyName,
    industryCode: naeringskode,
    industryCodeDesc: naeringskodeBeskrivelse,
    adresselinje1: billingAddressLine1,
    adresselinje2: billingAddressLine2,
    postNummer: postalCode,
    postSted: postalPlace,
    kontaktperson: contact,
    kontaktpersonMobil: phone,
    epostadresse: email,
    nyhetsbrev: newsletterChosen,
    passord: "",
    isPersonalSubscription: false,
    referenceOrganization: referralCode ? referralCode : "",
    productId : getProductId(selectedProduct),
    paymentMethod : paymentMethodName,
  });

  //This translates into db ids, not perfect, but should not change often
  // SMART is id 1, PLUSS is id 2, PREMIUM is id 3
  //defaults to PLUSS
  const getProductId = (product: string): number => {
    switch (product) {
      case 'SMART':
        return 1;
      case 'PLUSS':
        return 2;
      case 'PREMIUM':
        return 3;
      default:
        return 2;
    }
  };


  const { saveUserMethod: apiSaveUser } = SaveUserApiMethods(toSignupApiBody());
  const { industrySignupMethod: apiIndustrySignupUser } = IndustrySignupApiMethods(toSignupApiBody());

    // TODO const cancelRegistration = () => navigate("/portal");

  const setOrgDetails = (json: OrgDetails) => {
      setOrgNumErrorMsg("");
      setOrgNum(json.organisasjonsnummer);
      setCompanyName(json.navn);
      setCompanyNaeringskode(json.naeringskode1?.kode);
      industryCode(json.naeringskode1?.kode);
      setCompanyNaeringskodeBeskrivelse(json.naeringskode1?.beskrivelse);
      let address: Address;
      if (typeof json.postadresse === "object") {
          address = json.postadresse;
      } else {
          address = json.forretningsadresse;
      }
      const addressLines = address.adresse;

      if (addressLines.length === 1) {
          setBillingAddressLine1(addressLines[0]);
      } else if (addressLines.length > 1) {
          setBillingAddressLine1(addressLines[0]);
          setBillingAddressLine2(addressLines.slice(1).join(", "));
      } else {
          setBillingAddressLine1("");
          setBillingAddressLine2("");
      }

      setPostalCode(address.postnummer);
      setPostalPlace(address.poststed);
  };

  const printSearch = () => {
    if (refOrg === "") return;

    onChangeOrgNum(refOrg);
  };

  const canSave =
    orgNumErrorMsg === "" &&
    billingAddressLine1 !== "" &&
    postalCode !== "" &&
    postalPlace !== "" &&
    phone !== "";

  const saveInvite = async () => {
    try {
      setIsInviteLoading(true);
      if(referralCode != "" && referralCode != null)
      {
          await apiSaveUser().then((results) => {
            if (results?.externalUserId) {
              setIsInviteLoading(false);
              externalUserId(results.externalUserId);
              emailSent(true);
            } else if (results?.externalUserId == null) {
              inviteError(true);
              enqueueErrorMsg("Feil på lagring av bruker");
            }
          });
        }
        else
        {
          await apiIndustrySignupUser().then((results) => {
            if (results?.externalUserId) {
              setIsInviteLoading(false);
              externalUserId(results.externalUserId);
              searchesFormTrigger(true);
              emailSent(true);
            } else if (results?.externalUserId == null) {
              inviteError(true);
              enqueueErrorMsg("Feil på lagring av bruker");
            }
          });
        }

      // setTimeout(() => history.push(paths.portal), 300);
    } catch (e) {
      setIsInviteLoading(false);
      enqueueErrorMsg(`Kunne ikke lagre bruker: ${e.toString()}`);
    }
  };

  const handlePaymentMethodChange = (pm: string) => {
    switch (pm) {
      case 'Invoice':
        setCardSelected(false);
        setInvoiceSelected(true);
        setPaymentMethodName('Invoice');
        break;
      case 'Card':
        setCardSelected(true);
        setInvoiceSelected(false);
        setPaymentMethodName('Card');
        break;
      default:
        setCardSelected(true);
        setInvoiceSelected(false);
        setPaymentMethodName('Card');
    }
  };

  printSearch();
  return (
    <>
        <Grid container
        >
          <div style={{ 
            display: 'flex',
            flexDirection: 'column',
          }}>
            {!isSmallScreen &&<Typography variant="body1">Organisasjonsnummer</Typography>}
            {isSmallScreen && <Typography variant="body1">Orgnr</Typography>}
          </div>
          <TextField
            placeholder="Orgnr (9 sifre)"
            value={orgNum}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength: 11 }}
            onChange={(e) => onChangeOrgNum(e.target.value)}
            style={{ marginBottom: '3%' }}
            fullWidth
            required
            error={!!orgNumErrorMsg}
            helperText={orgNumErrorMsg}
          />
          <Box width="90%">
            {isCorrectOrgNum && ((
                  <>
                    <Typography>Firmanavn</Typography> 
                    <TextField placeholder="Navn" value={companyName} style={{ marginBottom: '3%' }} disabled fullWidth/>  
                  </>
                ))}
          </Box>
          <Typography>Kontaktperson</Typography>
          <TextField
            placeholder="Navn Navnesen"
            value={contact}
            autoComplete="name"
            inputProps={{ autoComplete: "name" }}
            onChange={(e) => setContact(e.target.value)}
            style={{ marginBottom: '3%' }}
            required
            fullWidth
          />
          <Grid container spacing={2} style={{ fontSize: '6pt', marginBottom:'3%' }} >
            <Grid item xs={6}>
              <Typography>Epost</Typography>
              <TextField
                value={email}
                autoComplete="email"
                inputProps={{ autoComplete: "email" }}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="epost@epost.no"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>Telefon</Typography>
              <TextField
                placeholder="+47 00 00 00 00"
                autoComplete="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
                fullWidth
              />
            </Grid>
            <Grid item container xs={12} alignItems="center">
               <Checkbox
                color="primary"
                checked={newsletterChosen}
                onChange={() => setNewsletterChosen(!newsletterChosen)}
                />
                <Typography variant="body2">Send meg siste nytt fra Finndoff</Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <div style={{ 
            display: 'flex',
            flexDirection: 'column',
            }}>
              {!isSmallScreen && <Typography>Betalingsmetode</Typography>}
              {isSmallScreen && <Typography>Betaling</Typography>}
            </div>
            <Grid container spacing={2}>
              <Grid item container alignItems="center" xs={12}>
                <>
                  {hasInvoice  && <FormControlLabel
                        control={
                            <Radio
                            size="small"
                            checked={invoiceSelected}
                            onChange={() => handlePaymentMethodChange('Invoice')}
                            value={invoiceSelected}  
                            />
                          }
                          label="Faktura"
                          onClick={() => handlePaymentMethodChange('Invoice')}
                          />
                  }
                </>
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={cardSelected}
                      onChange={() => handlePaymentMethodChange('Card')}
                      value={cardSelected}
                    />
                  }
                  label="Kort"
                  onClick={() => handlePaymentMethodChange('Card')}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            textAlign="end"
            sx={{
              "& > *": {
                margin: (theme) => theme.spacing(1),
              },
            }}
          >
            <PrimaryButton disabled={!canSave} onClick={saveInvite} text={"Registrer bruker!"} />
          </Grid>
        </Grid>
    </>
  );
};

export type NewFinndoffUser = {
  orgNummer?: string;
  hjemmeside?: string;
  firmaNavn: string;
  adresselinje1?: string;
  adresselinje2?: string;
  postNummer?: string;
  postSted?: string;
  kontaktperson: string;
  kontaktpersonMobil: string;
  epostadresse: string;
  nyhetsbrev: boolean;
  passord: string;
  isPersonalSubscription: boolean;
  referenceOrganization: string;
  industryCode: string;
  industryCodeDesc: string;
  productId: number;
  paymentMethod: string;
};

export type UserSearches = {
  ExternalUserId: string;
  Industries : string[];
};

export const SaveUserApiMethods = (user: NewFinndoffUser) => {
  const { error, loading, post } = useApi("/api/me/bruker/signup");
  return {
    error,
    loading,
    saveUserMethod: async (): Promise<FinndoffUser> => {
      const body = user;
      const apiResponse = await post("", body);
      return apiResponse;
    },
  };
};

export const IndustrySignupApiMethods = (user: NewFinndoffUser) => {
  const { error, loading, post } = useApi("/api/me/bruker/industrysignup");
  return {
    error,
    loading,
    industrySignupMethod: async (): Promise<FinndoffUser> => {
      const body = user;
      const apiResponse = await post("", body);
      return apiResponse;
    },
  };
};


