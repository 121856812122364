import React, { FunctionComponent, useState } from "react";
import { Box, Fab, Grid, OutlinedInput, Typography, useMediaQuery } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme } from "@mui/material";
import { Node } from "../../../utils/tree";
import { Link, useNavigate } from "react-router-dom";
import { paths } from "../../../app/paths";
import { useReferenceDataContext } from "../../../utils/contexts/ReferenceDataContext";
import { initialSearch, Search } from "../../../Types/Search";
import { HierarchyDialog } from "./HierarchyDialog";
import { HierarchyType } from "./datatypes";
import { ReactComponent as SearchImage } from "../../../images/search_illustration.svg";
import { UnderlinedButton } from "../../../components/Buttons/UnderlinedButton";
import { StyledChip } from "../../../components/StyledChip";

export const SearchComponentPure: FunctionComponent = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { steder, kategorier } = useReferenceDataContext();

  // Kategorier
  const [showCpvModal, setShowCpvModal] = useState(false);
  const [currentCpvSearch, setCurrentCpvSearch] = useState<Search>(initialSearch);
  const updateCurrentCpvSearch = (currentSearch: Search) => setCurrentCpvSearch(currentSearch);
  const handleOpenCpvModal = () => setShowCpvModal(true);
  const handleCloseCpvModal = () => setShowCpvModal(false);

  // Steder
  const [showStedModal, setShowStedModal] = useState(false);
  const [currentStedSearch, setCurrentStedSearch] = useState<Search>(initialSearch);
  const updateCurrentStedSearch = (currentSearch: Search) => setCurrentStedSearch(currentSearch);
  const handleOpenStedModal = () => setShowStedModal(true);
  const handleCloseStedModal = () => setShowStedModal(false);

  // Fritekstsøk
  const [freeText, setFreeText] = useState("");
  const handleFreeTextChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== "") {
      setFreeText(event.target.value);
    }
  };

  function getSelectedCpvNodes(): Node[] {
    let cpvNodes: Node[] = [];
    if (currentCpvSearch === null) {
      return cpvNodes;
    }
    currentCpvSearch?.kategoriIds.forEach((kategoriId) => {
      cpvNodes.push(kategorier.nodeMap[kategoriId]);
    });
    return cpvNodes;
  }

  function getSelectedStedNodes(): Node[] {
    let stedNodes: Node[] = [];
    if (currentStedSearch === null) {
      return stedNodes;
    }
    currentStedSearch?.stedIds.forEach((stedId) => {
      stedNodes.push(steder.nodeMap[stedId]);
    });
    return stedNodes;
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      navigate(`${paths.portal}/${paths.search}`, {
        state: {
          freeText: freeText,
          steder: currentStedSearch?.stedIds,
          kategorier: currentCpvSearch?.kategoriIds,
        },
      });
    }
  };
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const small = useMediaQuery(theme.breakpoints.only("sm"));
  const mid = useMediaQuery(theme.breakpoints.only("md"));

  return (
    <Box bgcolor="white" marginTop="16px">
      <Box color={(theme) => theme.palette.primary.light} marginLeft="auto" marginRight="auto" width="100%">
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          {xs && (
            <Grid item xs={10}>
              <SearchImage width="90%"></SearchImage>
            </Grid>
          )}
          <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
            <Grid container>
              <Grid item marginBottom="1rem">
                <Typography sx={{ typography: { xs: "h3", sm: "h4", mid: "h3", lg: "h2" } }}>
                  Søk og finn doffinkunngjøringer
                </Typography>
              </Grid>

              <Grid container spacing={3} alignItems="center">
                <Grid item xs={10} sm={8} md={6}>
                  <OutlinedInput
                    id="freetextInput"
                    sx={{
                      width: "100%",
                      color: "primary.main",
                    }}
                    onChange={handleFreeTextChanged}
                    placeholder="Bransje eller tjeneste"
                    onKeyDown={(e) => handleKeyPress(e)}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Link
                    to={`${paths.portal}/${paths.search}`}
                    state={{
                      freeText: freeText,
                      steder: currentStedSearch?.stedIds,
                      kategorier: currentCpvSearch?.kategoriIds,
                    }}
                  >
                    <Fab color="secondary" size="large">
                      <ArrowForwardIcon fontSize="large" />
                    </Fab>
                  </Link>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={5} sm={5} md={3} lg={3} xl={2}>
                  <UnderlinedButton onClick={handleOpenCpvModal} variant="text" text={"Velg kategori"} />
                  <div>
                    {getSelectedCpvNodes().map((node) => {
                      return (
                        <Box
                          sx={{
                            marginTop: "16px",
                            marginLeft: "2px",
                            marginRight: "2px",
                            margnBottom: "2px",
                          }}
                        >
                          <StyledChip key={node.id} label={node.label.split(" ")[0]} />
                        </Box>
                      );
                    })}
                  </div>
                  <HierarchyDialog
                    title={"Velg kategori(er)"}
                    tree={kategorier}
                    open={showCpvModal}
                    closeDialog={handleCloseCpvModal}
                    type={HierarchyType.Kategori}
                    updateCurrentSearch={updateCurrentCpvSearch}
                    currentSearch={currentCpvSearch}
                  />
                </Grid>

                <Grid item xs={5} sm={5} md={3} lg={3} xl={2}>
                  <UnderlinedButton onClick={handleOpenStedModal} variant="text" text={"Velg sted"} />
                  <div>
                    {getSelectedStedNodes().map((node) => {
                      return (
                        <Box
                          sx={{
                            marginTop: "16px",
                            marginLeft: "2px",
                            marginRight: "2px",
                            margnBottom: "2px",
                          }}
                        >
                          <StyledChip key={node.id} label={node.label.split(" ")[0]} />
                        </Box>
                      );
                    })}
                  </div>
                  <HierarchyDialog
                    title={"Velg sted(er)"}
                    tree={steder}
                    open={showStedModal}
                    closeDialog={handleCloseStedModal}
                    type={HierarchyType.Sted}
                    updateCurrentSearch={updateCurrentStedSearch}
                    currentSearch={currentStedSearch}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!xs && (
            <Grid item sm={4} md={4} lg={5} xl={5}>
              <SearchImage width={mid ? "90%" : "100%"} />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export const SearchComponent = SearchComponentPure;
