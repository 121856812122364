import { Box } from "@mui/material";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator";
import styled from "@emotion/styled";

type StyledIFrameProps = {
  backgroundColor: string;
};

const StyledIFrame = styled("iframe")`
  display: block;
  width: 100%;
  border: none;
  background-color: ${(props: StyledIFrameProps) => (props.backgroundColor ? props.backgroundColor : "white")};
  overflow-x: hidden;
  overflow-y: hidden;
  margin-height: 0;
  margin-width: 0;
`;

interface IFrameProps {
  id: string;
  src: string;
  defaultHeight?: string;
  backgroundColor?: string;
  urlChangedFunction?: (url: string) => void;
}

export const IFrame: FunctionComponent<IFrameProps> = ({
  id,
  src,
  defaultHeight,
  backgroundColor,
  urlChangedFunction,
}) => {
  const [loaded, _setLoaded] = useState(false);
  const loadedRef = useRef(loaded);

  const setLoaded = (loaded: boolean, iframeId: string) => {
    loadedRef.current = loaded;
    _setLoaded(true);
    if (defaultHeight) {
      const iframe = document.getElementById(iframeId) as HTMLIFrameElement;
      iframe.height = defaultHeight;
    }
  };

  useEffect(() => {
    const eventListener = (msg: any, iframeId: string) => {
      const message = msg.data;
      if (!loadedRef.current) {
        return;
      }
      try {
        if (message) {
          if (message.height) {
            const iframe = document.getElementById(iframeId) as HTMLIFrameElement;

            iframe.height = message.height + "px";
          }
          if (message.pathname && urlChangedFunction) {
            urlChangedFunction(message.pathname);
          }
        }
      } catch (e) {
        console.log("Error while resizing: " + e);
      }
    };

    window.addEventListener("message", (e) => eventListener(e, id), true);
  }, [id, urlChangedFunction]);

  return (
    <>
      {!loaded && (
        <Box marginBottom="2rem">
          <LoadingIndicator />
        </Box>
      )}
      <StyledIFrame
        onLoad={() => {
          setLoaded(true, id);
        }}
        seamless
        scrolling="no"
        id={id}
        src={src}
        height={defaultHeight ? `${defaultHeight}px` : `0px`}
        backgroundColor={backgroundColor}
      />
    </>
  );
};
