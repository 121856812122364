import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

/**
 * @ignore
 */
const defaultOnLoading = (): JSX.Element => <></>;

/**
 * ```js
 * const MyProtectedComponent = withAuthenticationRequired(MyComponent);
 * ```
 *
 * When you wrap your components in this Higher Order Component and an anonymous user visits your component
 * they will be redirected to the login page and returned to the page they we're redirected from after login.
 */
export const WaitForAuth0Loaded = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { isLoading } = useAuth0();
  return isLoading ? defaultOnLoading() : children;
};
