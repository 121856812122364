import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { config } from '../../config';
import { Link } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const TenderSummary: React.FC = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [tenderData, setTenderData] = useState<any | null>(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        const tenderId = queryParams.get('tenderId');

        if (!token || !tenderId) {
            setError('Mangler token eller anbud ID');
            setLoading(false);
            return;
        }

        const fetchTenderData = async () => {
            try {
                const response = await fetch(config.backendUrl + `/api/tender/summary?token=${encodeURIComponent(token)}&tenderId=${encodeURIComponent(tenderId)}`, {
                    method: 'GET'
                });

                if (!response.ok) {
                    throw new Error('Kunne ikke hente anbudsoppsummering');
                }

                const tenderData = await response.json();

                if (tenderData) {
                    setTenderData(tenderData);
                } else {
                    setError('Anbudsoppsummering ble ikke funnet');
                }
            } catch (err) {
                console.error('Feil ved henting av anbudsoppsummering:', err);
                setError('Feil ved uthenting av anbudet!');
            } finally {
                setLoading(false);
            }
        };

        fetchTenderData();
    }, [location.search]);

    return (
        <Container>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                paddingTop="20px"
                paddingBottom="20px"
            >
                {loading ? (
                    <CircularProgress />
                ) : error ? (
                    <>
                        <Typography variant="h6" color="error" align="center">
                            {error}
                        </Typography>
                        <Link
                            href="https://share-eu1.hsforms.com/183V6W1IGRcikwgsn9G8Nngfai8j"
                            target="_blank"
                            rel="noreferrer"
                            sx={{
                                fontSize: "1.2rem",
                                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: 500,
                                lineHeight: "1.6",
                                letterSpacing: "0.0075em",
                                position: "relative",
                                color: "#008489",
                                marginBottom: "24px",
                                marginTop: "10px",
                                textAlign: "center",
                            }}
                        >
                            Si gjerne ifra om du ser denne meldingen!
                        </Link>
                    </>
                ) : (
                    tenderData && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="start"
                            justifyContent="center"
                            padding="20px"
                            width="100%"
                            maxWidth="800px"
                            sx={{ marginBottom: '20px' }}
                        >
                            <Typography variant="h6" sx={{ marginBottom: '20px' }}>Nøkkelinformasjon</Typography>
                            <Box
                                display="flex"
                                alignItems="center"
                                bgcolor="grey.300"
                                padding="10px"
                                borderRadius="4px"
                                marginBottom="20px"
                                width="100%"
                            >
                                <InfoOutlinedIcon sx={{ marginRight: '10px' }} />
                                <Typography variant="body1">
                                    Maskinlagde oppsummeringer kan være feil. Sjekk kunngjøringen for fullstendig informasjon.
                                </Typography>
                            </Box>
                            {tenderData.kontraktverdi && !tenderData.kontraktverdi.startsWith('0') && (
                                <Box sx={{ marginBottom: '20px' }}>
                                    <Typography variant="subtitle1" fontWeight="bold">Kontraktverdi</Typography>
                                    <Typography>{tenderData.kontraktverdi}</Typography>
                                </Box>
                            )}
                            <Box sx={{ marginBottom: '20px' }}>
                                <Typography variant="subtitle1" fontWeight="bold">Oppdragsgiver</Typography>
                                <Typography>{tenderData.oppdragsgiversOrganisasjonsnavn}</Typography>
                            </Box>
                            <Box sx={{ marginBottom: '20px' }}>
                                <Typography variant="subtitle1" fontWeight="bold">Oppdragsgivers sektor</Typography>
                                <Typography>{tenderData.oppdragsgiversSektor}</Typography>
                            </Box>
                            {tenderData.forventetDatoForLeveranse && !tenderData.forventetDatoForLeveranse.toLowerCase().startsWith('ukjent') && (
                                <Box sx={{ marginBottom: '20px' }}>
                                    <Typography variant="subtitle1" fontWeight="bold">Forventet varighet</Typography>
                                    <Typography>{tenderData.forventetDatoForLeveranse}</Typography>
                                </Box>
                            )}
                            {tenderData.forventetAntallEnheter && !tenderData.forventetAntallEnheter.startsWith('Ikke relevant') && (
                                <Box sx={{ marginBottom: '20px' }}>
                                    <Typography variant="subtitle1" fontWeight="bold">Hva skal leveres</Typography>
                                    <Typography>{tenderData.forventetAntallEnheter}</Typography>
                                </Box>
                            )}
                            <Box sx={{ marginBottom: '20px' }}>
                                <Typography variant="subtitle1" fontWeight="bold">Innkjøpsdetaljer</Typography>
                                <Typography>{tenderData.innkjøpsdetaljer}</Typography>
                            </Box>
                            <Box sx={{ marginBottom: '20px' }}>
                                <Typography variant="subtitle1" fontWeight="bold">Detaljer om tilbyder</Typography>
                                <Typography>{tenderData.detaljerOmTilbyder}</Typography>
                            </Box>
                            <Box sx={{ marginBottom: '20px' }}>
                                <Typography variant="subtitle1" fontWeight="bold">Doffin Id</Typography>
                                <Typography>{tenderData.doffinTenderId}</Typography>
                            </Box>
                            <Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => window.open(tenderData.anbud?.doffinUrl, '_blank')}
                                    sx={{ marginTop: '20px' }}
                                >
                                    Gå til kunngjøring
                                </Button>
                            </Box>
                        </Box>
                    )
                )}
            </Box>
        </Container>
    );
};

export default TenderSummary;
